import React from "react";
import DeleteSection from "./DeleteSection";
import {Student} from "../../../../../data/Entities";
import MailingSection from "./mailing/MailingSection";

type Props = {
    student: Student
};

const ActionsTab = ({student}: Props) => {
    return (
        <>
            <MailingSection student={student}/>
            <DeleteSection student={student}/>
        </>
    );
};

export default ActionsTab;