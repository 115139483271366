import React from "react";
import {OverviewExamQuestion} from "../../../../../../data/Entities";
import router from "../../../../../../routing/Router";
import {TableButton} from "peggirkit";
import {useIntl} from "react-intl";

type Props = {
    question: unknown
};

const OptionsFormatter = ({question}: Props) => {
    const intl = useIntl();
    const q = question as OverviewExamQuestion;
    return (
        <TableButton
            text={intl.formatMessage({id: "view"})}
            href={router.editQuestion.absolutePath(q.id)}
        />
    );
};

export default OptionsFormatter;