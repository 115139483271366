import React, {useEffect, useState} from "react";
import {AlertModal, AlertText, Form, InputField, isEmpty, NotificationService} from "peggirkit";
import {FormattedMessage, useIntl} from "react-intl";
import {ExamCategory} from "../../../data/Entities";
import {useForm} from "react-hook-form";
import {upsertCategory} from "../../../data/ApiEndpoints";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void,
    category: ExamCategory | null,
    cb: () => void
};

const EditCategoryModal = ({show, setShow, category, cb}: Props) => {
    const intl = useIntl();
    const {register, getValues, handleSubmit, setValue, reset, formState: {errors}} = useForm<ExamCategory>();
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);

    // Load initial data
    useEffect(() => {
        if (!show) {
            reset();
            return;
        }

        if (category) {
            setValue("id", category.id);
            setValue("code", category.code);
            setValue("nameNl", category.nameNl);
            setValue("nameAr", category.nameAr);
            setValue("requiredInHazardRecognitionSection", category.requiredInHazardRecognitionSection);
            setValue("requiredInKnowledgeSection", category.requiredInKnowledgeSection);
            setValue("requiredInInsightSection", category.requiredInInsightSection);
        }
    }, [show]);

    // Save category
    useEffect(() => {
        if (!loading) {
            return;
        }

        const submittedData: ExamCategory = getValues();
        upsertCategory({
            ...submittedData,
            code: isEmpty(submittedData.code) ? null : submittedData.code
        }).then(() => {
            setShow(false);
            setLoading(false);
            NotificationService.notify({type: "success", text: intl.formatMessage({id: "categorySaved"})});
            cb();
        }).catch(() => {
            setFormError(intl.formatMessage({id: "operationError"}));
            setLoading(false);
        });
    }, [loading]);

    return (
        <AlertModal
            type={"info"}
            loading={loading}
            show={show}
            onClose={() => setShow(false)}
            title={intl.formatMessage({id: "editCategory"})}
            cancelText={intl.formatMessage({id: "cancel"})}
            onContinue={handleSubmit(() => setLoading(true))}
            continueText={intl.formatMessage({id: "save"})}
        >
            <div className={"mb-6 space-y-6"}>
                <AlertText>
                    <FormattedMessage id={"enterFieldsToUpdateCategory"}/>
                </AlertText>

                <Form
                    onSubmit={handleSubmit(() => setLoading(true))}
                    error={formError}
                >
                    <InputField
                        id={"id"}
                        displayName={intl.formatMessage({id: "id"})}
                        type={"number"}
                        disabled={true}
                        reactHookForm={{...register("id")}}
                    />
                    <InputField
                        id={"code"}
                        displayName={intl.formatMessage({id: "code"})}
                        type={"text"}
                        disabled={loading}
                        error={errors.code && errors.code.message}
                        reactHookForm={{
                            ...register("code", {
                                required: false,
                                minLength: {
                                    value: 1,
                                    message: intl.formatMessage({id: "mustBeMinCharacters"}, {min: 1})
                                },
                                maxLength: {
                                    value: 5,
                                    message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 5})
                                }
                            })
                        }}
                    />
                    <InputField
                        id={"nameNl"}
                        displayName={intl.formatMessage({id: "nameNl"}) + "*"}
                        type={"text"}
                        disabled={loading}
                        error={errors.nameNl && errors.nameNl.message}
                        reactHookForm={{
                            ...register("nameNl", {
                                required: intl.formatMessage({id: "mustEnterField"}),
                                minLength: {
                                    value: 1,
                                    message: intl.formatMessage({id: "mustBeMinCharacters"}, {min: 1})
                                },
                                maxLength: {
                                    value: 256,
                                    message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 256})
                                }
                            })
                        }}
                    />
                    <InputField
                        id={"nameAr"}
                        displayName={intl.formatMessage({id: "nameAr"}) + "*"}
                        type={"text"}
                        disabled={loading}
                        error={errors.nameAr && errors.nameAr.message}
                        alignRight={true}
                        rtl={true}
                        reactHookForm={{
                            ...register("nameAr", {
                                required: intl.formatMessage({id: "mustEnterField"}),
                                minLength: {
                                    value: 1,
                                    message: intl.formatMessage({id: "mustBeMinCharacters"}, {min: 1})
                                },
                                maxLength: {
                                    value: 256,
                                    message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 256})
                                }
                            })
                        }}
                    />
                    <InputField
                        id={"requiredInHazardRecognitionSection"}
                        displayName={intl.formatMessage({id: "requiredInHazardRecognitionSection"}) + "*"}
                        type={"number"}
                        disabled={loading}
                        error={errors.requiredInHazardRecognitionSection && errors.requiredInHazardRecognitionSection.message}
                        reactHookForm={{
                            ...register("requiredInHazardRecognitionSection", {
                                required: intl.formatMessage({id: "mustEnterField"}),
                            })
                        }}
                    />
                    <InputField
                        id={"requiredInKnowledgeSection"}
                        displayName={intl.formatMessage({id: "requiredInKnowledgeSection"}) + "*"}
                        type={"number"}
                        disabled={loading}
                        error={errors.requiredInKnowledgeSection && errors.requiredInKnowledgeSection.message}
                        reactHookForm={{
                            ...register("requiredInKnowledgeSection", {
                                required: intl.formatMessage({id: "mustEnterField"}),
                            })
                        }}
                    />
                    <InputField
                        id={"requiredInInsightSection"}
                        displayName={intl.formatMessage({id: "requiredInInsightSection"}) + "*"}
                        type={"number"}
                        disabled={loading}
                        error={errors.requiredInInsightSection && errors.requiredInInsightSection.message}
                        reactHookForm={{
                            ...register("requiredInInsightSection", {
                                required: intl.formatMessage({id: "mustEnterField"}),
                            })
                        }}
                    />
                </Form>
            </div>
        </AlertModal>
    );
};

export default EditCategoryModal;