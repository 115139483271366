import React from "react";
import BlogOverviewPageHeading from "./BlogOverviewPageHeading";
import BlogDataTable from "./BlogDataTable";

const BlogOverview = () => {
    return (
        <>
            <BlogOverviewPageHeading/>

            <div className={"mt-8 md:mt-16"}>
                <BlogDataTable/>
            </div>
        </>
    );
};

export default BlogOverview;