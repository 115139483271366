import React, {useRef} from "react";
import {FormLayoutCard, Icon, InputCreatableSelect, InputField, isEmpty, TwoColumnFormLayout} from "peggirkit";
import {useIntl} from "react-intl";
import {Control, UseFormHandleSubmit, UseFormRegister, UseFormSetValue, UseFormWatch} from "react-hook-form";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {conceptOptions} from "../../../questions/question/section/general/ConceptState";
import {ExamDetailsForm} from "../EditExamContainer";
import {ArrowUpTrayIcon} from "@heroicons/react/24/outline";
import ImageUploader from "../../../assets/ImageUploader";
import {getAbsoluteImageUrl} from "../../../assets/AssetsUtil";
// @ts-ignore
import placeholder from "../../../../../assets/img/placeholder.jpg";
import DemoAlert from "./DemoAlert";
import DeleteSection from "./DeleteSection";

export type EditExamGeneralSectionProps = {
    loading: boolean,
    watch: UseFormWatch<ExamDetailsForm>,
    register: UseFormRegister<ExamDetailsForm>,
    errors: FieldErrors<ExamDetailsForm>,
    setValue: UseFormSetValue<ExamDetailsForm>,
    control: Control<ExamDetailsForm>,
    handleSubmit: UseFormHandleSubmit<ExamDetailsForm>,
    setLoading: (loading: boolean) => void,
};

const GeneralSection = ({
                            loading,
                            watch,
                            register,
                            setValue,
                            errors,
                            control,
                            handleSubmit,
                            setLoading
                        }: EditExamGeneralSectionProps) => {
    const intl = useIntl();
    const imageUploaderRef = useRef<HTMLInputElement | null>(null);
    const isDemo: boolean = watch("demo");

    return (
        <div>
            <TwoColumnFormLayout
                onSubmit={handleSubmit(() => setLoading(true))}
                error={null}
            >
                <FormLayoutCard
                    title={intl.formatMessage({id: "general"})}
                    description={intl.formatMessage({id: "generalExamDetailsExplanation"})}
                >
                    <div className="grid grid-cols-6 gap-6">
                        <DemoAlert
                            isDemo={isDemo}
                        />

                        <div className="col-span-6 lg:col-span-4">
                            <InputCreatableSelect
                                id={"exam-status"}
                                displayName={intl.formatMessage({id: "status"})}
                                options={conceptOptions}
                                disabled={loading}
                                control={control}
                                placeholder={intl.formatMessage({id: "pickAStatus"})}
                                nameHookForm={"concept"}
                                isValidNewOption={_ => false}
                                formatCreateLabel={inputValue =>
                                    intl.formatMessage({id: "addOption"}, {option: inputValue})}
                                rules={{
                                    required: intl.formatMessage({id: "mustPickOption"}),
                                }}
                                error={errors.concept && errors.concept.message}
                                tip={intl.formatMessage({id: "examStatusTip"})}
                            />
                        </div>
                        <div className="col-span-6 lg:col-span-4">
                            <InputField
                                type={"text"}
                                id={"exam-name-nl"}
                                displayName={intl.formatMessage({id: "dutchName"})}
                                tip={`${watch("nameNl")?.length || 0}/128`}
                                reactHookForm={{
                                    ...register("nameNl", {
                                        required: intl.formatMessage({id: "mustEnterField"}),
                                        maxLength: {
                                            value: 128,
                                            message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 128})
                                        }
                                    })
                                }}
                                error={errors.nameNl && errors.nameNl.message}
                                disabled={loading || isDemo}
                            />
                        </div>
                        <div className="col-span-6 lg:col-span-4">
                            <InputField
                                type={"text"}
                                id={"exam-name-ar"}
                                displayName={intl.formatMessage({id: "arabicName"})}
                                tip={`${watch("nameAr")?.length || 0}/128`}
                                alignRight={true}
                                rtl={true}
                                reactHookForm={{
                                    ...register("nameAr", {
                                        required: intl.formatMessage({id: "mustEnterField"}),
                                        maxLength: {
                                            value: 128,
                                            message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 128})
                                        }
                                    })
                                }}
                                error={errors.nameAr && errors.nameAr.message}
                                disabled={loading || isDemo}
                            />
                        </div>


                        <div className="col-span-6 lg:col-span-4">
                            <InputField
                                type={"text"}
                                id={"exam-image"}
                                displayName={intl.formatMessage({id: "thumbnail"})}
                                reactHookForm={register("image", {
                                    required: isDemo ? false : intl.formatMessage({id: "mustSelectImage"})
                                })}
                                error={errors.image && errors.image.message}
                                disabled={loading}
                                trailingButton={{
                                    type: "gray",
                                    iconOnly: false,
                                    icon: ArrowUpTrayIcon as Icon,
                                    text: intl.formatMessage({id: "upload"}),
                                    disabled: loading,
                                    onClick: e => {
                                        e.preventDefault();
                                        imageUploaderRef.current && imageUploaderRef.current.click();
                                    }
                                }}
                            />
                            <ImageUploader
                                afterUpload={(img) => setValue("image", img)}
                                uploaderRef={imageUploaderRef}
                            />
                        </div>

                        <div className="col-span-6 lg:col-span-4">
                            <img
                                className={"border rounded-md border-gray-300 w-full aspect-[938/639]"}
                                alt={intl.formatMessage({id: "image"})}
                                src={
                                    !isEmpty(watch("image"))
                                        ? getAbsoluteImageUrl(watch("image"))
                                        : placeholder
                                }
                            />
                        </div>
                    </div>
                </FormLayoutCard>

                <DeleteSection
                    examId={watch("id")}
                    isDemo={isDemo}
                />
            </TwoColumnFormLayout>
        </div>
    );
};

export default GeneralSection;