import React from "react";
import {breadcrumbItemDashboard, breadcrumbItemDatabase} from "../../../routing/Router";
import {BreadcrumbsContent, PageHeading} from "peggirkit";
import TheorieGoFrame from "./layout/TheorieGoFrame";
import DatabaseIndexSection from "../../widgets/database/DatabaseIndexSection";
import {useIntl} from "react-intl";

const Database = () => {
    const intl = useIntl();
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemDatabase]
    };

    return (
        <TheorieGoFrame title={breadcrumbItemDatabase.name}>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={breadcrumbItemDatabase.name}
            />

            <DatabaseIndexSection/>
        </TheorieGoFrame>
    );
};

export default Database;