import React, {useEffect, useState} from "react";
import {BlogPost} from "../../../../data/Entities";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {Control, useForm, UseFormRegister, UseFormSetValue, UseFormWatch} from "react-hook-form";
import {BreadcrumbItem, Card, NotificationService, SlideOver, useWindowDimensions} from "peggirkit";
import {BreadcrumbsContent} from "peggirkit/dist/components/Heading/Page/Breadcrumbs";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import BlogPostPageHeading from "./BlogPostPageHeading";
import BlogPostContentCard from "./content/BlogPostContentCard";
import BlogPostMetadataPanel from "./metadata/BlogPostMetadataPanel";
import {upsertBlogPost} from "../../../../data/ApiEndpoints";
import router from "../../../../routing/Router";

type Props = {
    breadcrumbs: BreadcrumbsContent,
    currentBreadcrumbItem: BreadcrumbItem,
    loadingData: boolean,
    blogPost?: BlogPost,
    setBlogPost: React.Dispatch<React.SetStateAction<BlogPost | undefined>>,
};

export type EditBlogPostProps = {
    loading: boolean,
    register: UseFormRegister<BlogPost>
    control: Control<BlogPost>,
    errors: FieldErrors<BlogPost>
    watch: UseFormWatch<BlogPost>
    setValue: UseFormSetValue<BlogPost>
};

const BlogPostEditor = ({breadcrumbs, currentBreadcrumbItem, loadingData, blogPost, setBlogPost}: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const {innerWidth: windowWidth} = useWindowDimensions();
    const [loading, setLoading] = useState(false);
    const [metadataSliderOpen, setMetadataSliderOpen] = useState(false);
    const {
        register,
        control,
        getValues,
        watch,
        handleSubmit,
        reset,
        setValue,
        formState: {errors}
    } = useForm<BlogPost>({
        defaultValues: blogPost
            ? {...blogPost}
            : {id: undefined}
    });

    // Reset default values for new post
    useEffect(() => {
        if (!blogPost) {
            reset();
        }
    }, [blogPost]);

    // Save question
    useEffect(() => {
        if (!loading) {
            return;
        }

        const values = getValues();
        upsertBlogPost({
            id: values.id,
            title: values.title,
            banner: values.banner,
            slug: values.slug,
            description: values.description,
            content: values.content,
            publishDate: values.publishDate
        }).then((saved: BlogPost) => {
            if (blogPost === undefined) {
                navigate(router.blogPost.absolutePath(saved.id))
            }
            setLoading(false);
            setBlogPost(saved);
            NotificationService.notify({type: "success", text: intl.formatMessage({id: "postingSavedNotification"})});
        }).catch(() => {
            setLoading(false);
            NotificationService.notify({type: "danger", text: intl.formatMessage({id: "operationError"})});
        });
    }, [loading]);

    const editProps: EditBlogPostProps = {
        loading: loading,
        register: register,
        errors: errors,
        watch: watch,
        control: control,
        setValue: setValue,
    };

    return (
        <>
            <BlogPostPageHeading
                breadcrumbs={breadcrumbs}
                currentBreadcrumbItem={currentBreadcrumbItem}
                handleSubmit={handleSubmit}
                loading={loading || loadingData}
                setLoading={setLoading}
                blogPost={blogPost}
            />

            <div className={"mt-4 md:mt-12 grid grid-cols-1 xl:grid-cols-3 gap-6 xl:gap-12"}>
                <div className={"col-span-1 xl:col-span-2"}>
                    <BlogPostContentCard
                        setMetadataSliderOpen={setMetadataSliderOpen}
                        {...editProps}
                    />
                </div>

                {
                    windowWidth <= 1280
                        ? (
                            <SlideOver
                                open={metadataSliderOpen}
                                setOpen={setMetadataSliderOpen}
                                title={intl.formatMessage({id: "metadata"})}
                            >
                                <BlogPostMetadataPanel {...editProps}/>
                            </SlideOver>
                        )
                        : (
                            <div className={"col-span-1"}>
                                <Card header={{title: intl.formatMessage({id: "metadata"})}}>
                                    <BlogPostMetadataPanel {...editProps}/>
                                </Card>
                            </div>
                        )
                }
            </div>
        </>
    );
};

export default BlogPostEditor;