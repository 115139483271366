import React from 'react';
import RequirementBadges, {RequirementBadgeProps} from "./RequirementBadges";

const RequirementsBadgeContainer = ({categories, section, setCategoryFilter, watch}: RequirementBadgeProps) => {
    return (
        <div className={"w-full flex flex-row overflow-x-auto pb-3 gap-3"}>
            <RequirementBadges
                setCategoryFilter={setCategoryFilter}
                categories={categories}
                section={section}
                watch={watch}
            />
        </div>
    );
};

export default RequirementsBadgeContainer;