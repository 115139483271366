import {createIntl, createIntlCache} from "react-intl";
import DutchMessages from "../../../../../../../messages/nl-NL";
import {ValueLabel} from "../../../EditQuestionForm";

const cache = createIntlCache();
const intl = createIntl({locale: 'nl-NL', messages: DutchMessages}, cache);

export const yesNoOptions: ValueLabel[] = [
    {value: "true", label: intl.formatMessage({id: "yes"})},
    {value: "false", label: intl.formatMessage({id: "no"})},
];

export const findYesNoOption = (state: boolean): ValueLabel => {
    return yesNoOptions.filter(c => c.value === (state ? "true" : "false"))[0];
};