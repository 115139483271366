import React, {useMemo, useState} from "react";
import {DataTable, DataTableColumn, Icon, NotificationService, TableButton, TableColumn, TableData} from "peggirkit";
import {theorieGoAxios} from "../../../../data/ApiEndpoints";
import {TrashIcon} from "@heroicons/react/24/outline";
import OpenIndicationBadge from "./OpenIndicationBadge";
import {ContactForm} from "../../../../data/Entities";
import {useIntl} from "react-intl";
import {StatusOpenFilter} from "../ContactFormsOverview";
import DeleteContactFormsModal from "../DeleteContactFormsModal";
import router from "../../../../routing/Router";

type Props = {
    statusOpenFilter: StatusOpenFilter
};

type DeleteContactFormsOptions = {
    showDialog: boolean,
    selectedContactFormsToDelete: ContactForm[]
};

const dataTableBaseUrl = (statusOpenFilter: StatusOpenFilter): string => {
    const dataUrl = new URL(`${process.env.BACKEND_API_BASE_URL}/admin/contact`);
    if (statusOpenFilter !== null) {
        dataUrl.searchParams.set("openStatus", statusOpenFilter + "");
    }

    return dataUrl.toString();
}

const ContactFormsDataTable = ({statusOpenFilter}: Props) => {
    const intl = useIntl();
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const [deleteContactFormsOptions, setDeleteContactFormsOptions] = useState<DeleteContactFormsOptions>({
        showDialog: false,
        selectedContactFormsToDelete: [],
    });

    const columns: (TableColumn & DataTableColumn)[] = useMemo(() => ([
        {
            dataName: "id",
            name: intl.formatMessage({id: "id"}),
            sortable: true,
            secondary: true
        },
        {
            name: intl.formatMessage({id: "name"}),
            sortable: false,
            secondary: false,
            formatter: (_, contactForm): JSX.Element => (
                <>{contactForm.firstName} {contactForm.lastName}</>
            )
        },
        {
            dataName: "openStatus",
            name: intl.formatMessage({id: "status"}),
            sortable: false,
            secondary: true,
            alignRight: true,
            formatter: (openStatus: StatusOpenFilter) => <OpenIndicationBadge openStatus={openStatus}/>
        },
        {
            dataName: "creationDate",
            name: intl.formatMessage({id: "sentOn"}),
            sortable: true,
            secondary: true,
            sorted: "desc",
            alignRight: true,
            formatter: (creationDate: string) => new Date(creationDate).toLocaleString()
        },
        {
            hideLabel: true,
            name: intl.formatMessage({id: "options"}),
            sortable: false,
            alignRight: true,
            secondary: true,
            formatter: (_, contactForm): JSX.Element => (
                <TableButton
                    text={intl.formatMessage({id: "view"})}
                    href={router.contactForm.absolutePath(contactForm.id)}
                />
            )
        }
    ]), []);

    return (
        <>
            <DataTable
                initialLastUpdated={lastUpdated}
                withUrlPageParameter={true}
                scrollToTopOnPageChange={true}
                initialPageRequest={{
                    axios: theorieGoAxios,
                    baseUrl: dataTableBaseUrl(statusOpenFilter),
                    pageSize: 20,
                    pageNumber: 0,
                }}
                columns={columns}
                summaryFormatter={(from: number, to: number, total: number): JSX.Element | string => (
                    intl.formatMessage({id: "paginationSummary"}, {from: from, to: to, total: total})
                )}
                messages={{
                    next: intl.formatMessage({id: "next"}),
                    previous: intl.formatMessage({id: "previous"})
                }}
                batchActions={{
                    dropdownButtonText: (itemsSelected: number) =>
                        intl.formatMessage({id: "nFormsSelected"}, {n: itemsSelected}),
                    toggleSelectAllText: intl.formatMessage({id: "selectAll"}),
                    selectRowText: intl.formatMessage({id: "selectRow"}),
                    actions: [
                        [
                            {
                                type: "danger",
                                icon: TrashIcon as Icon,
                                text: intl.formatMessage({id: "delete"}),
                                disabled: false,
                                onClick: (rawData: TableData[]) => {
                                    setDeleteContactFormsOptions({
                                        ...deleteContactFormsOptions,
                                        selectedContactFormsToDelete: rawData as ContactForm[],
                                        showDialog: true
                                    });
                                }
                            }
                        ]
                    ]
                }}
                alwaysShowToolbar={true}
            />

            <DeleteContactFormsModal
                show={deleteContactFormsOptions.showDialog}
                setShow={(show: boolean) => setDeleteContactFormsOptions({
                    ...deleteContactFormsOptions,
                    showDialog: show
                })}
                contactForms={deleteContactFormsOptions.selectedContactFormsToDelete}
                cb={() => {
                    setLastUpdated(new Date());
                    NotificationService.notify({
                        type: "success",
                        text: intl.formatMessage(
                            {id: "nContactFormsDeleted"},
                            {n: deleteContactFormsOptions.selectedContactFormsToDelete.length})
                    });
                    setDeleteContactFormsOptions({
                        ...deleteContactFormsOptions,
                        showDialog: false,
                        selectedContactFormsToDelete: []
                    });
                }}
            />
        </>
    );
};

export default ContactFormsDataTable;