import React, {useEffect, useMemo, useState} from "react";
import TheorieGoFrame from "./layout/TheorieGoFrame";
import router, {
    breadcrumbItemDashboard,
    breadcrumbItemEditExam,
    breadcrumbItemExams,
    newExamPart
} from "../../../routing/Router";
import {BreadcrumbsContent} from "peggirkit";
import {useIntl} from "react-intl";
import {ExamDetails} from "../../../data/Entities";
import EditExamContainer from "../../widgets/exams/exam/EditExamContainer";
import {useNavigate, useParams} from "react-router-dom";
import {getExam} from "../../../data/ApiEndpoints";

const EditExam = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const {examId} = useParams();
    const [loadingData, setLoadingData] = useState(true);
    const [exam, setExam] = useState<ExamDetails | undefined>();
    const currentBreadcrumbItem = useMemo(() => breadcrumbItemEditExam(exam && exam.id || -1, exam?.nameNl), [exam]);
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemExams, currentBreadcrumbItem]
    };

    // Load existing exam
    useEffect(() => {
        if (!examId || examId === newExamPart) {
            setExam(undefined);
            setLoadingData(false);
            return;
        }

        getExam(parseInt(examId)).then((e: ExamDetails) => {
            setExam(e);
            setLoadingData(false);
        }).catch(_ => {
            navigate(router.error.absolutePath());
        });
    }, [examId]);

    return (
        <TheorieGoFrame title={currentBreadcrumbItem.name}>
            <EditExamContainer
                key={exam?.id || ""}
                currentBreadcrumbItem={currentBreadcrumbItem}
                breadcrumbs={breadcrumbs}
                loadingData={loadingData}
                existingExam={exam}
            />
        </TheorieGoFrame>
    );
};

export default EditExam;