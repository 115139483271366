import React from "react";
import {SectionHeading} from "peggirkit";
import {useIntl} from "react-intl";
import {Student} from "../../../../../data/Entities";
import NoPaymentDetailsAlert from "./NoPaymentDetailsAlert";
import PaymentDetails from "./PaymentDetails";

type Props = {
    student: Student
};

const FinancesTab = ({student}: Props) => {
    const intl = useIntl();

    return (
        <>
            <SectionHeading
                title={intl.formatMessage({id: "finances"})}
                description={intl.formatMessage({id: "financesDescription"})}
            />

            {
                !student.order
                    ? <NoPaymentDetailsAlert/>
                    : <PaymentDetails order={student.order}/>
            }
        </>
    );
};

export default FinancesTab;