import React from "react";
import {breadcrumbItemContactForms, breadcrumbItemStudents} from "../../../routing/Router";
import TheorieGoFrame from "./layout/TheorieGoFrame";
import StudentsOverview from "../../widgets/students/StudentsOverview";
import ContactFormsOverview from "../../widgets/contactForms/ContactFormsOverview";

const ContactForms = () => {
    return (
        <TheorieGoFrame title={breadcrumbItemContactForms.name}>
            <ContactFormsOverview/>
        </TheorieGoFrame>
    );
};

export default ContactForms;