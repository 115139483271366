import React from "react";
import {Card, classNames} from "peggirkit";
import {useIntl} from "react-intl";
import {Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatToShortDate} from "../../../../../utils/DateUtils";
import {ExamStats} from "../../../../../data/Entities";
import {axisStyling} from "../../sales/revenue/RevenueCard";
import {StatisticsSettings} from "../../StatisticsOverview";
import TakenExamsChartTooltip from "./chart/TakenExamsChartTooltip";

type Props = {
    statisticsSettings: StatisticsSettings
    examStats: ExamStats | undefined,
};

const TakenExamsCard = ({statisticsSettings, examStats}: Props) => {
    const intl = useIntl();
    return (
        <Card header={{title: intl.formatMessage({id: "takenExams"})}}>
            <div
                className={classNames(
                    "w-full h-[250px] lg:h-[250px]",
                    statisticsSettings.loadingStudentStats ? "animate-pulse" : ""
                )}
            >
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        width={500}
                        height={400}
                        data={examStats?.examsPerDay || []}
                    >
                        <CartesianGrid
                            stroke="#e5e7eb"
                            strokeDasharray="5 5"
                        />

                        <XAxis
                            tick={axisStyling}
                            dataKey="date"
                            tickMargin={12}
                            tickLine={false}
                            strokeWidth={3}
                            stroke={"#e5e7eb"}
                            tickFormatter={val => formatToShortDate(val)}
                        />

                        <YAxis
                            width={30}
                            tick={axisStyling}
                            tickLine={false}
                            axisLine={false}
                            allowDecimals={false}
                        />

                        <Bar
                            dataKey="passed"
                            name={intl.formatMessage({id: "passed"})}
                            stackId="a"
                            fill="#059669"
                            barSize={10}
                        />
                        <Bar
                            dataKey="failed"
                            name={intl.formatMessage({id: "failed"})}
                            stackId="a"
                            fill="#dc2626"
                            barSize={10}
                        />
                        <Bar
                            dataKey="notFinished"
                            name={intl.formatMessage({id: "notFinished"})}
                            stackId="a"
                            fill="#94a3b8"
                            barSize={10}
                        />

                        <Line
                            type="monotone"
                            dataKey="totalExams"
                            name={intl.formatMessage({id: "totalExams"})}
                            stroke="#cc9900"
                            strokeWidth={3}

                        />

                        <Tooltip content={TakenExamsChartTooltip}/>
                        <Legend wrapperStyle={{fontSize: "0.75rem"}}/>
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </Card>
    );
};

export default TakenExamsCard;