import React from "react";
import {InputField} from "peggirkit";
import EditMetadataCard from "./EditMetadataCard";
import {EditFaqQuestionProps} from "../EditFaqQuestionContainer";
import {useIntl} from "react-intl";

const EditDescriptionCard = ({
                                 loading,
                                 register,
                                 errors,
                                 watch
                             }: EditFaqQuestionProps) => {
    const intl = useIntl();
    return (
        <EditMetadataCard name={intl.formatMessage({id: "description"})}>
            <InputField
                type={"textarea"}
                textareaHeight={"h-24"}
                id={"metaDescriptionNl"}
                reactHookForm={register("metaDescriptionNl", {
                    required: intl.formatMessage({id: "mustEnterField"}),
                    maxLength: {
                        value: 180,
                        message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 180}),
                    },
                })}
                displayName={intl.formatMessage({id: "descriptionNl"})}
                tip={intl.formatMessage({id: "nOutOfTCharacters"}, {
                    n: watch("metaDescriptionNl")?.length || 0,
                    t: 180
                })}
                error={errors.metaDescriptionNl && errors.metaDescriptionNl.message}
                disabled={loading}
            />
            <InputField
                type={"textarea"}
                textareaHeight={"h-24"}
                id={"metaDescriptionAr"}
                rtl={true}
                reactHookForm={register("metaDescriptionAr", {
                    required: intl.formatMessage({id: "mustEnterField"}),
                    maxLength: {
                        value: 180,
                        message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 180}),
                    },
                })}
                displayName={intl.formatMessage({id: "descriptionAr"})}
                tip={intl.formatMessage({id: "nOutOfTCharacters"}, {
                    n: watch("metaDescriptionAr")?.length || 0,
                    t: 180
                })}
                error={errors.metaDescriptionAr && errors.metaDescriptionAr.message}
                disabled={loading}
                alignRight={true}
            />
        </EditMetadataCard>
    );
};

export default EditDescriptionCard;