import React from "react";
import {Alert, FormLayoutCard} from "peggirkit";
import {useIntl} from "react-intl";
import {ExamQuestion} from "../../../../../../data/Entities";
import AudioPlayers from "./AudioPlayers";

type Props = {
    question?: ExamQuestion
};

const AudioSection = ({question}: Props) => {
    const intl = useIntl();

    return (
        <FormLayoutCard
            title={intl.formatMessage({id: "audio"})}
            description={intl.formatMessage({id: "editExamQuestion.section.audio.description"})}
        >
            <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 lg:col-span-4">
                    {
                        question === undefined &&
                        <Alert
                            type={"info"}
                            title={intl.formatMessage({id: "text-to-speech-status"})}
                            description={intl.formatMessage({id: "text-to-speech-status.description.new-question"})}
                        />
                    }
                    {
                        (question && question.questionAudio === null) &&
                        <Alert
                            type={"info"}
                            title={intl.formatMessage({id: "text-to-speech-status"})}
                            description={intl.formatMessage({id: "text-to-speech-status.description.no-audio"})}
                        />
                    }
                    {
                        (question && question.questionAudio !== null && question.questionAudio.queued) &&
                        <Alert
                            type={"info"}
                            title={intl.formatMessage({id: "text-to-speech-status"})}
                            description={intl.formatMessage({id: "text-to-speech-status.description.in-queue"})}
                        />
                    }
                    {
                        (question && question.questionAudio !== null && !question.questionAudio.queued) &&
                        <AudioPlayers file={question.questionAudio.file}/>
                    }
                </div>
            </div>
        </FormLayoutCard>
    );
};

export default AudioSection;