import React from "react";
import {breadcrumbItemDashboard} from "../../../routing/Router";
import TheorieGoFrame from "./layout/TheorieGoFrame";
import DashboardLinks from "../../widgets/dashboard/links/DashboardLinks";
import DashboardHeading from "../../widgets/dashboard/DashboardHeading";

const Dashboard = () => {

    return (
        <TheorieGoFrame title={breadcrumbItemDashboard.name}>
            <DashboardHeading/>
            <DashboardLinks/>
        </TheorieGoFrame>
    );
};

export default Dashboard;