import React from "react";
import {Badge} from "peggirkit";
import {Exam} from "../../../../../../../data/Entities";
import {useIntl} from "react-intl";
import router from "../../../../../../../routing/Router";

export type Props = {
    exam: Exam
};

const InExamBadge = ({exam}: Props) => {
    const intl = useIntl();
    return (
        <a
            target={"_blank"}
            href={router.editExam.absolutePath(exam.id)}
        >
            <Badge
                type={exam.concept ? "gray" : "green"}
                content={exam.nameNl}
                tooltip={exam.concept ? intl.formatMessage({id: "concept"}) : intl.formatMessage({id: "public"})}
            />
        </a>
    );
};

export default InExamBadge;