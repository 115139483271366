import React from "react";
import {Button, EmptyState, Table, TableIconButton} from "peggirkit";
import {ArrowPathRoundedSquareIcon, ListBulletIcon, MinusCircleIcon} from "@heroicons/react/24/outline";
import {DropResult} from "react-beautiful-dnd";
import QuestionFormatter from "../../../../questions/overview/dataTable/formatter/QuestionFormatter";
import {FieldArrayWithId, UseFieldArrayMove, UseFieldArrayRemove} from "react-hook-form";
import {ExamDetails} from "../../../../../../data/Entities";
import {RequirementBadgeProps} from "./RequirementBadges";
import RequirementsBadgeContainer from "./RequirementsBadgeContainer";
import {useIntl} from "react-intl";

type Props = {
    fields: (FieldArrayWithId<ExamDetails, "hazardRecognitionQuestions">
        | FieldArrayWithId<ExamDetails, "knowledgeQuestions">
        | FieldArrayWithId<ExamDetails, "insightQuestions">)[],
    move: UseFieldArrayMove,
    remove: UseFieldArrayRemove,
};

const QuestionsInExam = ({
                             categories,
                             section,
                             setCategoryFilter,
                             fields,
                             move,
                             remove,
                             watch
                         }: Props & RequirementBadgeProps) => {
    const intl = useIntl();

    const shuffle = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        for (let i = fields.length - 1; i > 0; i--) {
            move(i, Math.floor(Math.random() * (i + 1)));
        }
    };

    return (
        <div className={"flex flex-col gap-2"}>
            <RequirementsBadgeContainer
                watch={watch}
                section={section}
                categories={categories}
                setCategoryFilter={setCategoryFilter}
            />

            <div>
                <Button
                    type={"white"}
                    disabled={fields.length <= 1}
                    icon={ArrowPathRoundedSquareIcon}
                    text={intl.formatMessage({id: "shuffleOrder"})}
                    onClick={shuffle}
                />
            </div>

            <Table
                sortingDisabled={true}
                emptyState={
                    <EmptyState
                        className={""}
                        icon={ListBulletIcon}
                        title={intl.formatMessage({id: "noQuestions"})}
                        description={intl.formatMessage({id: "noQuestions.explanation"})}
                    />
                }
                draggableRowsOptions={{
                    onDragEnd: (result: DropResult) => {
                        if (result.destination === undefined || result.destination === null) {
                            return;
                        }

                        move(result.source.index, result.destination.index);
                    }
                }}
                columns={[
                    {
                        name: intl.formatMessage({id: "question"}),
                        sortable: false,
                        secondary: false
                    },
                    {
                        name: intl.formatMessage({id: "options"}),
                        hideLabel: true,
                        secondary: true,
                        sortable: false,
                        alignRight: true,
                    }
                ]}
                data={
                    fields.map((f, index) => ({
                        [intl.formatMessage({id: "question"})]: <QuestionFormatter
                            questionNl={f.question.questionNl}
                            data={f.question}
                            separateImageTooltip={true}
                        />,
                        [intl.formatMessage({id: "options"})]: <TableIconButton
                            title={"Weghalen"}
                            type={"danger"}
                            icon={MinusCircleIcon}
                            href={"#"}
                            onClick={(e) => {
                                e.preventDefault();
                                remove(index);
                            }}
                        />
                    }))
                }
            />
        </div>
    );
};

export default QuestionsInExam;