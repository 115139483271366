import {CheckIcon} from "@heroicons/react/24/outline";
import {ExamCategory} from "../../../data/Entities";
import {DropdownActions} from "peggirkit/dist/components/Button/Dropdown/Groups";
import {createIntl, createIntlCache} from "react-intl";
import DutchMessages from "../../../messages/nl-NL";

const cache = createIntlCache();
const intl = createIntl({locale: 'nl-NL', messages: DutchMessages}, cache);

export type QuestionFilterCategory = ExamCategory | null;

export const categoryFilterOptions = (
    currentFilter: QuestionFilterCategory,
    setCurrentFilter: ((category: QuestionFilterCategory) => void),
    categories: ExamCategory[]
): DropdownActions => {
    return [[
        {
            disabled: currentFilter === null,
            icon: currentFilter === null ? CheckIcon : undefined,
            text: formatCategoryOption(null),
            onClick: () => setCurrentFilter(null)
        }
    ],
        categories.map(c => ({
            disabled: currentFilter === c,
            icon: currentFilter === c ? CheckIcon : undefined,
            text: formatCategoryOption(c),
            onClick: () => setCurrentFilter(c)
        }))
    ];
};

export const formatCategoryOption = (c: ExamCategory | null) => {
    if (c === null) {
        return intl.formatMessage({id: "anyCategory"});
    }

    return (c.code ? c.code + " - " : "") + c.nameNl;
}