import React from "react";
import router, {breadcrumbItemDashboard, breadcrumbItemQuestions} from "../../../../routing/Router";
import {CheckIcon, FunnelIcon, PlusIcon} from "@heroicons/react/24/outline";
import {BreadcrumbsContent, PageHeading} from "peggirkit";
import {ExamCategory} from "../../../../data/Entities";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import {QuestionFilterConcept, QuestionFilterSection} from "./QuestionsOverview";
import {categoryFilterOptions, QuestionFilterCategory} from "../../categories/CategoryFilterOptions";

type Props = {
    conceptFilter: QuestionFilterConcept,
    setConceptFilter: (concept: QuestionFilterConcept) => void,
    sectionFilter: QuestionFilterSection,
    setSectionFilter: (section: QuestionFilterSection) => void,
    categoryFilter: QuestionFilterCategory,
    setCategoryFilter: (category: QuestionFilterCategory) => void,
    loading: boolean,
    categories: ExamCategory[],
};

const StudentsOverviewPageHeading = ({
                                         loading,
                                         conceptFilter,
                                         setConceptFilter,
                                         sectionFilter,
                                         setSectionFilter,
                                         categoryFilter,
                                         setCategoryFilter,
                                         categories
                                     }: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemQuestions]
    };

    return (
        <PageHeading
            breadcrumbsContent={breadcrumbs}
            title={breadcrumbItemQuestions.name}
            actions={{
                moreButtonText: intl.formatMessage({id: "more"}),
                disabled: loading,
                actions: [
                    {
                        type: categoryFilter !== null ? "whiteBlueAccent" : undefined,
                        actionType: "dropdown",
                        text: intl.formatMessage({id: "category"}),
                        primary: false,
                        iconOnly: false,
                        icon: FunnelIcon,
                        options: categoryFilterOptions(categoryFilter, setCategoryFilter, categories)
                    },
                    {
                        type: sectionFilter !== QuestionFilterSection.ANY ? "whiteBlueAccent" : undefined,
                        actionType: "dropdown",
                        text: intl.formatMessage({id: "section"}),
                        primary: false,
                        iconOnly: false,
                        icon: FunnelIcon,
                        options: [
                            [
                                {
                                    disabled: QuestionFilterSection.ANY === sectionFilter,
                                    icon: QuestionFilterSection.ANY === sectionFilter ? CheckIcon : undefined,
                                    text: intl.formatMessage({id: "anySection"}),
                                    onClick: () => setSectionFilter(QuestionFilterSection.ANY)
                                }
                            ],
                            [
                                {
                                    disabled: QuestionFilterSection.NONE === sectionFilter,
                                    icon: QuestionFilterSection.NONE === sectionFilter
                                        ? CheckIcon
                                        : undefined,
                                    text: intl.formatMessage({id: "noSection"}),
                                    onClick: () => setSectionFilter(QuestionFilterSection.NONE)
                                }
                            ],
                            [
                                {
                                    disabled: QuestionFilterSection.HAZARD_RECOGNITION === sectionFilter,
                                    icon: QuestionFilterSection.HAZARD_RECOGNITION === sectionFilter
                                        ? CheckIcon
                                        : undefined,
                                    text: intl.formatMessage({id: "hazardRecognition"}),
                                    onClick: () => setSectionFilter(QuestionFilterSection.HAZARD_RECOGNITION)
                                },
                                {
                                    disabled: QuestionFilterSection.KNOWLEDGE === sectionFilter,
                                    icon: QuestionFilterSection.KNOWLEDGE === sectionFilter ? CheckIcon : undefined,
                                    text: intl.formatMessage({id: "knowledge"}),
                                    onClick: () => setSectionFilter(QuestionFilterSection.KNOWLEDGE)
                                },
                                {
                                    disabled: QuestionFilterSection.INSIGHT === sectionFilter,
                                    icon: QuestionFilterSection.INSIGHT === sectionFilter ? CheckIcon : undefined,
                                    text: intl.formatMessage({id: "insight"}),
                                    onClick: () => setSectionFilter(QuestionFilterSection.INSIGHT)
                                },
                            ],
                        ]
                    },
                    {
                        type: conceptFilter !== null ? "whiteBlueAccent" : undefined,
                        actionType: "dropdown",
                        text: intl.formatMessage({id: "status"}),
                        primary: false,
                        iconOnly: false,
                        icon: FunnelIcon,
                        options: [
                            [
                                {
                                    disabled: conceptFilter === null,
                                    icon: conceptFilter === null ? CheckIcon : undefined,
                                    text: intl.formatMessage({id: "anyStatus"}),
                                    onClick: () => setConceptFilter(null)
                                }
                            ],
                            [
                                {
                                    disabled: conceptFilter === false,
                                    icon: conceptFilter === false ? CheckIcon : undefined,
                                    text: intl.formatMessage({id: "public"}),
                                    onClick: () => setConceptFilter(false)
                                },
                                {
                                    disabled: conceptFilter === true,
                                    icon: conceptFilter === true ? CheckIcon : undefined,
                                    text: intl.formatMessage({id: "concept"}),
                                    onClick: () => setConceptFilter(true)
                                },
                            ],
                        ]
                    },
                    {
                        actionType: "button",
                        text: intl.formatMessage({id: "newQuestion"}),
                        primary: true,
                        icon: PlusIcon,
                        onClick: () => navigate(router.newQuestion.absolutePath())
                    }
                ]
            }}
        />
    );
};

export default StudentsOverviewPageHeading;