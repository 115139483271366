import React from "react";
import {Badge} from "peggirkit";
import {useIntl} from "react-intl";

type Props = {
    concept: boolean
};

const ConceptFormatter = ({concept}: Props) => {
    const intl = useIntl();
    return (
        <Badge
            type={concept ? "gray" : "green"}
            content={concept ? intl.formatMessage({id: "concept"}) : intl.formatMessage({id: "public"})}
        />
    );
};

export default ConceptFormatter;