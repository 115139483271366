import React, {useMemo} from "react";
import {ExamCategory} from "../../../../../../data/Entities";
import {QuestionFilterSection} from "../../../../questions/overview/QuestionsOverview";
import {Badge} from "peggirkit";
import {formatCategoryOption} from "../../../../categories/CategoryFilterOptions";
import {UseFormWatch} from "react-hook-form";
import {useIntl} from "react-intl";
import {ExamDetailsForm} from "../../EditExamContainer";

export type RequirementBadgeProps = {
    categories: ExamCategory[],
    section: QuestionFilterSection,
    setCategoryFilter: (category: ExamCategory) => void,
    watch: UseFormWatch<ExamDetailsForm>
};

type ExamRequirementBadge = {
    category: ExamCategory,
    formattedName: string,
    requiredInExam: number,
    currentInExam: number
};

type SectionMap = {
    categoryProp: "requiredInHazardRecognitionSection" | "requiredInKnowledgeSection" | "requiredInInsightSection",
    examProp: "hazardRecognitionQuestions" | "knowledgeQuestions" | "insightQuestions",
};

const mapSection = (section: QuestionFilterSection): SectionMap => {
    if (section === QuestionFilterSection.HAZARD_RECOGNITION) {
        return {
            categoryProp: "requiredInHazardRecognitionSection",
            examProp: "hazardRecognitionQuestions"
        };
    } else if (section === QuestionFilterSection.KNOWLEDGE) {
        return {
            categoryProp: "requiredInKnowledgeSection",
            examProp: "knowledgeQuestions"
        };
    } else {
        return {
            categoryProp: "requiredInInsightSection",
            examProp: "insightQuestions"
        }
    }
};

const RequirementBadges = ({categories, section, setCategoryFilter, watch}: RequirementBadgeProps) => {
    const intl = useIntl();
    const badges: ExamRequirementBadge[] = useMemo(() => {
            const sectionProp = mapSection(section);
            return categories.filter(c => c[sectionProp.categoryProp] > 0).map(c => ({
                category: c,
                formattedName: formatCategoryOption(c),
                requiredInExam: c[sectionProp.categoryProp],
                currentInExam: watch(sectionProp.examProp).filter(qie => qie.question.category.id === c.id).length
            }))
        },
        [categories, section, watch("hazardRecognitionQuestions"), watch("knowledgeQuestions"),
            watch("insightQuestions")]);

    return (
        <>
            {
                badges.map((badge, i) => (
                    <div key={i} className={"shrink-0"}>
                        <button
                            onClick={() => setCategoryFilter(badge.category)}
                        >
                            <Badge
                                tooltip={intl.formatMessage({id: "applyFilter"})}
                                type={badge.requiredInExam === badge.currentInExam ? "green" : "red"}
                                content={`${badge.formattedName}: ${badge.currentInExam}/${badge.requiredInExam}`}
                            />
                        </button>
                    </div>
                ))
            }
        </>
    );
};

export default RequirementBadges;