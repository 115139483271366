import React from "react";
import {AuthFormFooter} from "peggirkit";
import {useIntl} from "react-intl";

const AuthFormFooterSignIn = () => {
    const intl = useIntl();
    return (
        <AuthFormFooter
            message={intl.formatMessage({id: "goToHomePage"})}
            link={process.env.LANDING_BASE_URL || ""}
            linkText={"TheorieGO"}
        />
    );
};

export default AuthFormFooterSignIn;