import React, {useEffect, useMemo, useState} from "react";
import {Button, Card, Form, Icon, InputCreatableSelect, InputField, NotificationService} from "peggirkit";
import {useIntl} from "react-intl";
import {CheckIcon} from "@heroicons/react/24/outline";
import {useForm} from "react-hook-form";
import {ContactForm} from "../../../../../data/Entities";
import {updateContactForm} from "../../../../../data/ApiEndpoints";

type OpenStatus = {
    label: string,
    value: "true" | "false"
};

type FormFields = {
    internalNote: string,
    openStatus: OpenStatus,
};

type Props = {
    setContactForm: (form: ContactForm) => void,
    contactForm: ContactForm | undefined,
};

const ContactFormAdminCard = ({contactForm, setContactForm}: Props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const {register, control, setValue, getValues, watch, handleSubmit, formState: {errors}} = useForm<FormFields>();
    const openStatusOptions: OpenStatus[] = useMemo(() => ([
        {
            label: intl.formatMessage({id: "open"}),
            value: "true",
        },
        {
            label: intl.formatMessage({id: "closed"}),
            value: "false",
        }
    ]), [intl]);

    // Init. data
    useEffect(() => {
        if (!contactForm) {
            return;
        }

        setValue("internalNote", contactForm.internalNote);
        setValue("openStatus", openStatusOptions.filter(s => (s.value === "true") === contactForm.openStatus)[0]);
    }, [contactForm]);

    // Update
    useEffect(() => {
        if (!loading || !contactForm) {
            return;
        }

        const submittedData = getValues();
        updateContactForm({
            id: contactForm.id,
            internalNote: submittedData.internalNote,
            openStatus: submittedData.openStatus.value === "true",
        }).then((updatedForm: ContactForm) => {
            setLoading(false);
            setContactForm(updatedForm);
            NotificationService.notify({type: "success", text: intl.formatMessage({id: "savedChanged"})});
        }).catch(_ => {
            NotificationService.notify({type: "danger", text: intl.formatMessage({id: "operationError"})});
            setLoading(false);
        });
    }, [loading]);

    return (
        <Card header={{title: intl.formatMessage({id: "actions"})}}>
            <Form
                onSubmit={handleSubmit(() => setLoading(true))}
                error={null}
            >
                <InputCreatableSelect
                    id={"openStatus"}
                    displayName={intl.formatMessage({id: "status"})}
                    nameHookForm={"openStatus"}
                    placeholder={intl.formatMessage({id: "pickAStatus"})}
                    control={control}
                    rules={{required: intl.formatMessage({id: "pickAStatus"})}}
                    disabled={contactForm === undefined}
                    formatCreateLabel={() => ""}
                    options={openStatusOptions}
                    isValidNewOption={() => false}
                    tip={intl.formatMessage({id: "contactFormStatusTip"})}
                    error={errors.openStatus && errors.openStatus.message}
                />

                <InputField
                    type={"textarea"}
                    id={"internalNote"}
                    reactHookForm={register("internalNote", {
                        max: {
                            value: 4096,
                            message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 4096})
                        }
                    })}
                    displayName={intl.formatMessage({id: "internalNote"})}
                    disabled={contactForm === undefined}
                    textareaHeight={"h-96"}
                    tip={intl.formatMessage({id: "nOutOfTCharacters"},
                        {n: watch("internalNote")?.length || 0, t: 4096})}
                    error={errors.internalNote && errors.internalNote.message}
                />

                <div className={"flex justify-end"}>
                    <Button
                        type={"primary"}
                        text={intl.formatMessage({id: "save"})}
                        icon={CheckIcon as Icon}
                        disabled={contactForm === undefined}
                    />
                </div>
            </Form>
        </Card>
    );
};

export default ContactFormAdminCard;