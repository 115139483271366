import React from "react";
import {InputField, Link} from "peggirkit";
import {FormattedMessage, useIntl} from "react-intl";
import EditMetadataCard from "./EditMetadataCard";
import {EditFaqQuestionProps} from "../EditFaqQuestionContainer";

const EditSlugCard = ({
                          loading,
                          register,
                          errors,
                          watch
                      }: EditFaqQuestionProps) => {
    const intl = useIntl();
    return (
        <EditMetadataCard name={intl.formatMessage({id: "slug"})}>
            <InputField
                type={"text"}
                id={"slugNl"}
                reactHookForm={register("slugNl", {
                    required: intl.formatMessage({id: "mustEnterField"}),
                    maxLength: {
                        value: 128,
                        message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 128}),
                    },
                })}
                displayName={intl.formatMessage({id: "slugNl"})}
                tip={<>
                    <FormattedMessage
                        id={"nOutOfTCharacters"}
                        values={{
                            n: watch("slugNl")?.length || 0,
                            t: 128
                        }}
                    />
                    <br/>
                    <Link
                        type={"default"}
                        target={"_blank"}
                        text={`https://theoriego.nl/faq/${watch("slugNl") || "..."}`}
                        href={`https://theoriego.nl/faq/${watch("slugNl") || "..."}`}
                    />
                </>}
                error={errors.slugNl && errors.slugNl.message}
                disabled={loading}
            />

            <InputField
                type={"text"}
                id={"slugAr"}
                reactHookForm={register("slugAr", {
                    required: intl.formatMessage({id: "mustEnterField"}),
                    maxLength: {
                        value: 128,
                        message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 128}),
                    },
                })}
                displayName={intl.formatMessage({id: "slugAr"})}
                tip={<>
                    <FormattedMessage
                        id={"nOutOfTCharacters"}
                        values={{
                            n: watch("slugAr")?.length || 0,
                            t: 128
                        }}
                    />
                    <br/>
                    <Link
                        type={"default"}
                        target={"_blank"}
                        text={`https://theoriego.nl/ar/faq/${watch("slugAr") || "..."}`}
                        href={`https://theoriego.nl/ar/faq/${watch("slugAr") || "..."}`}
                    />
                </>}
                error={errors.slugAr && errors.slugAr.message}
                disabled={loading}
            />
        </EditMetadataCard>
    );
};

export default EditSlugCard;