import React, {useEffect, useMemo, useState} from "react";
import {
    breadcrumbItemDashboard,
    breadcrumbItemStudent,
    breadcrumbItemStudents,
    newStudentPart
} from "../../../routing/Router";
import TheorieGoFrame from "./layout/TheorieGoFrame";
import {useParams} from "react-router-dom";
import {BreadcrumbsContent, PageHeading} from "peggirkit";
import {Student as StudentEntity} from "../../../data/Entities";
import StudentDetails from "../../widgets/students/student/StudentDetails";
import {getStudent} from "../../../data/ApiEndpoints";
import {useIntl} from "react-intl";

const Student = () => {
    const intl = useIntl();
    const {studentId} = useParams();
    const [student, setStudent] = useState<StudentEntity | undefined>();
    const [loadingError, setLoadingError] = useState(false);
    const currentBreadcrumbItem = useMemo(() => {
        return breadcrumbItemStudent(student && student.id || -1);
    }, [student]);
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemStudents, currentBreadcrumbItem]
    };

    // Load existing student
    useEffect(() => {
        if (studentId && studentId !== newStudentPart) {
            getStudent(parseInt(studentId))
                .then(s => setStudent(s))
                .catch(_ => setLoadingError(true));
        }
    }, []);

    if (loadingError) {
        throw new Response();
    }

    return (
        <TheorieGoFrame title={currentBreadcrumbItem.name}>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={currentBreadcrumbItem.name}
            />

            <StudentDetails student={student} setStudent={setStudent}/>
        </TheorieGoFrame>
    );
};

export default Student;