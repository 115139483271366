import React from "react";
import {SectionHeading} from "peggirkit";
import EditStudentForm from "./EditStudentForm";
import {Student} from "../../../../../data/Entities";
import {useIntl} from "react-intl";

type Props = {
    student?: Student,
    setStudent: (student: Student) => void
};

const GeneralTab = ({student, setStudent}: Props) => {
    const intl = useIntl();
    return (
        <div>
            <SectionHeading
                title={intl.formatMessage({id: "details"})}
                description={intl.formatMessage({id: "viewOrEditDetails"})}
            />

            <EditStudentForm
                student={student}
                setStudent={setStudent}
            />
        </div>
    );
};

export default GeneralTab;