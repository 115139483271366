import React, {useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {theorieGoAxios} from "../../../data/ApiEndpoints";
import {TrashIcon} from "@heroicons/react/24/outline";
import {DataTable, DataTableColumn, Icon, NotificationService, TableButton, TableColumn, TableData} from "peggirkit";
import {ExamCategory} from "../../../data/Entities";
import DeleteCategoriesModal from "./DeleteCategoriesModal";
import {EditCategoryOptions} from "./CategoriesOverview";
import RequirementsFormatter from "./formatter/RequirementsFormatter";
import PublicQuestionsPerSectionFormatter from "./formatter/PublicQuestionsPerSectionFormatter";

type DeleteCategoriesOptions = {
    showDialog: boolean,
    selectedCategoriesToDelete: ExamCategory[]
};

type Props = {
    editCategoryOptions: EditCategoryOptions,
    setEditCategoryOptions: (options: EditCategoryOptions) => void,
    lastUpdated: Date,
    setLastUpdated: (date: Date) => void
};

const CategoriesDataTable = ({editCategoryOptions, setEditCategoryOptions, lastUpdated, setLastUpdated}: Props) => {
    const intl = useIntl();
    const [deleteCategoriesOptions, setDeleteCategoriesOptions] = useState<DeleteCategoriesOptions>({
        showDialog: false,
        selectedCategoriesToDelete: [],
    });

    const columns: (TableColumn & DataTableColumn)[] = useMemo(() => {
        return [
            {
                dataName: "id",
                name: intl.formatMessage({id: "id"}),
                sortable: true,
                secondary: true
            },
            {
                dataName: "code",
                name: intl.formatMessage({id: "code"}),
                sortable: true,
                secondary: true,
                sorted: "asc"
            },
            {
                dataName: "nameNl",
                name: intl.formatMessage({id: "nameNl"}),
                sortable: true,
                secondary: false
            },
            {
                dataName: "nameAr",
                name: intl.formatMessage({id: "nameAr"}),
                sortable: true,
                secondary: true,
                alignRight: true
            },
            {
                name: "Publieke vragen per sectie",
                sortable: false,
                secondary: true,
                formatter: (_, category: TableData) =>
                    <PublicQuestionsPerSectionFormatter category={category as ExamCategory}/>
            },
            {
                name: intl.formatMessage({id: "examRequirements"}),
                sortable: false,
                secondary: true,
                alignRight: true,
                formatter: (_, category: TableData) => <RequirementsFormatter category={category as ExamCategory}/>
            },
            {
                hideLabel: true,
                name: intl.formatMessage({id: "options"}),
                sortable: false,
                alignRight: true,
                secondary: true,
                formatter: (_, category): JSX.Element => (
                    <TableButton
                        text={intl.formatMessage({id: "edit"})}
                        href={"#"}
                        onClick={e => {
                            e.preventDefault();
                            setEditCategoryOptions({
                                ...editCategoryOptions,
                                showDialog: true,
                                category: category as ExamCategory
                            });
                        }}
                    />
                )
            }
        ]
    }, []);

    return (
        <div className={"mt-8"}>
            <DataTable
                initialLastUpdated={lastUpdated}
                withUrlPageParameter={true}
                scrollToTopOnPageChange={true}
                initialPageRequest={{
                    axios: theorieGoAxios,
                    baseUrl: `${process.env.BACKEND_API_BASE_URL}/admin/categories`,
                    pageSize: 20,
                    pageNumber: 0,
                }}
                columns={columns}
                summaryFormatter={(from: number, to: number, total: number): JSX.Element | string => (
                    intl.formatMessage({id: "paginationSummary"}, {from: from, to: to, total: total})
                )}
                messages={{
                    next: intl.formatMessage({id: "next"}),
                    previous: intl.formatMessage({id: "previous"})
                }}
                batchActions={{
                    dropdownButtonText: (itemsSelected: number) =>
                        intl.formatMessage({id: "nItemsSelected"}, {n: itemsSelected}),
                    toggleSelectAllText: intl.formatMessage({id: "selectAll"}),
                    selectRowText: intl.formatMessage({id: "selectRow"}),
                    actions: [
                        [
                            {
                                type: "danger",
                                icon: TrashIcon as Icon,
                                text: intl.formatMessage({id: "delete"}),
                                disabled: false,
                                onClick: (rawData: TableData[]) => {
                                    setDeleteCategoriesOptions({
                                        ...deleteCategoriesOptions,
                                        selectedCategoriesToDelete: rawData as ExamCategory[],
                                        showDialog: true
                                    });
                                }
                            }
                        ]
                    ]
                }}
            />

            <DeleteCategoriesModal
                show={deleteCategoriesOptions.showDialog}
                setShow={(show: boolean) => setDeleteCategoriesOptions({...deleteCategoriesOptions, showDialog: show})}
                categories={deleteCategoriesOptions.selectedCategoriesToDelete}
                cb={() => {
                    setLastUpdated(new Date());
                    NotificationService.notify({
                        type: "success",
                        text: intl.formatMessage(
                            {id: "nCategoriesDeleted"},
                            {n: deleteCategoriesOptions.selectedCategoriesToDelete.length})
                    });
                    setDeleteCategoriesOptions({
                        ...deleteCategoriesOptions,
                        showDialog: false,
                        selectedCategoriesToDelete: []
                    });
                }}
            />
        </div>
    );
};

export default CategoriesDataTable;