import React, {useEffect, useState} from "react";
import {BreadcrumbItem, BreadcrumbsContent, isEmpty, NotificationService, PageHeading} from "peggirkit";
import {PlusIcon} from "@heroicons/react/20/solid";
import {CheckIcon} from "@heroicons/react/24/outline";
import EditQuestionForm, {ExamQuestionForm, NumberValueLabel} from "./EditQuestionForm";
import {useIntl} from "react-intl";
import {useForm} from "react-hook-form";
import {findSelectedAnswerTypeOption, SelectedAnswerType, SelectedAnswerTypeOption} from "./section/answer/AnswerType";
import router from "../../../../routing/Router";
import {useNavigate} from "react-router-dom";
import {ExamMultipleChoiceAnswerOption, ExamQuestion} from "../../../../data/Entities";
import {findConceptOption} from "./section/general/ConceptState";
import {findYesNoOption} from "./section/answer/yesNo/options";
import {transformAndValidateMultipleChoiceAnswer} from "./section/answer/multipleChoice/Validate";
import {transformAndValidateDragDropAnswer} from "./section/answer/dragDrop/Validate";
import {upsertExamQuestion} from "../../../../data/ApiEndpoints";

type Props = {
    currentBreadcrumb: BreadcrumbItem,
    breadcrumbs: BreadcrumbsContent,
    existingQuestion?: ExamQuestion,
    categoryOptions: NumberValueLabel[],
    loadingData: boolean
};

const getSelectedAnswerTypeOption = (examQuestion: ExamQuestion): SelectedAnswerTypeOption => {
    if (examQuestion.answerYes !== null) {
        return findSelectedAnswerTypeOption(SelectedAnswerType.YES_NO);
    } else if (examQuestion.numberAnswer !== null) {
        return findSelectedAnswerTypeOption(SelectedAnswerType.NUMBER);
    } else if (examQuestion.multipleChoiceAnswer !== null) {
        return findSelectedAnswerTypeOption(SelectedAnswerType.MULTIPLE_CHOICE);
    } else if (examQuestion.dragDropAnswer !== null) {
        return findSelectedAnswerTypeOption(SelectedAnswerType.DRAG_DROP);
    } else {
        return findSelectedAnswerTypeOption(SelectedAnswerType.NONE);
    }
};

const EditQuestionContainer = ({
                                   currentBreadcrumb,
                                   breadcrumbs,
                                   existingQuestion,
                                   categoryOptions,
                                   loadingData
                               }: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);
    const [examQuestion, _] = useState<ExamQuestion | undefined>(existingQuestion);
    const {
        register,
        control,
        setValue,
        getValues,
        watch,
        handleSubmit,
        reset,
        formState: {errors}
    } = useForm<ExamQuestionForm>({
        defaultValues: existingQuestion
            ? {
                ...existingQuestion,
                selectedAnswerType: getSelectedAnswerTypeOption(existingQuestion),
                concept: findConceptOption(existingQuestion.concept ? "true" : "false"),
                category: categoryOptions.filter(o => o.value === existingQuestion.category.id)[0],
                answerYes: existingQuestion.answerYes !== null ? findYesNoOption(existingQuestion.answerYes) : null,
            }
            : {
                selectedAnswerType: findSelectedAnswerTypeOption(SelectedAnswerType.NONE),
            }
    });
    const setFormValidationError = (message: string) => {
        setFormError(message);
        setLoading(false);
        window.scrollTo(0, 0);
    };

    // Reset default values for new question
    useEffect(() => {
        if (!existingQuestion) {
            reset();
        }
    }, [existingQuestion]);

    // Save question
    useEffect(() => {
        if (!loading) {
            return;
        }

        setFormError(null);
        let values: ExamQuestionForm = getValues();

        // Validate multiple-choice or drag-drop answer
        try {
            values = transformAndValidateMultipleChoiceAnswer(values);
            values = transformAndValidateDragDropAnswer(values);
        } catch (e: unknown) {
            if (e instanceof Error) {
                setFormValidationError(e.message);
                return;
            }
        }

        // Submit data
        const toSubmit: ExamQuestion = {
            id: values.id,
            questionNl: values.questionNl,
            questionAr: values.questionAr,
            image: isEmpty(values.image) ? null : values.image,
            explanationNl: values.explanationNl,
            explanationAr: values.explanationAr,
            concept: values.concept.value === "true",
            category: {
                id: values.category.value
            },
            hazardRecognitionSection: values.hazardRecognitionSection,
            knowledgeSection: values.knowledgeSection,
            insightSection: values.insightSection,
            answerYes: values.selectedAnswerType.value === SelectedAnswerType.YES_NO
                ? values.answerYes?.value === "true"
                : null,
            numberAnswer: values.selectedAnswerType.value === SelectedAnswerType.NUMBER
                ? values.numberAnswer
                : null,
            multipleChoiceAnswer: values.selectedAnswerType.value === SelectedAnswerType.MULTIPLE_CHOICE
                ? (
                    values.multipleChoiceAnswer === null
                        ? null
                        : ({
                            ...values.multipleChoiceAnswer,
                            answers: values.multipleChoiceAnswer.answers.map((a: ExamMultipleChoiceAnswerOption) => ({
                                ...a,
                                answerNl: a.answerNl === "" ? null : a.answerNl,
                                answerAr: a.answerAr === "" ? null : a.answerAr
                            }))
                        })
                )
                : null,
            dragDropAnswer: values.selectedAnswerType.value === SelectedAnswerType.DRAG_DROP
                ? values.dragDropAnswer
                : null,
            questionAudio: existingQuestion?.questionAudio || null
        };

        upsertExamQuestion(toSubmit).then(data => {
            if (watch("id") === undefined || watch("id") === null) {
                navigate(router.editQuestion.absolutePath(data.id));
            } else {
                setLoading(false);
                NotificationService.notify({type: "success", text: intl.formatMessage({id: "questionSaved"})});
            }
        }).catch(_ => {
            NotificationService.notify({type: "danger", text: intl.formatMessage({id: "operationError"})});
            setLoading(false);
        });
    }, [loading]);

    return (
        <>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={currentBreadcrumb.name}
                actions={{
                    disabled: loading || loadingData,
                    moreButtonText: intl.formatMessage({id: "more"}),
                    actions: [
                        {
                            actionType: "button",
                            primary: false,
                            text: intl.formatMessage({id: "new"}),
                            icon: PlusIcon,
                            onClick: () => navigate(router.newQuestion.absolutePath())
                        },
                        {
                            actionType: "button",
                            primary: true,
                            text: intl.formatMessage({id: "save"}),
                            icon: CheckIcon,
                            onClick: handleSubmit(() => setLoading(true))
                        }
                    ]
                }}
            />

            <EditQuestionForm
                setLoading={setLoading}
                loading={loading || loadingData}
                control={control}
                register={register}
                errors={errors}
                watch={watch}
                setValue={setValue}
                handleSubmit={handleSubmit}
                formError={formError}
                categoryOptions={categoryOptions}
                examQuestion={examQuestion}
            />
        </>
    );
};

export default EditQuestionContainer;