import React, {useEffect, useState} from "react";
import {AlertModal, AlertText, NotificationService} from "peggirkit";
import {reindexSearchData} from "../../../data/ApiEndpoints";
import {FormattedMessage, useIntl} from "react-intl";

type Props = {
    show: boolean,
    onClose: () => void
};

const IndexDatabaseModal = ({show, onClose}: Props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        reindexSearchData()
            .then(() => {
                onClose();
                setLoading(false);
                NotificationService.notify({
                    type: "success",
                    text: intl.formatMessage({id: "searchDataReIndexed.success"})
                });
            })
            .catch(_ => {
                setLoading(false);
                NotificationService.notify({
                    type: "danger",
                    text: intl.formatMessage({id: "searchDataReIndexed.error"})
                });
            });
    }, [loading]);

    return (
        <AlertModal
            type="info"
            show={show}
            onClose={onClose}
            title={intl.formatMessage({id: "indexSearchData"})}
            cancelText={intl.formatMessage({id: "cancel"})}
            continueText={intl.formatMessage({id: "continue"})}
            onContinue={() => setLoading(true)}
            loading={loading}
        >
            <div className={"mb-6 space-y-6"}>
                <AlertText>
                    <FormattedMessage id={"confirmStartIndexingDatabase"}/>
                </AlertText>
            </div>
        </AlertModal>
    );
};

export default IndexDatabaseModal;