import React from "react";
import {DateRange} from "react-day-picker";
import {useIntl} from "react-intl";
import {
    addDays,
    endOfDay,
    endOfMonth,
    endOfQuarter,
    endOfYear,
    startOfDay,
    startOfMonth,
    startOfQuarter,
    startOfYear
} from "date-fns";
import {RangePicker} from "peggirkit";
import {StatisticsSettings} from "./StatisticsOverview";

type Props = {
    statisticsSettings: StatisticsSettings,
    setStatisticsSettings: (newRange: StatisticsSettings) => void,
};

const DayRangeSelector = ({statisticsSettings, setStatisticsSettings}: Props) => {
    const intl = useIntl();

    return (
        <RangePicker
            title={intl.formatMessage({id: "pickARange"})}
            cancelText={intl.formatMessage({id: "cancel"})}
            continueText={intl.formatMessage({id: "apply"})}
            show={statisticsSettings.showPicker}
            setShow={(show: boolean) => setStatisticsSettings({...statisticsSettings, showPicker: show})}
            defaultSelected={statisticsSettings.dateRange}
            onSelect={(range?: DateRange) => {
                if (!range || !range.from || !range.to) {
                    return;
                }

                const newRange = statisticsSettings;
                newRange.dateRange.from = startOfDay(range.from);
                newRange.dateRange.to = endOfDay(range.to);
                newRange.loadingSaleStats = true;
                newRange.loadingStudentStats = true;
                setStatisticsSettings(newRange);
            }}
            rangePresets={[
                {
                    name: intl.formatMessage({id: "lastNDays"}, {n: 7}),
                    range: {
                        from: startOfDay(addDays(new Date(), -7)),
                        to: endOfDay(new Date())
                    },
                },
                {
                    name: intl.formatMessage({id: "lastNDays"}, {n: 31}),
                    range: {
                        from: startOfDay(addDays(new Date(), -31)),
                        to: endOfDay(new Date())
                    },
                },
                {
                    name: intl.formatMessage({id: "month"}),
                    range: {
                        from: startOfMonth(new Date()),
                        to: endOfMonth(new Date()),
                    },
                },
                {
                    name: intl.formatMessage({id: "quarter"}),
                    range: {
                        from: startOfQuarter(new Date()),
                        to: endOfQuarter(new Date())
                    },
                },
                {
                    name: intl.formatMessage({id: "year"}),
                    range: {
                        from: startOfYear(new Date()),
                        to: endOfYear(new Date())
                    },
                },
            ]}
        />
    );
};

export default DayRangeSelector;