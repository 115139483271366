import React from "react";
import {Alert} from "peggirkit";
import {useIntl} from "react-intl";

const NoPaymentDetailsAlert = () => {
    const intl = useIntl();
    return (
        <Alert
            type={"info"}
            title={intl.formatMessage({id: "paymentDetails"})}
            description={intl.formatMessage({id: "noStudentPaymentDetailsDescription"})}
        />
    );
};

export default NoPaymentDetailsAlert;