import React, {useEffect, useMemo, useState} from "react";
import router, {breadcrumbItemContactForm} from "../../../routing/Router";
import TheorieGoFrame from "./layout/TheorieGoFrame";
import {useNavigate, useParams} from "react-router-dom";
import {ContactForm as ContactFormEntity} from "../../../data/Entities";
import ContactFormDetails from "../../widgets/contactForms/contactForm/ContactFormDetails";
import {getContactForm} from "../../../data/ApiEndpoints";
import ContactFormHeading from "../../widgets/contactForms/contactForm/ContactFormHeading";

const ContactForm = () => {
    const {contactFormId} = useParams();
    const navigate = useNavigate();
    const [contactForm, setContactForm] = useState<ContactFormEntity | undefined>();
    const currentBreadcrumbItem = useMemo(() => {
        return breadcrumbItemContactForm(contactForm?.id || -1);
    }, [contactForm]);

    // Load contact form
    useEffect(() => {
        if (contactFormId) {
            getContactForm(parseInt(contactFormId))
                .then(form => setContactForm(form))
                .catch(_ => navigate(router.contactForms.absolutePath()));
        }
    }, []);

    return (
        <TheorieGoFrame title={currentBreadcrumbItem.name}>
            <ContactFormHeading
                currentBreadcrumbItem={currentBreadcrumbItem}
                contactForm={contactForm}
            />

            <ContactFormDetails
                contactForm={contactForm}
                setContactForm={setContactForm}
            />
        </TheorieGoFrame>
    );
};

export default ContactForm;