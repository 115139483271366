import React from "react";
import {CheckIcon, PlusIcon} from "@heroicons/react/24/outline";
import {BreadcrumbItem, Icon, PageHeading as PeggirKitPageHeading} from "peggirkit";
import {BreadcrumbsContent} from "peggirkit/dist/components/Heading/Page/Breadcrumbs";
import {UseFormHandleSubmit} from "react-hook-form";
import {ExamDetailsForm} from "./EditExamContainer";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import router from "../../../../routing/Router";

export type Props = {
    breadcrumbs: BreadcrumbsContent,
    currentBreadcrumbItem: BreadcrumbItem,
    handleSubmit: UseFormHandleSubmit<ExamDetailsForm>,
    loading: boolean,
    setLoading: (loading: boolean) => void
};

const PageHeading = ({breadcrumbs, currentBreadcrumbItem, handleSubmit, loading, setLoading}: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();

    return (
        <PeggirKitPageHeading
            breadcrumbsContent={breadcrumbs}
            title={currentBreadcrumbItem.name}
            actions={{
                disabled: loading,
                moreButtonText: intl.formatMessage({id: "more"}),
                actions: [
                    {
                        primary: false,
                        onClick: () => {
                            navigate(router.newExam.absolutePath())
                        },
                        text: intl.formatMessage({id: "new"}),
                        icon: PlusIcon as Icon,
                        actionType: "button",
                    },
                    {
                        primary: true,
                        onClick: handleSubmit(() => setLoading(true)),
                        text: intl.formatMessage({id: "save"}),
                        icon: CheckIcon as Icon,
                        actionType: "button"
                    }
                ]
            }}
        />
    );
};

export default PageHeading;