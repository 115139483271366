import React from "react";
import {InputField} from "peggirkit";
import EditMetadataCard from "./EditMetadataCard";
import {useIntl} from "react-intl";
import {EditFaqQuestionProps} from "../EditFaqQuestionContainer";

const EditOrderCard = ({
                           loading,
                           register,
                           errors
                       }: EditFaqQuestionProps) => {
    const intl = useIntl();
    return (
        <EditMetadataCard name={intl.formatMessage({id: "order"})}>
            <InputField
                type={"number"}
                id={"orderNr"}
                reactHookForm={register("orderNr", {
                    required: intl.formatMessage({id: "mustEnterField"}),
                    valueAsNumber: true,
                })}
                displayName={intl.formatMessage({id: "orderInSection"})}
                tip={intl.formatMessage({id: "orderInSection.description"})}
                error={errors.orderNr && errors.orderNr.message}
                disabled={loading}
            />

            <InputField
                type={"number"}
                id={"featuredOrderNr"}
                reactHookForm={register("featuredOrderNr", {
                    valueAsNumber: true,
                })}
                displayName={intl.formatMessage({id: "orderInFeaturedSection"})}
                tip={intl.formatMessage({id: "orderInFeaturedSection.description"})}
                error={errors.featuredOrderNr && errors.featuredOrderNr.message}
                disabled={loading}
            />
        </EditMetadataCard>
    );
};

export default EditOrderCard;