import React from "react";
import {breadcrumbItemStudents} from "../../../routing/Router";
import TheorieGoFrame from "./layout/TheorieGoFrame";
import StudentsOverview from "../../widgets/students/StudentsOverview";

const Students = () => {
    return (
        <TheorieGoFrame title={breadcrumbItemStudents.name}>
            <StudentsOverview/>
        </TheorieGoFrame>
    );
};

export default Students;