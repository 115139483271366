import React from "react";
import {QuestionInExam} from "../../../../../../../data/Entities";
import {FormattedMessage, useIntl} from "react-intl";
import InExamBadge from "./InExamBadge";
import {Badge} from "peggirkit";

type Props = {
    questionInExams?: QuestionInExam[]
};

const InExams = ({questionInExams}: Props) => {
    const intl = useIntl();
    return (
        <div className="col-span-6 lg:col-span-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
                <FormattedMessage id={"inExams"}/>
            </label>
            <div className={"flex flex-row flex-wrap gap-2 items-start justify-start"}>
                {
                    questionInExams && questionInExams.length > 0
                        ? questionInExams?.map((q, index) => <InExamBadge key={index} exam={q.exam}/>)
                        : <Badge type={"gray"} content={intl.formatMessage({id: "none"})}/>
                }
            </div>
        </div>
    );
};

export default InExams;