import React from "react";
import {InputField, Link} from "peggirkit";
import {FormattedMessage, useIntl} from "react-intl";
import {EditBlogPostProps} from "../BlogPostEditor";
import EditMetadataCard from "../../../faq/questions/metadata/EditMetadataCard";

const BlogPostSeoCard = ({
                              loading,
                              register,
                              errors,
                              watch
                          }: EditBlogPostProps) => {
    const intl = useIntl();
    return (
        <EditMetadataCard name={intl.formatMessage({id: "seo"})}>
            <InputField
                type={"text"}
                id={"slug"}
                reactHookForm={register("slug", {
                    required: intl.formatMessage({id: "mustEnterField"}),
                    maxLength: {
                        value: 128,
                        message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 128}),
                    },
                })}
                displayName={intl.formatMessage({id: "slug"})}
                tip={<>
                    <FormattedMessage
                        id={"nOutOfTCharacters"}
                        values={{
                            n: watch("slug")?.length || 0,
                            t: 128
                        }}
                    />
                    <br/>
                    <Link
                        type={"default"}
                        target={"_blank"}
                        text={`https://theoriego.nl/blog/${watch("slug") || "..."}`}
                        href={`https://theoriego.nl/blog/${watch("slug") || "..."}`}
                    />
                </>}
                error={errors.slug && errors.slug.message}
                disabled={loading}
            />

            <InputField
                type={"textarea"}
                textareaHeight={"h-24"}
                id={"description"}
                reactHookForm={register("description", {
                    required: intl.formatMessage({id: "mustEnterField"}),
                    maxLength: {
                        value: 180,
                        message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 180}),
                    },
                })}
                displayName={intl.formatMessage({id: "description"})}
                tip={intl.formatMessage({id: "nOutOfTCharacters"}, {
                    n: watch("description")?.length || 0,
                    t: 180
                })}
                error={errors.description && errors.description.message}
                disabled={loading}
            />
        </EditMetadataCard>
    );
};

export default BlogPostSeoCard;