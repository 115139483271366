import React, {useState} from "react";
import {DescriptionList, SectionHeading} from "peggirkit";
import IndexDatabaseModal from "./IndexDatabaseModal";
import {useIntl} from "react-intl";

const DatabaseIndexSection = () => {
    const intl = useIntl();
    const [showIndexDatabaseModal, setShowIndexDatabaseModal] = useState(false);
    return (
        <div className={"mt-8"}>
            <SectionHeading
                title={intl.formatMessage({id: "database"})}
                description={intl.formatMessage({id: "adminDatabaseOperation"})}
            />
            <DescriptionList
                items={[
                    {
                        key: intl.formatMessage({id: "indexing"}),
                        value: intl.formatMessage({id: "indexDbDescription"}),
                        options: [{
                            text: intl.formatMessage({id: "startIndexing"}),
                            onClick: _ => setShowIndexDatabaseModal(true)
                        }]
                    },
                ]}
            />

            <IndexDatabaseModal
                show={showIndexDatabaseModal}
                onClose={() => setShowIndexDatabaseModal(false)}
            />
        </div>
    );
};

export default DatabaseIndexSection;