import React from "react";
import {EditQuestionSectionProps} from "../../EditQuestionForm";
import {FormLayoutCard, InputField} from "peggirkit";
import {useIntl} from "react-intl";
import Presets from "../answer/Presets";


const QuestionSection = ({loading, register, errors, setValue, watch}: EditQuestionSectionProps) => {
    const intl = useIntl();
    return (
        <FormLayoutCard
            title={intl.formatMessage({id: "questionOrStatement"})}
            description={intl.formatMessage({id: "editExamQuestion.section.questionOrStatement.description"})}
        >
            <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 lg:col-span-4">
                    <Presets
                        loading={loading}
                        setValue={setValue}
                    />
                </div>
                <div className="col-span-6 lg:col-span-4">
                    <InputField
                        type={"textarea"}
                        id={"question-question-nl"}
                        textareaHeight={"h-16"}
                        displayName={intl.formatMessage({id: "dutch"})}
                        tip={`${watch("questionNl")?.length || 0}/562`}
                        reactHookForm={{
                            ...register("questionNl", {
                                required: intl.formatMessage({id: "mustEnterField"}),
                                maxLength: {
                                    value: 562,
                                    message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 562})
                                }
                            })
                        }}
                        error={errors.questionNl && errors.questionNl.message}
                        disabled={loading}
                    />
                </div>
                <div className="col-span-6 lg:col-span-4">
                    <InputField
                        type={"textarea"}
                        id={"question-question-ar"}
                        textareaHeight={"h-16"}
                        displayName={intl.formatMessage({id: "arabic"})}
                        alignRight={true}
                        rtl={true}
                        tip={`${watch("questionAr")?.length || 0}/562`}
                        reactHookForm={{
                            ...register("questionAr", {
                                required: intl.formatMessage({id: "mustEnterField"}),
                                maxLength: {
                                    value: 562,
                                    message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 562})
                                }
                            })
                        }}
                        error={errors.questionAr && errors.questionAr.message}
                        disabled={loading}
                    />
                </div>
            </div>
        </FormLayoutCard>
    );
};

export default QuestionSection;