import React, {useMemo} from "react";
import {theorieGoAxios} from "../../../../../../data/ApiEndpoints";
import {questionsDataTableBaseUrl} from "../../../../questions/overview/dataTable/QuestionsDataTable";
import {QuestionFilterSection} from "../../../../questions/overview/QuestionsOverview";
import {NoSymbolIcon} from "@heroicons/react/24/outline";
import {DataTable, DataTableColumn, TableColumn} from "peggirkit";
import {useIntl} from "react-intl";
import {
    columnCategory,
    columnId,
    columnQuestionInExams,
    columnQuestionNl
} from "../../../../questions/overview/dataTable/Columns";
import {ExamCategory, OverviewExamQuestion} from "../../../../../../data/Entities";
import {UseFieldArrayAppend, UseFieldArrayRemove, UseFormWatch} from "react-hook-form";
import Toolbar from "./Toolbar";
import {QuestionFilterCategory} from "../../../../categories/CategoryFilterOptions";
import AddToExamFormatter from "./AddToExamFormatter";
import {ExamDetailsForm} from "../../EditExamContainer";

export type ExamQuestionOperations = {
    hrRemove: UseFieldArrayRemove,
    hrAppend: UseFieldArrayAppend<ExamDetailsForm, "hazardRecognitionQuestions">,
    kRemove: UseFieldArrayRemove,
    kAppend: UseFieldArrayAppend<ExamDetailsForm, "knowledgeQuestions">,
    iRemove: UseFieldArrayRemove,
    iAppend: UseFieldArrayAppend<ExamDetailsForm, "insightQuestions">,
};

type Props = {
    watch: UseFormWatch<ExamDetailsForm>,
    sectionFilter: QuestionFilterSection,
    categories: ExamCategory[]
    categoryFilter: QuestionFilterCategory
    setCategoryFilter: (filter: QuestionFilterCategory) => void
};

const QuestionsDataTable = ({
                                watch,
                                hrRemove,
                                hrAppend,
                                kRemove,
                                kAppend,
                                iRemove,
                                iAppend,
                                sectionFilter,
                                categoryFilter,
                                setCategoryFilter,
                                categories
                            }: Props & ExamQuestionOperations) => {
    const intl = useIntl();
    const columns: (DataTableColumn & TableColumn)[] = useMemo(() => ([
        columnId,
        columnQuestionNl,
        columnCategory,
        columnQuestionInExams,
        {
            hideLabel: true,
            name: intl.formatMessage({id: "options"}),
            sortable: false,
            alignRight: true,
            secondary: true,
            formatter: (_: any, question: unknown) => <AddToExamFormatter
                watch={watch}
                hrRemove={hrRemove}
                hrAppend={hrAppend}
                kRemove={kRemove}
                kAppend={kAppend}
                iRemove={iRemove}
                iAppend={iAppend}
                question={question as OverviewExamQuestion}
                sectionFilter={sectionFilter}
            />,
        }
    ]), [sectionFilter, watch("hazardRecognitionQuestions"), watch("knowledgeQuestions"), watch("insightQuestions")]);

    return (
        <div
            data-title={"questions-overview-container"}
            className={"flex flex-col"}
        >
            <Toolbar
                categories={categories}
                categoryFilter={categoryFilter}
                setCategoryFilter={setCategoryFilter}
            />

            <DataTable
                key={sectionFilter}
                alwaysShowToolbar={true}
                withUrlPageParameter={false}
                scrollToTopOnPageChange={true}
                initialPageRequest={{
                    axios: theorieGoAxios,
                    baseUrl: questionsDataTableBaseUrl(false, sectionFilter, categoryFilter, false),
                    pageSize: 15,
                    pageNumber: 0,
                    search: {
                        baseUrl: questionsDataTableBaseUrl(false, sectionFilter, categoryFilter, true),
                        urlParameter: "query",
                        messages: {
                            search: intl.formatMessage({id: "search"}),
                            placeholder: intl.formatMessage({id: "question"}),
                            clear: intl.formatMessage({id: "clear"})
                        }
                    }
                }}
                columns={columns}
                summaryFormatter={(from: number, to: number, total: number): JSX.Element | string => (
                    intl.formatMessage({id: "paginationSummary"}, {from: from, to: to, total: total})
                )}
                messages={{
                    next: intl.formatMessage({id: "next"}),
                    previous: intl.formatMessage({id: "previous"})
                }}
                batchActions={{
                    dropdownButtonText: (itemsSelected: number) =>
                        intl.formatMessage({id: "nItemsSelected"}, {n: itemsSelected}),
                    toggleSelectAllText: intl.formatMessage({id: "selectAll"}),
                    selectRowText: intl.formatMessage({id: "selectRow"}),
                    actions: [
                        [
                            {
                                icon: NoSymbolIcon,
                                text: intl.formatMessage({id: "noActions"}),
                                disabled: true,
                                onClick: _ => {
                                }
                            }
                        ]
                    ]
                }}
            />
        </div>
    );
};

export default QuestionsDataTable;