import React, {useState} from "react";
import {Button, FormLayoutCard, InputFeedback} from "peggirkit";
import {TrashIcon} from "@heroicons/react/24/outline";
import router from "../../../../../routing/Router";
import {useIntl} from "react-intl";
import DeleteExamsModal from "../../delete/DeleteExamsModal";
import {useNavigate} from "react-router-dom";

type Props = {
    examId?: number | null,
    isDemo: boolean
};

const DeleteSection = ({examId, isDemo}: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    if (examId === undefined || examId === null) {
        return <></>;
    }

    return (
        <FormLayoutCard
            title={intl.formatMessage({id: "delete"})}
            description={intl.formatMessage({id: "deleteExamExplanation"})}
        >
            <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 lg:col-span-4">
                    <Button
                        type={"danger"}
                        disabled={isDemo}
                        icon={TrashIcon}
                        text={intl.formatMessage({id: "deleteExam"})}
                        onClick={e => {
                            e.preventDefault();
                            setShowModal(true);
                        }}
                    />
                    {
                        isDemo
                            ? <InputFeedback
                                message={intl.formatMessage({id: "cannotDeleteDemoExam"})}
                                type="error"
                            />
                            : <></>
                    }
                </div>
            </div>

            <DeleteExamsModal
                show={showModal}
                setShow={setShowModal}
                examIds={[examId]}
                cb={() => navigate(router.exams.absolutePath())}
            />
        </FormLayoutCard>
    );
};

export default DeleteSection;