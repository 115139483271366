import React from "react";

type Props = {
    title: string,
    values: string[],
};

const ChartTooltip = ({title, values}: Props) => {
    return (
        <div className="flex flex-col gap-2 p-3 bg-white shadow ring-1 ring-black ring-opacity-5 rounded-sm outline-0">
            <span className={"text-gray-600 text-sm"}>
                {title}
            </span>
            {
                values.map((val, i) => (
                    <span key={i} className={"font-medium text-sm"}>
                        {val}
                    </span>
                ))
            }
        </div>
    );
};

export default ChartTooltip;