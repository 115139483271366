import React from "react";
import {Badge} from "peggirkit";
import {ExamCategory} from "../../../../data/Entities";
import {useIntl} from "react-intl";

type Props = {
    category: ExamCategory
};

const PublicQuestionsPerSectionFormatter = ({category}: Props) => {
    const intl = useIntl();
    return (
        <div className={"flex flex-col gap-2 items-start"}>
            <Badge
                type={"gray"}
                content={intl.formatMessage(
                    {id: "hazardRecognitionCount"},
                    {count: category.hazardRecognitionQuestionsCount}
                )}
            />
            <Badge
                type={"gray"}
                content={intl.formatMessage(
                    {id: "knowledgeCount"},
                    {count: category.knowledgeQuestionsCount}
                )}
            />
            <Badge
                type={"gray"}
                content={intl.formatMessage(
                    {id: "insightCount"},
                    {count: category.insightQuestionsCount}
                )}
            />
        </div>
    );
};

export default PublicQuestionsPerSectionFormatter;