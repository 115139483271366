import React from "react";
import {breadcrumbItemCategories} from "../../../routing/Router";
import TheorieGoFrame from "./layout/TheorieGoFrame";
import CategoriesOverview from "../../widgets/categories/CategoriesOverview";

const Categories = () => {
    return (
        <TheorieGoFrame title={breadcrumbItemCategories.name}>
            <CategoriesOverview/>
        </TheorieGoFrame>
    );
};

export default Categories;