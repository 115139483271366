import React, {useId, useRef} from 'react';
import {Button, Checkbox, Icon, InputField, isEmpty, Radio} from "peggirkit";
import {ArrowUpTrayIcon, TrashIcon} from "@heroicons/react/24/outline";
import {UseFieldArrayRemove, UseFormRegister, UseFormSetValue, UseFormWatch} from "react-hook-form";
import ImageUploader from "../../../../../assets/ImageUploader";
import {getAbsoluteImageUrl} from "../../../../../assets/AssetsUtil";
import {useIntl} from "react-intl";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {ExamQuestionForm} from "../../../EditQuestionForm";

type Props = {
    loading: boolean,
    index: number,
    remove: UseFieldArrayRemove,
    register: UseFormRegister<ExamQuestionForm>,
    setValue: UseFormSetValue<ExamQuestionForm>,
    watch: UseFormWatch<ExamQuestionForm>,
    errors: FieldErrors<ExamQuestionForm>,
    totalOptions: number,
    withImages: boolean,
    multipleResponse: boolean,
    answerRequired: boolean
};

const Option = ({
                    loading,
                    index,
                    remove,
                    register,
                    setValue,
                    totalOptions,
                    withImages,
                    multipleResponse,
                    watch,
                    answerRequired,
                    errors
                }: Props) => {
    const id = useId();
    const intl = useIntl();
    const imageUploaderRef = useRef<HTMLInputElement | null>(null);

    return (
        <div
            data-title={"multiple-choice-answer-option"}
            className={"flex flex-row w-full gap-6 mb-6"}
        >
            <div>
                {
                    multipleResponse
                        ? <Checkbox
                            id={`${id}-answer-correct`}
                            displayName={intl.formatMessage({id: "correct"})}
                            disabled={loading}
                            onChange={e => setValue(`multipleChoiceAnswer.answers.${index}.correct`, e.target.checked)}
                            checked={watch(`multipleChoiceAnswer.answers.${index}.correct`)}
                        />
                        : <Radio
                            name={"question-multiple-choice-correct"}
                            value={index}
                            disabled={loading}
                            label={intl.formatMessage({id: "correct"})}
                            hideLabel={true}
                            onChange={e => {
                                for (let i = 0; i < totalOptions; i++) {
                                    setValue(`multipleChoiceAnswer.answers.${i}.correct`, false);
                                }
                                setValue(`multipleChoiceAnswer.answers.${index}.correct`, e.target.checked);
                            }}
                            checked={watch(`multipleChoiceAnswer.answers.${index}.correct`)}
                        />
                }
            </div>
            {
                withImages
                    ? <div className={"flex-shrink-0"}>
                        <img
                            className={"border rounded-md border-gray-300 h-32 w-32 object-contain"}
                            src={
                                !isEmpty(watch(`multipleChoiceAnswer.answers.${index}.image`))
                                    ? getAbsoluteImageUrl(watch(`multipleChoiceAnswer.answers.${index}.image`))
                                    : undefined
                            }
                            alt={intl.formatMessage({id: "image"})}
                        />
                    </div>
                    : <></>
            }
            <div className={"flex flex-col w-full gap-2"}>
                <input
                    type={"hidden"}
                    {...register(`multipleChoiceAnswer.answers.${index}.id`, {
                        valueAsNumber: true,
                        required: false
                    })}
                />
                <InputField
                    id={`${id}-answer-nl`}
                    type={"text"}
                    reactHookForm={{
                        ...register(`multipleChoiceAnswer.answers.${index}.answerNl`, {
                            required: answerRequired && !withImages ? intl.formatMessage({id: "mustEnterField"}) : false,
                            minLength: {
                                value: 1,
                                message: intl.formatMessage({id: "mustBeMinCharacters"}, {min: 1})
                            },
                            maxLength: {
                                value: 256,
                                message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 256})
                            }
                        })
                    }}
                    error={
                        errors.multipleChoiceAnswer?.answers?.[index]?.answerNl &&
                        errors.multipleChoiceAnswer?.answers?.[index]?.answerNl?.message
                    }
                    displayName={intl.formatMessage({id: "dutchAnswer"})}
                    hideLabel={true}
                    placeholder={intl.formatMessage({id: "dutchAnswer"})}
                    disabled={loading}
                />
                <InputField
                    id={`${id}-answer-ar`}
                    type={"text"}
                    reactHookForm={{
                        ...register(`multipleChoiceAnswer.answers.${index}.answerAr`, {
                            required: answerRequired && !withImages ? intl.formatMessage({id: "mustEnterField"}) : false,
                            minLength: {
                                value: 1,
                                message: intl.formatMessage({id: "mustBeMinCharacters"}, {min: 1})
                            },
                            maxLength: {
                                value: 256,
                                message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 256})
                            }
                        })
                    }}
                    error={
                        errors.multipleChoiceAnswer?.answers?.[index]?.answerAr &&
                        errors.multipleChoiceAnswer?.answers?.[index]?.answerAr?.message
                    }
                    displayName={intl.formatMessage({id: "arabicAnswer"})}
                    alignRight={true}
                    rtl={true}
                    hideLabel={true}
                    placeholder={intl.formatMessage({id: "arabicAnswer"})}
                    disabled={loading}
                />
                {
                    withImages
                        ? <>
                            <InputField
                                id={`${id}-answer-image`}
                                type={"text"}
                                placeholder={intl.formatMessage({id: "image"})}
                                reactHookForm={{
                                    ...register(`multipleChoiceAnswer.answers.${index}.image`, {
                                        required: answerRequired && withImages
                                            ? intl.formatMessage({id: "mustSelectImage"})
                                            : false,
                                        minLength: {
                                            value: 1,
                                            message: intl.formatMessage({id: "mustBeMinCharacters"}, {min: 1})
                                        },
                                        maxLength: {
                                            value: 256,
                                            message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 256})
                                        }
                                    })
                                }}
                                error={
                                    errors.multipleChoiceAnswer?.answers?.[index]?.image &&
                                    errors.multipleChoiceAnswer?.answers?.[index]?.image?.message
                                }
                                displayName={intl.formatMessage({id: "image"})}
                                hideLabel={true}
                                addOn={intl.formatMessage({id: "image"})}
                                disabled={loading}
                                trailingButton={{
                                    type: "gray",
                                    iconOnly: true,
                                    icon: ArrowUpTrayIcon as Icon,
                                    text: intl.formatMessage({id: "upload"}),
                                    disabled: loading,
                                    onClick: e => {
                                        e.preventDefault();
                                        imageUploaderRef.current && imageUploaderRef.current.click();
                                    }
                                }}
                            />
                            <ImageUploader
                                afterUpload={(img) => setValue(`multipleChoiceAnswer.answers.${index}.image`, img)}
                                uploaderRef={imageUploaderRef}
                            />
                        </>
                        : <></>
                }
            </div>
            <div>
                <Button
                    type={"danger"}
                    iconOnly={true}
                    icon={TrashIcon as Icon}
                    disabled={loading}
                    text={intl.formatMessage({id: "delete"})}
                    onClick={e => {
                        e.preventDefault();
                        remove(index);
                    }}
                />
            </div>
        </div>
    );
};

export default Option;