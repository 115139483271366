import React from "react";
import {breadcrumbItemFaq} from "../../../routing/Router";
import TheorieGoFrame from "./layout/TheorieGoFrame";
import FaqSectionsOverview from "../../widgets/faq/sections/FaqSectionsOverview";

const FaqSections = () => {
    return (
        <TheorieGoFrame title={breadcrumbItemFaq.name}>
            <FaqSectionsOverview/>
        </TheorieGoFrame>
    );
};

export default FaqSections;