import React, {useState} from "react";
import UpdatePasswordModal from "./UpdatePasswordModal";
import {DescriptionList, SectionHeading} from "peggirkit";
import {useIntl} from "react-intl";


const AccountTabSecurity = () => {
    const intl = useIntl();
    const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);

    return (
        <>
            <SectionHeading
                title={intl.formatMessage({id: "security"})}
                description={intl.formatMessage({id: "securitySettingsAccount"})}
            />
            <DescriptionList
                items={[
                    {
                        key: intl.formatMessage({id: "password"}),
                        value: "•••••••••••••••",
                        options: [
                            {
                                text: intl.formatMessage({id: "update"}),
                                onClick: () => setShowUpdatePasswordModal(true)
                            }
                        ]
                    }
                ]}
            />

            <UpdatePasswordModal
                show={showUpdatePasswordModal}
                onClose={() => setShowUpdatePasswordModal(false)}
            />
        </>
    );
};

export default AccountTabSecurity;