import React, {ReactNode} from "react";
import {Card} from "peggirkit";

type Props = {
    name: string,
    children: ReactNode,
};

const EditMetadataCard = ({name, children}: Props) => {
    return (
        <Card header={{title: name}}>
            <div className={"flex flex-col gap-6"}>
                {children}
            </div>
        </Card>
    );
};

export default EditMetadataCard;