import {ExamQuestionForm} from "../../../EditQuestionForm";
import {createIntl, createIntlCache} from "react-intl";
import DutchMessages from "../../../../../../../messages/nl-NL";
import {SelectedAnswerType} from "../AnswerType";

const cache = createIntlCache();
const intl = createIntl({locale: 'nl-NL', messages: DutchMessages}, cache);

export const transformAndValidateDragDropAnswer = (values: ExamQuestionForm): ExamQuestionForm => {
    if (values.selectedAnswerType.value !== SelectedAnswerType.DRAG_DROP) {
        return values;
    }

    if (values.dragDropAnswer === null) {
        throw new Error(intl.formatMessage({id: "noDragDropAnswerSpecified"}));
    }

    // At least two options
    if (!values.dragDropAnswer?.answers) {
        throw new Error(intl.formatMessage({id: "needsTwoDragDropOptions"}));
    }

    if (values.dragDropAnswer?.answers.length < 2) {
        throw new Error(intl.formatMessage({id: "needsTwoDragDropOptions"}));
    }

    // One option must be draggable (minimum)
    if (values.dragDropAnswer?.answers.filter(a => a.draggableOption).length === 0) {
        throw new Error(intl.formatMessage({id: "oneDragDropOptionNeedsToBeDraggable"}));
    }

    // All numbers must be unique
    const optionNumbers = new Set(values.dragDropAnswer?.answers.map(a => a.number));
    if (optionNumbers.size !== values.dragDropAnswer.answers.length) {
        throw new Error(intl.formatMessage({id: "dragDropOptionNumbersMustBeUnique"}));
    }

    // Round top- and left-offsets
    if (values.dragDropAnswer?.answers) {
        values = {
            ...values,
            dragDropAnswer: {
                ...values.dragDropAnswer,
                answers: values.dragDropAnswer.answers.map(a => ({
                    ...a,
                    topOffset: Math.round(a.topOffset),
                    leftOffset: Math.round(a.leftOffset)
                }))
            }
        };
    }

    return values;
};