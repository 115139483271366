import axios, {AxiosError, AxiosResponse} from "axios";
import {
    AssetDestination,
    BlogPost,
    ContactForm,
    ContactFormAdminFields,
    ExamCategory,
    ExamDetails,
    ExamDetailsForSubmission,
    ExamQuestion,
    ExamStats,
    FaqQuestionDetails,
    FaqSection,
    Message,
    RevenueStats,
    Student,
    User
} from "./Entities";
import {isSignedInLocalCookieName, Page} from "peggirkit";

export const theorieGoAxios = axios.create({
    baseURL: process.env.BACKEND_API_BASE_URL,
    withCredentials: true,
});

theorieGoAxios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        if (error.response?.status === 401) {
            localStorage.removeItem(isSignedInLocalCookieName);
            location.reload();
        }

        return Promise.reject(error);
    });

export const getCurrentUser = (): Promise<User> => (
    theorieGoAxios
        .get("/admin/user")
        .then(response => response.data)
);

export const signIn = (emailAddress: string, password: string): Promise<Message> => (
    theorieGoAxios
        .post("/admin/auth/sign-in", {
            emailAddress: emailAddress,
            password: password
        })
        .then(response => response.data)
);

export const signOut = (): Promise<Message> => (
    theorieGoAxios
        .post("/admin/auth/sign-out")
        .then(response => response.data)
);

export const requestPasswordReset = (emailAddress: string): Promise<Message> => (
    theorieGoAxios
        .post("/admin/auth/reset", {emailAddress: emailAddress})
        .then(response => response.data)
);

export const confirmPasswordReset = (emailAddress: string, token: string, password: string): Promise<Message> => (
    theorieGoAxios
        .post("/admin/auth/reset/confirm", {
            emailAddress: emailAddress,
            token: token,
            password: password
        })
        .then(response => response.data)
);

export const updatePassword = (currentPassword: string, newPassword: string): Promise<Message> => (
    theorieGoAxios
        .put("/admin/user/password", {
            currentPassword: currentPassword,
            newPassword: newPassword
        })
        .then(response => response.data)
);

export const reindexSearchData = (): Promise<Message> => (
    theorieGoAxios
        .put("/admin/database-search-index")
        .then(response => response.data)
);

export const getStudent = (id: number): Promise<Student> => (
    theorieGoAxios
        .get(`/admin/students/${id}`)
        .then(response => response.data)
);

export const upsertStudent = (student: Student): Promise<Student> => (
    theorieGoAxios
        .put("/admin/students", student)
        .then(response => response.data)
);

export const deleteStudents = (studentIds: number[]): Promise<Message> => (
    theorieGoAxios
        .delete("/admin/students", {
            data: studentIds
        })
        .then(response => response.data)
);

export const resendStudentCreatedMail = (studentId: number): Promise<Message> => (
    theorieGoAxios
        .put(`/admin/students/${studentId}/created-mail`)
        .then(response => response.data)
);

export const deleteCategories = (categoryIds: number[]): Promise<Message> => (
    theorieGoAxios
        .delete("/admin/categories", {
            data: categoryIds
        })
        .then(response => response.data)
);

export const upsertCategory = (category: ExamCategory): Promise<ExamCategory> => (
    theorieGoAxios
        .put("/admin/categories", category)
        .then(response => response.data)
);

export const listCategories = (): Promise<Page> => (
    theorieGoAxios
        .get("/admin/categories?page=0&size=10000&sort=code,asc")
        .then(response => response.data)
);

export const uploadImage = (data: FormData, assetDestination: AssetDestination): Promise<Message> => (
    theorieGoAxios
        .post(`/admin/assets/images/${assetDestination}`, data)
        .then(response => response.data)
);

export const upsertExamQuestion = (question: ExamQuestion): Promise<ExamQuestion> => (
    theorieGoAxios
        .put("/admin/questions", question)
        .then(response => response.data)
);

export const getExamQuestion = (id: number): Promise<ExamQuestion> => (
    theorieGoAxios
        .get(`/admin/questions/${id}`)
        .then(response => response.data)
);

export const deleteExamQuestions = (ids: number[]): Promise<Message> => (
    theorieGoAxios
        .delete("/admin/questions", {
            data: ids
        })
        .then(response => response.data)
);

export const deleteExams = (ids: number[]): Promise<Message> => (
    theorieGoAxios
        .delete("/admin/exams", {
            data: ids
        })
        .then(response => response.data)
);

export const upsertExam = (exam: ExamDetailsForSubmission): Promise<ExamDetails> => (
    theorieGoAxios
        .put("/admin/exams", exam)
        .then(response => response.data)
);

export const getExam = (id: number): Promise<ExamDetails> => (
    theorieGoAxios
        .get(`/admin/exams/${id}`)
        .then(response => response.data)
);

export const deleteContactForms = (formIds: number[]): Promise<Message> => (
    theorieGoAxios
        .delete("/admin/contact", {
            data: formIds
        })
        .then(response => response.data)
);

export const getContactFormsOpenCount = (): Promise<number> => (
    theorieGoAxios
        .get("/admin/contact/open")
        .then(response => response.data)
);

export const getContactForm = (contactFormId: number): Promise<ContactForm> => (
    theorieGoAxios
        .get(`/admin/contact/${contactFormId}`)
        .then(response => response.data)
);

export const updateContactForm = (contactForm: ContactFormAdminFields): Promise<ContactForm> => (
    theorieGoAxios
        .put("/admin/contact", contactForm)
        .then(response => response.data)
);

export const listFaqSections = (): Promise<FaqSection[]> => (
    theorieGoAxios
        .get("/admin/faq")
        .then(response => response.data)
);

export const getFaqQuestion = (questionId: number): Promise<FaqQuestionDetails> => (
    theorieGoAxios
        .get(`/admin/faq/questions/${questionId}`)
        .then(response => response.data)
);

export const upsertFaqQuestion = (question: FaqQuestionDetails): Promise<FaqQuestionDetails> => (
    theorieGoAxios
        .put("/admin/faq/questions", question)
        .then(response => response.data)
);

export const deleteFaqQuestions = (questionIds: number[]): Promise<Message> => (
    theorieGoAxios
        .delete("/admin/faq/questions", {
            data: questionIds
        })
        .then(response => response.data)
);

export const getRevenueStats = (from: Date, to: Date): Promise<RevenueStats> => (
    theorieGoAxios
        .get(`/admin/stats/sales?from=${from.toISOString()}&to=${to.toISOString()}`)
        .then(response => response.data)
);

export const getStudentExamsStats = (from: Date, to: Date): Promise<ExamStats> => (
    theorieGoAxios
        .get(`/admin/stats/students?from=${from.toISOString()}&to=${to.toISOString()}`)
        .then(response => response.data)
);

export const getBlogPost = (id: number): Promise<BlogPost> => (
    theorieGoAxios
        .get(`/admin/blog/${id}`)
        .then(response => response.data)
);

export const upsertBlogPost = (post: BlogPost): Promise<BlogPost> => (
    theorieGoAxios
        .put("/admin/blog", post)
        .then(response => response.data)
);

export const deleteBlogPosts = (blogPostIds: number[]): Promise<Message> => (
    theorieGoAxios
        .delete("/admin/blog", {
            data: blogPostIds
        })
        .then(response => response.data)
);
