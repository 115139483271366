import React, {useEffect, useMemo, useState} from "react";
import router, {
    breadcrumbItemBlog,
    breadcrumbItemBlogPost,
    breadcrumbItemDashboard,
    newBlogPostPart,
} from "../../../../routing/Router";
import TheorieGoFrame from "../layout/TheorieGoFrame";
import {BreadcrumbsContent, NotificationService} from "peggirkit";
import {BlogPost as BlogPostEntity} from "../../../../data/Entities";
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import {getBlogPost} from "../../../../data/ApiEndpoints";
import BlogPostEditor from "../../../widgets/blog/post/BlogPostEditor";

const BlogPost = () => {
    const intl = useIntl();
    const {blogPostId} = useParams();
    const navigate = useNavigate();
    const [blogPost, setBlogPost] = useState<BlogPostEntity | undefined>();
    const [loading, setLoading] = useState(true);
    const currentBreadcrumbItem = useMemo(() => {
        return breadcrumbItemBlogPost(blogPost && blogPost.id || -1);
    }, [blogPost]);
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemBlog, currentBreadcrumbItem]
    };

    // Load existing post blog
    useEffect(() => {
        if (blogPostId && blogPostId !== newBlogPostPart) {
            getBlogPost(parseInt(blogPostId)).then(b => {
                setBlogPost(b);
                setLoading(false);
            }).catch(e => {
                if (e.response.status === 404) {
                    navigate(router.blog.absolutePath());
                }

                NotificationService.notify({type: "danger", text: intl.formatMessage({id: "operationError"})});
            });
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <TheorieGoFrame title={currentBreadcrumbItem.name}>
            <BlogPostEditor
                key={blogPost?.lastEditedDate || ""}
                breadcrumbs={breadcrumbs}
                currentBreadcrumbItem={currentBreadcrumbItem}
                loadingData={loading}
                blogPost={blogPost}
                setBlogPost={setBlogPost}
            />
        </TheorieGoFrame>
    );
};

export default BlogPost;