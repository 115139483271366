import React from "react";
// @ts-ignore
import logo from "../../../../assets/img/theoriego-small-dark.svg";
import router from "../../../../routing/Router";
import {AuthFrame} from "peggirkit";
import {useIntl} from "react-intl";

type Props = {
    title: string,
    children: JSX.Element
};

const TheorieGoAuthFrame = ({title, children}: Props) => {
    const intl = useIntl();

    return (
        <AuthFrame
            icon={logo}
            title={title}
            appName={intl.formatMessage({id: "appName"})}
            onSignedInRedirectTo={router.dashboard.absolutePath()}
        >
            {children}
        </AuthFrame>
    );
};

export default TheorieGoAuthFrame;