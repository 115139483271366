import React from "react";
import {Badge, Button, Icon, SectionHeading, Table, TableButton} from "peggirkit";
import {CheckIcon, PlusIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";
import {FaqSection} from "../../../../data/Entities";
import {useIntl} from "react-intl";
import router from "../../../../routing/Router";


const FaqSectionDetails = ({id, nameNl, nameAr, questions}: FaqSection) => {
    const intl = useIntl();
    const navigate = useNavigate();
    return (
        <div>
            <div className={"w-full flex flex-row justify-between items-start"}>
                <SectionHeading
                    title={nameNl}
                    description={nameAr}
                />
                <Button
                    text={intl.formatMessage({id: "new"})}
                    icon={PlusIcon as Icon}
                    onClick={() => navigate(router.newFaqQuestion.absolutePath(id))}
                />
            </div>

            <Table
                columns={[
                    {
                        name: intl.formatMessage({id: "id"}),
                        sortable: false,
                        secondary: true,
                    },
                    {
                        name: intl.formatMessage({id: "question"}),
                        sortable: false,
                    },
                    {
                        name: intl.formatMessage({id: "isFeatured"}),
                        sortable: false,
                        alignRight: true,
                        secondary: true,
                    },
                    {
                        name: intl.formatMessage({id: "updatedOn"}),
                        sortable: false,
                        alignRight: true,
                        secondary: true,
                    },
                    {
                        name: intl.formatMessage({id: "creationDate"}),
                        sortable: false,
                        alignRight: true,
                        secondary: true,
                    },
                    {
                        name: intl.formatMessage({id: "options"}),
                        sortable: false,
                        hideLabel: true,
                        alignRight: true,
                        secondary: true,
                    },
                ]}
                data={questions.map(q => ({
                    [intl.formatMessage({id: "id"})]: q.id,
                    [intl.formatMessage({id: "question"})]: q.questionNl,
                    [intl.formatMessage({id: "isFeatured"})]: q.featuredOrderNr === null
                        ? ""
                        : <Badge type={"green"} content={"✓"} tooltip={intl.formatMessage({id: "isFeatured"})}/>,
                    [intl.formatMessage({id: "updatedOn"})]: new Date(q.updatedOn || "").toLocaleDateString(),
                    [intl.formatMessage({id: "creationDate"})]: new Date(q.creationDate || "").toLocaleDateString(),
                    [intl.formatMessage({id: "options"})]: <TableButton
                        text={intl.formatMessage({id: "view"})}
                        href={router.editFaqQuestion.absolutePath(q.id)}
                    />
                }))}
            />
        </div>
    );
};

export default FaqSectionDetails;