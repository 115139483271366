import React from "react";
import {Badge} from "peggirkit";
import {useIntl} from "react-intl";

type Props = {
    expirationDate: string
};

const ActiveIndicationBadge = ({expirationDate}: Props) => {
    const intl = useIntl();

    if (expirationDate === null) {
        return <Badge
            type={"gray"}
            content={intl.formatMessage({id: "unactivated"})}
        />
    }

    const date = new Date(expirationDate);
    const expired = new Date() > date;
    return <Badge
        type={expired ? "red" : "green"}
        content={expired ? intl.formatMessage({id: "expired"}) : intl.formatMessage({id: "active"})}
        tooltip={intl.formatMessage({id: "expirationDateOn"}, {date: date.toLocaleString()})}
    />
};

export default ActiveIndicationBadge;