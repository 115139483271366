import React, {useRef} from "react";
import {Alert, Button, isEmpty} from "peggirkit";
import {PlusIcon} from "@heroicons/react/24/outline";
import {EditQuestionSectionProps} from "../../../EditQuestionForm";
import {AnswerTypeProps} from "../AnswerSection";
import {useIntl} from "react-intl";
import {useFieldArray} from "react-hook-form";
import ImageSelector from "./ImageSelector";
import DraggableTarget from "./DraggableTarget";
import {getAbsoluteImageUrl} from "../../../../../assets/AssetsUtil";
// @ts-ignore
import placeholder from "../../../../../../../assets/img/placeholder.jpg";
import Option from "./Option";

const DragDropAnswer = ({
                            loading,
                            control,
                            register,
                            watch,
                            setValue,
                            selectedAnswerType,
                            errors
                        }: EditQuestionSectionProps & AnswerTypeProps) => {
    const intl = useIntl();
    const {fields, append, remove} = useFieldArray({
        control,
        name: "dragDropAnswer.answers",
    });
    const draggableContainerRef = useRef<HTMLDivElement>(null);

    return (
        <div className={"w-full flex flex-col items-start gap-6"}>
            <ImageSelector
                loading={loading}
                selectedAnswerType={selectedAnswerType}
                register={register}
                setValue={setValue}
                errors={errors}
                watch={watch}
            />

            <Alert
                type={"info"}
                title={intl.formatMessage({id: "composeDragDropQuestion"})}
                description={intl.formatMessage({id: "composeDragDropQuestion.explanation"})}
            />

            <div className="w-full">
                <div
                    ref={draggableContainerRef}
                    className={"relative bg-red-100 border rounded-md border-gray-300 w-full aspect-[938/639] overflow-hidden"}
                >
                    {fields.map((a, index) => <DraggableTarget
                        key={a.id}
                        loading={loading}
                        index={index}
                        watch={watch}
                        setValue={setValue}
                        draggableContainerRef={draggableContainerRef}
                    />)}
                    <img
                        className={"absolute w-full h-full top-0 left-0"}
                        src={
                            !isEmpty(watch("dragDropAnswer.image"))
                                ? getAbsoluteImageUrl(watch("dragDropAnswer.image"))
                                : placeholder
                        }
                        alt={intl.formatMessage({id: "dragDropQuestionImage"})}
                    />
                </div>
            </div>

            <Button
                type={"white"}
                text={intl.formatMessage({id: "addDragDropOption"})}
                icon={PlusIcon}
                disabled={loading}
                onClick={e => {
                    e.preventDefault();
                    append({
                        id: undefined,
                        number: -1,
                        draggableOption: true,
                        leftOffset: 50,
                        topOffset: 50
                    });
                }}
            />

            <div className={"flex flex-col w-full gap-6"}>
                {fields.map((a, index) =>
                    <Option
                        key={a.id}
                        id={a.id}
                        loading={loading}
                        index={index}
                        remove={remove}
                        errors={errors}
                        register={register}
                    />
                )}
            </div>
        </div>
    );
};

export default DragDropAnswer;