import React from "react";
import {IntlProvider} from "react-intl";
import DutchMessages from "../../../messages/nl-NL";
import ErrorDetails from "../../widgets/error/ErrorDetails";

const Error = () => {
    return (
        <IntlProvider
            locale="nl-NL"
            messages={DutchMessages}
        >
            <ErrorDetails/>
        </IntlProvider>
    );
};

export default Error;