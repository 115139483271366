import React, {useEffect, useState} from "react";
import {SectionHeading} from "peggirkit";
import RevenueStats from "./revenue/RevenueStats";
import RevenueCard from "./revenue/RevenueCard";
import PlansCard from "./plans/PlansCard";
import {RevenueStats as RevenueStatsEntity} from "../../../../data/Entities";
import {useIntl} from "react-intl";
import {StatisticsSettings} from "../StatisticsOverview";
import {getRevenueStats} from "../../../../data/ApiEndpoints";

type Props = {
    statisticsSettings: StatisticsSettings,
    setStatisticsSettings: React.Dispatch<React.SetStateAction<StatisticsSettings>>,
};

const SalesStatistics = ({statisticsSettings, setStatisticsSettings}: Props) => {
    const intl = useIntl();
    const [revenueStats, setRevenueStats] = useState<RevenueStatsEntity | undefined>();

    useEffect(() => {
        if (!statisticsSettings.dateRange.from
            || !statisticsSettings.dateRange.to
            || !statisticsSettings.loadingSaleStats) {
            return;
        }

        getRevenueStats(statisticsSettings.dateRange.from, statisticsSettings.dateRange.to)
            .then(x => {
                setRevenueStats(x);
                setStatisticsSettings((prev) => ({...prev, loadingSaleStats: false}));
            });
    }, [statisticsSettings.loadingSaleStats]);

    return (
        <div className={"mt-12"}>
            <SectionHeading
                title={intl.formatMessage({id: "sales"})}
                description={intl.formatMessage({id: "salesStatsDescription"})}
            />

            <div className={"w-full flex flex-col gap-6 -mt-5"}>
                <RevenueStats
                    dateRange={statisticsSettings}
                    revenueStats={revenueStats}
                />

                <div className={"grid grid-cols-2 lg:grid-cols-3 gap-6"}>
                    <div className={"col-span-2"}>
                        <RevenueCard
                            dateRange={statisticsSettings}
                            revenueStats={revenueStats}
                        />
                    </div>
                    <div className={"col-span-2 md:col-span-1"}>
                        <PlansCard
                            dateRange={statisticsSettings}
                            revenueStats={revenueStats}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SalesStatistics;