import React from "react";

export type AudioPlayerProps = {
    label: string,
    locale: string,
    file: string,
}

const AudioPlayer = ({label, locale, file}: AudioPlayerProps) => {
    return (
        <div className={"flex flex-col gap-1"}>
            <label className="block text-sm font-medium text-gray-700">
                {label}
            </label>

            <audio
                className={"w-full"}
                controls
            >
                <source
                    src={`${process.env.ASSETS_BASE_URL}/audio/${file}_${locale}.mp3`}
                    type="audio/mpeg"
                />
            </audio>
        </div>
    );
};

export default AudioPlayer;