import React, {useState} from "react";
import StudentsOverviewPageHeading from "./StudentsOverviewPageHeading";
import StudentsDataTable from "./dataTable/StudentsDataTable";
import {StudentStatus} from "../../../data/Entities";

const StudentsOverview = () => {
    const [studentStatusFilter, setStudentStatusFilter] = useState<StudentStatus>(StudentStatus.ANY);

    return (
        <>
            <StudentsOverviewPageHeading
                studentStatusFilter={studentStatusFilter}
                setStudentStatusFilter={setStudentStatusFilter}
            />

            <div className={"mt-8 md:mt-16"}>
                <StudentsDataTable
                    studentStatusFilter={studentStatusFilter}
                />
            </div>
        </>
    );
};

export default StudentsOverview;