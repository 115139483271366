import React from "react";
import {Payload} from "recharts/types/component/DefaultTooltipContent";
import {formatDate} from "../../../../../../utils/DateUtils";
import ChartTooltip from "../../../ChartTooltip";

type Props = {
    active?: boolean,
    payload?: Payload<number, string>[],
    label?: string
};

const TakenExamsChartTooltip = ({active, payload, label}: Props) => {
    if (!active || !payload || !payload.length) {
        return;
    }

    return (
        <ChartTooltip
            title={formatDate(label)}
            values={payload.map(p => `${p.name}: ${p.value}`)}
        />
    );
};

export default TakenExamsChartTooltip;