import React, {useEffect, useState} from "react";
import {TabControl} from "peggirkit";
import {AcademicCapIcon, ExclamationTriangleIcon, LightBulbIcon} from "@heroicons/react/24/outline";
import {ExamCategory} from "../../../../../data/Entities";
import {useIntl} from "react-intl";
import {Control, useFieldArray, UseFormWatch} from "react-hook-form";
import QuestionsDataTable from "./dataTable/QuestionsDataTable";
import QuestionsInExam from "./questionInExam/QuestionsInExam";
import {QuestionFilterSection} from "../../../questions/overview/QuestionsOverview";
import {QuestionFilterCategory} from "../../../categories/CategoryFilterOptions";
import {listCategories} from "../../../../../data/ApiEndpoints";
import {ExamDetailsForm} from "../EditExamContainer";

export type ComposeSectionProps = {
    control: Control<ExamDetailsForm>,
    watch: UseFormWatch<ExamDetailsForm>
};

const ComposeSection = ({control, watch}: ComposeSectionProps) => {
    const intl = useIntl();
    const [sectionFilter, setSectionFilter] = useState<QuestionFilterSection>(QuestionFilterSection.HAZARD_RECOGNITION);
    const [categoryFilter, setCategoryFilter] = useState<QuestionFilterCategory>(null);
    const [categories, setCategories] = useState<ExamCategory[]>([]);
    const {fields: hrFields, append: hrAppend, remove: hrRemove, move: hrMove} = useFieldArray({
        control,
        name: "hazardRecognitionQuestions"
    });
    const {fields: kFields, append: kAppend, remove: kRemove, move: kMove} = useFieldArray({
        control,
        name: "knowledgeQuestions"
    });
    const {fields: iFields, append: iAppend, remove: iRemove, move: iMove} = useFieldArray({
        control,
        name: "insightQuestions"
    });

    // Load categories
    useEffect(() => {
        listCategories().then(page => {
            setCategories(page.content as ExamCategory[]);
        });
    }, []);

    return (
        <div>
            <div className={"w-full flex gap-8 items-start"}>
                <div className={"w-1/2"}>
                    <QuestionsDataTable
                        watch={watch}
                        hrRemove={hrRemove}
                        hrAppend={hrAppend}
                        kRemove={kRemove}
                        kAppend={kAppend}
                        iRemove={iRemove}
                        iAppend={iAppend}
                        sectionFilter={sectionFilter}
                        categories={categories}
                        categoryFilter={categoryFilter}
                        setCategoryFilter={setCategoryFilter}
                    />
                </div>

                <div
                    data-title={"questions-in-exam-container"}
                    className={"w-1/2"}
                >
                    <TabControl
                        wrapperClassname={""}
                        disableHash={true}
                        tabs={[
                            {
                                name: intl.formatMessage({id: "hazardRecognition"}),
                                icon: ExclamationTriangleIcon,
                                current: true,
                                defaultTab: true,
                                onOpen: () => setSectionFilter(QuestionFilterSection.HAZARD_RECOGNITION),
                                content: <QuestionsInExam
                                    categories={categories}
                                    section={QuestionFilterSection.HAZARD_RECOGNITION}
                                    setCategoryFilter={setCategoryFilter}
                                    fields={hrFields}
                                    remove={hrRemove}
                                    move={hrMove}
                                    watch={watch}
                                />
                            },
                            {
                                name: intl.formatMessage({id: "knowledge"}),
                                icon: AcademicCapIcon,
                                current: false,
                                defaultTab: false,
                                onOpen: () => setSectionFilter(QuestionFilterSection.KNOWLEDGE),
                                content: <QuestionsInExam
                                    categories={categories}
                                    section={QuestionFilterSection.KNOWLEDGE}
                                    setCategoryFilter={setCategoryFilter}
                                    fields={kFields}
                                    remove={kRemove}
                                    move={kMove}
                                    watch={watch}
                                />
                            },
                            {
                                name: intl.formatMessage({id: "insight"}),
                                icon: LightBulbIcon,
                                current: false,
                                defaultTab: false,
                                onOpen: () => setSectionFilter(QuestionFilterSection.INSIGHT),
                                content: <QuestionsInExam
                                    categories={categories}
                                    section={QuestionFilterSection.INSIGHT}
                                    setCategoryFilter={setCategoryFilter}
                                    fields={iFields}
                                    remove={iRemove}
                                    move={iMove}
                                    watch={watch}
                                />
                            }
                        ]}
                        selectTabText={intl.formatMessage({id: "pickSection"})}
                    />
                </div>
            </div>
        </div>
    );
};

export default ComposeSection;