import React from "react";
import router, {breadcrumbItemDashboard, breadcrumbItemExams} from "../../../../routing/Router";
import {CheckIcon, FunnelIcon, PlusIcon} from "@heroicons/react/24/outline";
import {BreadcrumbsContent, Icon, PageHeading} from "peggirkit";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import {ExamFilterConcept, ExamFilterDemo} from "./ExamsOverview";

type Props = {
    conceptFilter: ExamFilterConcept,
    setConceptFilter: (concept: ExamFilterConcept) => void,
    demoFilter: ExamFilterDemo,
    setDemoFilter: (concept: ExamFilterDemo) => void,
};

const StudentsOverviewPageHeading = ({
                                         conceptFilter,
                                         setConceptFilter,
                                         demoFilter,
                                         setDemoFilter
                                     }: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemExams]
    };

    return (
        <PageHeading
            breadcrumbsContent={breadcrumbs}
            title={breadcrumbItemExams.name}
            actions={{
                moreButtonText: intl.formatMessage({id: "more"}),
                disabled: false,
                actions: [
                    {
                        type: demoFilter !== null ? "whiteBlueAccent" : undefined,
                        actionType: "dropdown",
                        text: intl.formatMessage({id: "type"}),
                        primary: false,
                        iconOnly: false,
                        icon: FunnelIcon as Icon,
                        options: [
                            [
                                {
                                    disabled: demoFilter === null,
                                    icon: demoFilter === null ? CheckIcon as Icon : undefined,
                                    text: intl.formatMessage({id: "anyType"}),
                                    onClick: () => setDemoFilter(null)
                                }
                            ],
                            [
                                {
                                    disabled: demoFilter === true,
                                    icon: demoFilter === true ? CheckIcon as Icon : undefined,
                                    text: intl.formatMessage({id: "demo"}),
                                    onClick: () => setDemoFilter(true)
                                },
                                {
                                    disabled: demoFilter === false,
                                    icon: demoFilter === false ? CheckIcon as Icon : undefined,
                                    text: intl.formatMessage({id: "nonDemo"}),
                                    onClick: () => setDemoFilter(false)
                                },
                            ],
                        ]
                    },
                    {
                        type: conceptFilter !== null ? "whiteBlueAccent" : undefined,
                        actionType: "dropdown",
                        text: intl.formatMessage({id: "status"}),
                        primary: false,
                        iconOnly: false,
                        icon: FunnelIcon as Icon,
                        options: [
                            [
                                {
                                    disabled: conceptFilter === null,
                                    icon: conceptFilter === null ? CheckIcon as Icon : undefined,
                                    text: intl.formatMessage({id: "anyStatus"}),
                                    onClick: () => setConceptFilter(null)
                                }
                            ],
                            [
                                {
                                    disabled: conceptFilter === false,
                                    icon: conceptFilter === false ? CheckIcon as Icon : undefined,
                                    text: intl.formatMessage({id: "public"}),
                                    onClick: () => setConceptFilter(false)
                                },
                                {
                                    disabled: conceptFilter === true,
                                    icon: conceptFilter === true ? CheckIcon as Icon : undefined,
                                    text: intl.formatMessage({id: "concept"}),
                                    onClick: () => setConceptFilter(true)
                                },
                            ],
                        ]
                    },
                    {
                        actionType: "button",
                        text: intl.formatMessage({id: "newExam"}),
                        primary: true,
                        icon: PlusIcon as Icon,
                        onClick: () => navigate(router.newExam.absolutePath())
                    }
                ]
            }}
        />
    );
};

export default StudentsOverviewPageHeading;