import React, {useRef, useState} from "react";
import {useIntl} from "react-intl";
import {EditBlogPostProps} from "../BlogPostEditor";
import EditMetadataCard from "../../../faq/questions/metadata/EditMetadataCard";
import {getAbsoluteImageUrl} from "../../../assets/AssetsUtil";
import {Icon, InputField} from "peggirkit";
import {ArrowUpTrayIcon} from "@heroicons/react/24/outline";
import ImageUploader from "../../../assets/ImageUploader";

const BlogPostBannerCard = ({
                                loading,
                                register,
                                errors,
                                watch,
                                setValue,
                            }: EditBlogPostProps) => {
    const intl = useIntl();
    const bannerUploaderRef = useRef<HTMLInputElement | null>(null);
    const [isUploading, setIsUploading] = useState(false);

    return (
        <EditMetadataCard name={intl.formatMessage({id: "banner"})}>
            <img
                className={"w-full rounded"}
                src={watch("banner") ? getAbsoluteImageUrl("blog/" + watch("banner")) : undefined}
                alt={intl.formatMessage({id: "banner"})}
            />

            <InputField
                type={"text"}
                id={"banner"}
                reactHookForm={register("banner", {
                    required: intl.formatMessage({id: "mustEnterField"}),
                    maxLength: {
                        value: 256,
                        message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 256}),
                    },
                })}
                trailingButton={{
                    type: "gray",
                    text: intl.formatMessage({id: "upload"}),
                    icon: ArrowUpTrayIcon as Icon,
                    iconOnly: true,
                    disabled: loading,
                    onClick: () => bannerUploaderRef.current && bannerUploaderRef.current.click()
                }}
                displayName={intl.formatMessage({id: "banner"})}
                tip={"Formaat: 1400x562"}
                error={errors.banner && errors.banner.message}
                disabled={loading || isUploading}
            />

            <ImageUploader
                assetDestination={"BLOG"}
                afterUpload={(img) => setValue("banner", img)}
                uploaderRef={bannerUploaderRef}
                setLoading={setIsUploading}
            />

        </EditMetadataCard>
    );
};

export default BlogPostBannerCard;