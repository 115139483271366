import React from "react";
import AuthFormResetPasswordConfirmation from "../../widgets/auth/AuthFormResetPasswordConfirmation";
import router from "../../../routing/Router";
import TheorieGoAuthFrame from "./layout/TheorieGoAuthFrame";
import {AuthFormFooter, AuthFormPanel} from "peggirkit";
import {useIntl} from "react-intl";

const ResetPasswordConfirmation = () => {
    const intl = useIntl();

    return (
        <TheorieGoAuthFrame
            title={intl.formatMessage({id: "setPassword"})}
        >
            <AuthFormPanel
                form={<AuthFormResetPasswordConfirmation/>}
                footer={
                    <AuthFormFooter
                        message={intl.formatMessage({id: "resetLinkExpired"})}
                        link={router.reset.absolutePath()}
                        linkText={intl.formatMessage({id: "requestResetAgain"})}
                    />
                }
            />
        </TheorieGoAuthFrame>
    );
};

export default ResetPasswordConfirmation;