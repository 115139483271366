import React, {useEffect, useMemo, useState} from "react";
import {
    DayPicker,
    FormLayoutCard,
    FormLayoutFooterOptions, Icon,
    InputCheckbox,
    InputCreatableSelect,
    InputField,
    NotificationService,
    RadioGroup,
    TwoColumnFormLayout
} from "peggirkit";
import {useForm} from "react-hook-form";
import {CalendarIcon} from "@heroicons/react/24/outline";
import router from "../../../../../routing/Router";
import {useNavigate} from "react-router-dom";
import {PreferredLanguage, Student} from "../../../../../data/Entities";
import {upsertStudent} from "../../../../../data/ApiEndpoints";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {FormattedMessage, useIntl} from "react-intl";

dayjs.extend(utc);

type Props = {
    student?: Student,
    setStudent: (student: Student) => void
};

type PreferredLang = {
    label: string,
    value: PreferredLanguage
};

type StudentForm = Omit<Student, "preferredLang" | ""> & {
    preferredLang: PreferredLang,
    activeState: "active" | "not_yet_active"
};

const EditStudentForm = ({student, setStudent}: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showSelectExpirationDay, setShowSelectExpirationDay] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);
    const {register, control, setValue, getValues, watch, handleSubmit, formState: {errors}} = useForm<StudentForm>({
        defaultValues: {
            id: undefined,
            code: "",
            emailAddress: "",
            name: "",
            receivedReminder: "",
            preferredLang: undefined,
            activatedDurationMinutes: undefined,
            expirationDate: undefined,
            startExamWithExplanation: true,
            creationDate: ""
        }
    });
    const preferredLangOptions: PreferredLang[] = useMemo(() => ([
        {
            label: intl.formatMessage({id: "arabic"}),
            value: "ar"
        },
        {
            label: intl.formatMessage({id: "dutch"}),
            value: "nl"
        }
    ]), [intl]);

    // Initialize existing data
    useEffect(() => {
        if (student) {
            setValue("id", student.id);
            setValue("code", student.code);
            setValue("emailAddress", student.emailAddress);
            setValue("name", student.name);
            setValue("receivedReminder", student.receivedReminder);
            setValue("startExamWithExplanation", student.startExamWithExplanation);
            setValue("preferredLang", preferredLangOptions.filter(({
                                                                       label,
                                                                       value
                                                                   }) => value === student.preferredLang)[0]);
            setValue("activatedDurationMinutes", student.activatedDurationMinutes);
            setValue("expirationDate", student.expirationDate
                ? dayjs(student.expirationDate).format("YYYY-MM-DD[T]HH:mm:ssZZ")
                : undefined);
            setValue("creationDate", dayjs(student.creationDate).format("YYYY-MM-DD[T]HH:mm:ssZZ"));
            setValue("activeState", student.expirationDate === null ? "not_yet_active" : "active");
        } else {
            setValue("activeState", "not_yet_active");
        }
    }, [student]);

    // Upsert data
    useEffect(() => {
        if (!loading) {
            return;
        }

        const values = getValues();
        upsertStudent({
            id: values.id,
            emailAddress: values.emailAddress,
            name: values.name,
            preferredLang: values.preferredLang.value,
            activatedDurationMinutes: values.activatedDurationMinutes,
            startExamWithExplanation: values.startExamWithExplanation,
            expirationDate: values.expirationDate
                ? dayjs(values.expirationDate).utc(false).format("YYYY-MM-DD[T]HH:mm:ssZZ")
                : undefined,
        }).then(updatedStudent => {
            if (watch("id") === undefined || watch("id") === null) {
                navigate(router.student.absolutePath(updatedStudent.id));
            }

            setStudent(updatedStudent);
            setLoading(false);
            NotificationService.notify({type: "success", text: intl.formatMessage({id: "studentUpdated"})});
        }).catch(_ => {
            setFormError(intl.formatMessage({id: "operationError"}));
            NotificationService.notify({type: "danger", text: intl.formatMessage({id: "operationError"})});
            setLoading(false);
        });
    }, [loading]);

    return (
        <TwoColumnFormLayout
            onSubmit={handleSubmit(() => setLoading(true))}
            error={formError}
        >
            <FormLayoutCard
                title={intl.formatMessage({id: "general"})}
                description={intl.formatMessage({id: "generalPersonData"})}
            >
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 lg:col-span-4">
                        <InputField
                            type={"text"}
                            id={"id"}
                            displayName={intl.formatMessage({id: "id"})}
                            reactHookForm={{...register("id")}}
                            disabled={true}
                            tip={intl.formatMessage({id: "internalIdReferencePurposes"})}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4">
                        <InputField
                            type={"text"}
                            id={"code"}
                            displayName={intl.formatMessage({id: "code"})}
                            reactHookForm={{...register("code")}}
                            disabled={true}
                            tip={intl.formatMessage({id: "codeToSignInWith"})}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4">
                        <InputField
                            type={"text"}
                            id={"name"}
                            displayName={intl.formatMessage({id: "name"})}
                            reactHookForm={{
                                ...register("name", {
                                    required: intl.formatMessage({id: "mustEnterField"}),
                                    minLength: {
                                        value: 1,
                                        message: intl.formatMessage({id: "mustBeMinCharacters"}, {min: 1})
                                    },
                                    maxLength: {
                                        value: 128,
                                        message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 128})
                                    },
                                })
                            }}
                            error={errors.name && errors.name.message}
                            tip={intl.formatMessage({id: "nOutOfTCharacters"}, {n: watch("name")?.length, t: 128})}
                            disabled={loading}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4">
                        <InputField
                            type={"email"}
                            id={"emailAddress"}
                            displayName={intl.formatMessage({id: "emailAddress"})}
                            reactHookForm={{
                                ...register("emailAddress", {
                                    required: intl.formatMessage({id: "mustEnterField"}),
                                    minLength: {
                                        value: 1,
                                        message: intl.formatMessage({id: "mustBeMinCharacters"}, {min: 1})
                                    },
                                    maxLength: {
                                        value: 128,
                                        message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 128})
                                    }
                                })
                            }}
                            tip={intl.formatMessage({id: "nOutOfTCharacters"}, {n: watch("emailAddress")?.length, t: 128})}
                            error={errors.emailAddress && errors.emailAddress.message}
                            disabled={loading}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4">
                        <InputField
                            type={"text"}
                            id={"creationDate"}
                            displayName={intl.formatMessage({id: "registeredOn"})}
                            reactHookForm={{...register("creationDate")}}
                            disabled={true}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4">
                        <InputCreatableSelect
                            id={"preferredLang"}
                            displayName={intl.formatMessage({id: "preferredLanguage"})}
                            nameHookForm="preferredLang"
                            options={preferredLangOptions}
                            tip={intl.formatMessage({id: "languageEmailsAreSentIn"})}
                            disabled={loading}
                            control={control}
                            rules={{required: intl.formatMessage({id: "mustPickOption"})}}
                            error={errors.preferredLang && errors.preferredLang.message}
                            isValidNewOption={_ => false}
                            formatCreateLabel={inputValue => intl.formatMessage({id: "addOption"}, {option: inputValue})}
                            placeholder={intl.formatMessage({id: "pickALanguage"})}
                        />
                    </div>
                </div>
            </FormLayoutCard>

            <FormLayoutCard
                title={intl.formatMessage({id: "activeState"})}
                description={intl.formatMessage({id: "activeState.description"})}
            >
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 lg:col-span-4">
                        <RadioGroup
                            title={intl.formatMessage({id: "activation"})}
                            name={"activeState"}
                            tip={intl.formatMessage({id: "activation.description"})}
                            error={errors.activeState && errors.activeState.message}
                            disabled={loading}
                            options={[
                                {value: "active", label: intl.formatMessage({id: "activated"})},
                                {value: "not_yet_active", label: intl.formatMessage({id: "unactivated"})}
                            ]}
                            reactHookForm={{
                                ...register("activeState")
                            }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 lg:col-span-4">
                        <InputField
                            type={"number"}
                            id={"activatedDurationMinutes"}
                            displayName={intl.formatMessage({id: "activatedDuration"})}
                            reactHookForm={{
                                ...register("activatedDurationMinutes", {
                                    required: watch("activeState") === "not_yet_active"
                                        ? intl.formatMessage({id: "mustEnterField"})
                                        : false
                                })
                            }}
                            disabled={loading || watch("activeState") === "active"}
                            error={errors.activatedDurationMinutes && errors.activatedDurationMinutes.message}
                            tip={intl.formatMessage({id: "activatedDuration.description"})}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4">
                        <InputField
                            type={"text"}
                            id={"expirationDate"}
                            displayName={intl.formatMessage({id: "expirationDate"})}
                            tip={intl.formatMessage({id: "expirationDate.description"})}
                            reactHookForm={{
                                ...register("expirationDate", {
                                    required: watch("activeState") === "active"
                                        ? intl.formatMessage({id: "mustEnterField"})
                                        : false
                                })
                            }}
                            error={errors.expirationDate && errors.expirationDate.message}
                            disabled={loading || watch("activeState") === "not_yet_active"}
                            trailingButton={{
                                type: "gray",
                                text: intl.formatMessage({id: "chooseDate"}),
                                icon: CalendarIcon as Icon,
                                disabled: loading,
                                onClick: (e) => {
                                    e.preventDefault();
                                    setShowSelectExpirationDay(true);
                                }
                            }}
                        />
                    </div>
                </div>
            </FormLayoutCard>

            <FormLayoutCard
                title={intl.formatMessage({id: "preferences"})}
                description={intl.formatMessage({id: "studentPreferencesDescription"})}
            >
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 lg:col-span-4">
                        <div>
                            <label className="contents text-sm font-medium text-gray-900">
                                <FormattedMessage id={"startExamWithExplanation"}/>
                            </label>
                            <p className="mt-2 text-sm text-gray-500 mt-0 mb-4">
                                <FormattedMessage id={"startExamWithExplanation.description"}/>
                            </p>
                            <InputCheckbox
                                id={"startExamWithExplanation"}
                                displayName={intl.formatMessage({id: "showExplanationScreen"})}
                                reactHookForm={{...register("startExamWithExplanation")}}
                                disabled={loading}
                                error={errors.startExamWithExplanation && errors.startExamWithExplanation.message}
                            />
                        </div>
                    </div>
                </div>
            </FormLayoutCard>

            <FormLayoutFooterOptions
                cancelText={intl.formatMessage({id: "backToStudents"})}
                onCancel={() => navigate(router.students.absolutePath())}
                submitText={intl.formatMessage({id: "saveChanges"})}
                submitLoading={loading}
                disabled={loading}
            />

            <DayPicker
                key={watch("expirationDate")}
                defaultSelected={watch("expirationDate") ? new Date(watch("expirationDate") + "") : new Date()}
                title={intl.formatMessage({id: "expirationDate"})}
                cancelText={intl.formatMessage({id: "cancel"})}
                continueText={intl.formatMessage({id: "ok"})}
                show={showSelectExpirationDay}
                setShow={setShowSelectExpirationDay}
                onSelect={(selected) => {
                    if (selected) {
                        setValue("expirationDate", dayjs(selected).format("YYYY-MM-DD[T]HH:mm:ssZZ"));
                    }
                }}
                withTime={{
                    messages: {
                        time: intl.formatMessage({id: "time"}),
                        hour: intl.formatMessage({id: "hour"}),
                        minute: intl.formatMessage({id: "minute"}),
                    }
                }}
            />
        </TwoColumnFormLayout>
    );
};

export default EditStudentForm;