import React, {useId, useRef} from "react";
import {Icon, InputField, Link} from "peggirkit";
import {ArrowUpTrayIcon} from "@heroicons/react/24/outline";
import {UseFormRegister, UseFormSetValue, UseFormWatch} from "react-hook-form";
import {ExamQuestionForm} from "../../../EditQuestionForm";
import {FormattedMessage, useIntl} from "react-intl";
import ImageUploader from "../../../../../assets/ImageUploader";
import {SelectedAnswerType} from "../AnswerType";
import {FieldErrors} from "react-hook-form/dist/types/errors";

type Props = {
    loading: boolean,
    selectedAnswerType: SelectedAnswerType,
    setValue: UseFormSetValue<ExamQuestionForm>,
    register: UseFormRegister<ExamQuestionForm>,
    errors: FieldErrors<ExamQuestionForm>,
    watch: UseFormWatch<ExamQuestionForm>
};

const ImageSelector = ({loading, selectedAnswerType, setValue, register, errors, watch}: Props) => {
    const id = useId();
    const intl = useIntl();
    const imageUploaderRef = useRef<HTMLInputElement | null>(null);

    return (
        <div className={"w-full"}>
            <InputField
                id={id}
                type={"text"}
                disabled={loading}
                reactHookForm={{
                    ...register("dragDropAnswer.image", {
                        required: selectedAnswerType === SelectedAnswerType.DRAG_DROP
                            ? intl.formatMessage({id: "mustSelectImage"})
                            : false
                    })
                }}
                displayName={intl.formatMessage({id: "dragDropQuestionImage"})}
                addOn={intl.formatMessage({id: "image"})}
                trailingButton={{
                    type: "gray",
                    iconOnly: false,
                    icon: ArrowUpTrayIcon as Icon,
                    text: intl.formatMessage({id: "upload"}),
                    disabled: loading,
                    onClick: e => {
                        e.preventDefault();
                        imageUploaderRef.current && imageUploaderRef.current.click();
                    }
                }}
                tip={
                    <>
                        <span className={"mr-2"}>
                            <FormattedMessage id="questionImageAspectRatioTip"/>
                        </span>
                        <Link
                            text={intl.formatMessage({id: "copyToQuestionImage"})}
                            href={"#"}
                            onClick={e => {
                                e.preventDefault();
                                setValue("image", watch("dragDropAnswer.image"));
                            }}
                        />
                    </>
                }
                error={errors.dragDropAnswer?.image && errors.dragDropAnswer.image.message}
            />
            <ImageUploader
                afterUpload={(img) => setValue("dragDropAnswer.image", img)}
                uploaderRef={imageUploaderRef}
            />
        </div>
    );
};

export default ImageSelector;