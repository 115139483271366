import React from "react";
import {ExamCategory} from "../../../../../../data/Entities";
import {Badge} from "peggirkit";

type Props = {
    category: ExamCategory
};

const CategoryFormatter = ({category}: Props) => {
    return (
        <Badge
            type={"gray"}
            content={category.code || category.nameNl}
            tooltip={`${category.code ? category.code + " - " : ""}${category.nameNl}`}
        />
    );
}

export default CategoryFormatter;