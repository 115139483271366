import React, {useMemo} from "react";
import {getNavItems} from "../../../page/authenticated/layout/TheorieGoFrame";
import {PrimaryNavigationItemProps} from "peggirkit";
import DashboardLink from "./DashboardLink";

const DashboardLinks = () => {
    const navItems: PrimaryNavigationItemProps[] = useMemo(() => getNavItems(null), []);

    return (
        <div className={"py-12 lg:py-24 w-full flex justify-center"}>
            <div className={"w-full max-w-[1000px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-6"}>
                {
                    navItems.map((item, i) =>
                        <DashboardLink key={i} {...item} />
                    )
                }
            </div>
        </div>
    );
};

export default DashboardLinks;