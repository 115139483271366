import React, {useMemo} from "react";
import {Link, SlideOver, Stats, Table} from "peggirkit";
import ConceptFormatter from "../../../../../formatter/ConceptFormatter";
import {QuestionInExam} from "../../../../../../../data/Entities";
import {useIntl} from "react-intl";
import router from "../../../../../../../routing/Router";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void,
    questionId: number,
    questionInExams: QuestionInExam[]
};

const ExamsSlideOver = ({show, setShow, questionId, questionInExams}: Props) => {
    const intl = useIntl();
    const publicExams: number = useMemo(() => questionInExams.filter(q => !q.exam.concept).length, [questionInExams]);
    return (
        <SlideOver
            open={show}
            setOpen={setShow}
            title={intl.formatMessage({id: "examsWithQuestion"}, {
                questionId: questionId,
                count: questionInExams.length
            })}
        >
            <Stats
                items={[
                    {
                        name: intl.formatMessage({id: "total"}),
                        stat: questionInExams.length
                    },
                    {
                        name: intl.formatMessage({id: "public"}),
                        stat: publicExams
                    },
                    {
                        name: intl.formatMessage({id: "concept"}),
                        stat: questionInExams.length - publicExams
                    }
                ]}
            />
            <Table
                className={"mt-6"}
                columns={[
                    {
                        name: intl.formatMessage({id: "name"}),
                        sortable: false
                    },
                    {
                        name: intl.formatMessage({id: "status"}),
                        sortable: false,
                        secondary: true,
                        alignRight: true
                    },
                ]}
                data={questionInExams.map(q => ({
                    [intl.formatMessage({id: "name"})]: <Link
                        href={router.editExam.absolutePath(q.exam.id)}
                        text={q.exam.nameNl}
                    />,
                    [intl.formatMessage({id: "status"})]: <ConceptFormatter concept={q.exam.concept}/>
                }))}
            />
        </SlideOver>
    );
};

export default ExamsSlideOver;