import React, {useEffect, useState} from "react";
import router, {breadcrumbItemDashboard} from "../../../routing/Router";
import {BreadcrumbsContent, capitalizeFirstLetter, Icon, PageHeading, useAuth} from "peggirkit";
import {UserCircleIcon} from "@heroicons/react/24/outline";
import {useIntl} from "react-intl";
import {User} from "../../../data/Entities";
import {useNavigate} from "react-router-dom";

const DashboardHeading = () => {
    const intl = useIntl();
    const auth = useAuth();
    const [user, setUser] = useState<User | null>(null);
    const navigate = useNavigate();
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard]
    };

    useEffect(() => {
        auth?.currentUser().then((user: User) => setUser(user));
    }, []);

    return (
        <PageHeading
            breadcrumbsContent={breadcrumbs}
            title={breadcrumbItemDashboard.name}
            actions={{
                moreButtonText: intl.formatMessage({id: "more"}),
                actions: user === null ? [] : [{
                    type: "white",
                    actionType: "button",
                    text: capitalizeFirstLetter(user.emailAddress),
                    icon: UserCircleIcon as Icon,
                    onClick: () => navigate(router.account.absolutePath()),
                    primary: true,
                }]
            }}
        />
    );
};

export default DashboardHeading;