import React from "react";
import {breadcrumbItemExams} from "../../../routing/Router";
import TheorieGoFrame from "./layout/TheorieGoFrame";
import ExamsOverview from "../../widgets/exams/overview/ExamsOverview";

const Exams = () => {
    return (
        <TheorieGoFrame title={breadcrumbItemExams.name}>
            <ExamsOverview/>
        </TheorieGoFrame>
    );
};

export default Exams;