import React from "react";
import GeneralTab from "./general/GeneralTab";
import {CurrencyEuroIcon, PlayIcon, UserIcon} from "@heroicons/react/24/outline";
import {Icon, TabControl} from "peggirkit";
import ActionsTab from "./actions/ActionsTab";
import {Student} from "../../../../data/Entities";
import {useIntl} from "react-intl";
import FinancesTab from "./finances/FinancesTab";

type Props = {
    student?: Student,
    setStudent: (student: Student) => void
};

const StudentDetails = ({student, setStudent}: Props) => {
    const intl = useIntl();

    return (
        <div>
            <TabControl
                key={student?.id}
                selectTabText={intl.formatMessage({id: "pickATab"})}
                tabs={[
                    {
                        current: true,
                        defaultTab: true,
                        icon: UserIcon as Icon,
                        name: intl.formatMessage({id: "general"}),
                        hash: "general",
                        content: <GeneralTab student={student} setStudent={setStudent}/>
                    },
                    ...(student ? [{
                        current: false,
                        defaultTab: false,
                        icon: PlayIcon as Icon,
                        name: intl.formatMessage({id: "actions"}),
                        hash: "actions",
                        content: <ActionsTab student={student}/>
                    }] : []),
                    ...(student ? [{
                        current: false,
                        defaultTab: false,
                        icon: CurrencyEuroIcon as Icon,
                        name: intl.formatMessage({id: "finances"}),
                        hash: "finances",
                        content: <FinancesTab student={student}/>
                    }] : [])
                ]}
            />
        </div>
    );
};

export default StudentDetails;