import React, {ChangeEvent} from "react";
import {uploadImage} from "../../../data/ApiEndpoints";
import {AssetDestination} from "../../../data/Entities";

type Props = {
    afterUpload: (filename: string) => void,
    uploaderRef: any,
    assetDestination?: AssetDestination,
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
};

const ImageUploader = ({afterUpload, uploaderRef, assetDestination = "GENERAL", setLoading}: Props) => {

    const handleUploadImage = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (!e.target.files || e.target.files.length === 0) {
            return;
        }

        if (setLoading) {
            setLoading(true);
        }

        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        uploadImage(formData, assetDestination).then(response => {
            afterUpload(response.message);

            if (setLoading) {
                setLoading(false);
            }
        });
    };

    return (
        <input
            hidden
            accept="image/*"
            type="file"
            multiple={false}
            onChange={handleUploadImage}
            ref={uploaderRef}
        />
    );
};

export default ImageUploader;