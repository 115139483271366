import React, {useState} from "react";
import {Button, FormLayoutCard, Icon, InputFeedback} from "peggirkit";
import {useIntl} from "react-intl";
import {TrashIcon} from "@heroicons/react/24/outline";
import DeleteQuestionsModal from "../../../delete/DeleteQuestionsModal";
import {QuestionInExam} from "../../../../../../data/Entities";
import {useNavigate} from "react-router-dom";
import router from "../../../../../../routing/Router";

type Props = {
    questionId?: number,
    questionInExams?: QuestionInExam[]
};

const DeleteSection = ({questionId, questionInExams}: Props) => {
    const [showModal, setShowModal] = useState(false);
    const intl = useIntl();
    const navigate = useNavigate();

    if (!questionId) {
        return <></>;
    }

    const canDeleteQuestion = !questionInExams || questionInExams.length === 0;
    return (
        <FormLayoutCard
            title={intl.formatMessage({id: "delete"})}
            description={intl.formatMessage({id: "editExamQuestion.section.delete.description"})}
        >
            <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 lg:col-span-4">
                    <Button
                        type={"danger"}
                        disabled={!canDeleteQuestion}
                        icon={TrashIcon as Icon}
                        text={intl.formatMessage({id: "deleteExamQuestion"})}
                        onClick={e => {
                            e.preventDefault();
                            setShowModal(true);
                        }}
                    />
                    {
                        canDeleteQuestion
                            ? <></>
                            : <InputFeedback
                                message={intl.formatMessage({id: "questionIsStillInExam"})}
                                type="error"
                            />
                    }
                </div>
            </div>

            <DeleteQuestionsModal
                show={showModal}
                setShow={setShowModal}
                questionIds={[questionId]}
                cb={() => navigate(router.questions.absolutePath())}
            />
        </FormLayoutCard>
    );
};

export default DeleteSection;