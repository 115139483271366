import React from "react";
import {BreadcrumbsContent, Icon, PageHeading} from "peggirkit";
import router, {breadcrumbItemBlog, breadcrumbItemDashboard} from "../../../../routing/Router";
import {PlusIcon} from "@heroicons/react/24/outline";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";

const BlogOverviewPageHeading = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemBlog]
    };

    return (
        <PageHeading
            breadcrumbsContent={breadcrumbs}
            title={breadcrumbItemBlog.name}
            actions={{
                moreButtonText: intl.formatMessage({id: "more"}),
                disabled: false,
                actions: [
                    {
                        actionType: "button",
                        text: intl.formatMessage({id: "newPost"}),
                        primary: true,
                        icon: PlusIcon as Icon,
                        onClick: () => navigate(router.newBlogPost.absolutePath())
                    }
                ]
            }}
        />
    );
};

export default BlogOverviewPageHeading;