import React from "react";
import {LockClosedIcon, UserIcon} from "@heroicons/react/24/outline";
import {breadcrumbItemAccount, breadcrumbItemDashboard,} from "../../../routing/Router";
import AccountTabGeneral from "../../widgets/account/AccountTabGeneral";
import AccountTabSecurity from "../../widgets/account/AccountTabSecurity";
import {BreadcrumbsContent, PageHeading, TabControl} from "peggirkit";
import TheorieGoFrame from "./layout/TheorieGoFrame";
import {useIntl} from "react-intl";

const Account = () => {
    const intl = useIntl();
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemAccount]
    };

    return (
        <TheorieGoFrame title={breadcrumbItemAccount.name}>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={breadcrumbItemAccount.name}
            />
            <TabControl
                selectTabText={intl.formatMessage({id: "pickATab"})}
                tabs={[
                    {
                        name: intl.formatMessage({id: "general"}),
                        hash: "general",
                        defaultTab: true,
                        icon: UserIcon,
                        current: true,
                        content: <AccountTabGeneral/>
                    },
                    {
                        name: intl.formatMessage({id: "security"}),
                        hash: "security",
                        icon: LockClosedIcon,
                        current: false,
                        content: <AccountTabSecurity/>
                    },
                ]}
            />
        </TheorieGoFrame>
    );
};

export default Account;