import React from "react";
import {AdjustmentsHorizontalIcon, LanguageIcon} from "@heroicons/react/24/outline";
import {Card, Icon, TabControl} from "peggirkit";
import {useIntl} from "react-intl";
import "react-quill/dist/quill.snow.css";
import "../../../quill/quill.scss";
import {EditFaqQuestionProps} from "../EditFaqQuestionContainer";
import ContentCardTab from "./ContentCardTab";

type Props = {
    setMetadataSliderOpen: (open: boolean) => void,
};

const ContentCard = ({
                         setMetadataSliderOpen,
                         loading,
                         control,
                         register,
                         errors,
                         watch
                     }: Props & EditFaqQuestionProps) => {
    const intl = useIntl();
    return (
        <Card
            header={{
                title: intl.formatMessage({id: "questionAndAnswer"}),
                action: {
                    className: "xl:hidden",
                    type: "white",
                    text: intl.formatMessage({id: "metadata"}),
                    icon: AdjustmentsHorizontalIcon as Icon,
                    onClick: () => setMetadataSliderOpen(true)
                },
            }}
        >
            <TabControl
                selectTabText={intl.formatMessage({id: "selectTab"})}
                wrapperClassname={"mb-10"}
                disableHash={true}
                tabs={[
                    {
                        name: intl.formatMessage({id: "dutch"}),
                        current: true,
                        icon: LanguageIcon as Icon,
                        defaultTab: true,
                        content: <ContentCardTab
                            key={"nl"}
                            loading={loading}
                            register={register}
                            control={control}
                            errors={errors}
                            watch={watch}
                            questionField={"questionNl"}
                            answerField={"answerNl"}
                        />
                    },
                    {
                        name: intl.formatMessage({id: "arabic"}),
                        current: false,
                        icon: LanguageIcon as Icon,
                        defaultTab: false,
                        content: <ContentCardTab
                            key={"ar"}
                            loading={loading}
                            register={register}
                            control={control}
                            errors={errors}
                            watch={watch}
                            questionField={"questionAr"}
                            answerField={"answerAr"}
                        />
                    },
                ]}
            />
        </Card>
    );
};

export default ContentCard;