import React from "react";
import {InputField} from "peggirkit";
import {FieldProps} from "../../fields/Field";
import {useIntl} from "react-intl";

const PasswordField = ({register, disabled, fieldError, fieldName, displayName}: FieldProps) => {
    const intl = useIntl();

    return (
        <InputField
            id={fieldName}
            displayName={displayName || intl.formatMessage({id: "password"})}
            type={"password"}
            placeholder="••••••••••••••••••"
            disabled={disabled}
            error={fieldError && fieldError.message}
            reactHookForm={{
                ...register(fieldName, {
                    required: intl.formatMessage({id: "enterAPassword"}),
                    minLength: {
                        value: 8,
                        message: intl.formatMessage({id: "mustBeMinCharacters"}, {min: 8})
                    },
                    maxLength: {
                        value: 128,
                        message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 128})
                    }
                })
            }}
        />
    );
};

export default PasswordField;