import React from "react";
import router from "../../../routing/Router";
import {ErrorPage} from "peggirkit";
import {useIntl} from "react-intl";

const ErrorDetails = () => {
    const intl = useIntl();

    return (
        <ErrorPage
            title={intl.formatMessage({id: "error"})}
            appName={intl.formatMessage({id: "appName"})}
            errorText={intl.formatMessage({id: "error"})}
            links={[
                {
                    text: intl.formatMessage({id: "homepage"}),
                    href: process.env.LANDING_BASE_URL || ""
                },
                {
                    text: intl.formatMessage({id: "dashboard"}),
                    href: router.dashboard.absolutePath()
                }
            ]}
        />
    );
};

export default ErrorDetails;