import React, {useState} from "react";
import {QuestionInExam} from "../../../../../../../data/Entities";
import {Link} from "peggirkit";
import ExamsSlideOver from "./ExamsSlideOver";

type Props = {
    questionId: number,
    questionInExams: QuestionInExam[]
};

const InExamsFormatter = ({questionId, questionInExams}: Props) => {
    const [showExams, setShowExams] = useState(false);

    return (
        <>
            <Link
                href={"#"}
                onClick={e => {
                    e.preventDefault();
                    setShowExams(true);
                }}
                text={questionInExams.length + ""}
            />
            <ExamsSlideOver
                show={showExams}
                setShow={setShowExams}
                questionId={questionId}
                questionInExams={questionInExams}
            />
        </>
    );
};

export default InExamsFormatter;