import React from "react";
import {EditQuestionSectionProps} from "../../../EditQuestionForm";
import {InputField} from "peggirkit";
import {useIntl} from "react-intl";
import {AnswerTypeProps} from "../AnswerSection";
import {SelectedAnswerType} from "../AnswerType";


const NumberAnswer = ({
                          selectedAnswerType,
                          loading,
                          register,
                          errors
                      }: EditQuestionSectionProps & AnswerTypeProps) => {
    const intl = useIntl();

    return (
        <InputField
            type={"number"}
            id={"question-answer-number"}
            displayName={intl.formatMessage({id: "numberAsAnswer"})}
            reactHookForm={{
                ...register("numberAnswer", {
                    valueAsNumber: true,
                    required: selectedAnswerType === SelectedAnswerType.NUMBER
                        ? intl.formatMessage({id: "mustEnterField"})
                        : false,
                })
            }}
            error={errors.numberAnswer && errors.numberAnswer.message}
            disabled={loading}
        />
    );
};

export default NumberAnswer;