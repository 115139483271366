import React, {useState} from "react";
import {DescriptionList, SectionHeading} from "peggirkit";
import {Student} from "../../../../../../data/Entities";
import ResendWelcomeMailModal from "./ResendWelcomeMailModal";
import {useIntl} from "react-intl";

type Props = {
    student: Student
};

const MailingSection = ({student}: Props) => {
    const intl = useIntl();
    const [showResendWelcomeMailModal, setShowResendWelcomeMailModal] = useState(false);

    return (
        <>
            <SectionHeading
                title={intl.formatMessage({id: "mailing"})}
                description={intl.formatMessage({id: "studentMailingDescription"})}
            />
            <DescriptionList
                items={[
                    {
                        key: intl.formatMessage({id: "welcomeMail"}),
                        value: intl.formatMessage({id: "welcomeMailDescription"}),
                        options: [{
                            text: intl.formatMessage({id: "send"}),
                            onClick: () => setShowResendWelcomeMailModal(true)
                        }]
                    },
                    {
                        key: intl.formatMessage({id: "expirationReminderMail"}),
                        keyTooltip: intl.formatMessage({id: "expirationReminderMail.description"}),
                        value: !student.receivedReminder
                            ? intl.formatMessage({id: "notYetSent"})
                            : intl.formatMessage(
                                {id: "sentOnDate"},
                                {"date": new Date(student.receivedReminder).toLocaleString()}
                            )
                    }
                ]}
            />

            {
                student.id &&
                <ResendWelcomeMailModal
                    studentId={student.id}
                    show={showResendWelcomeMailModal}
                    setShow={setShowResendWelcomeMailModal}
                />
            }
        </>
    );
};

export default MailingSection;