import React, {RefObject, useMemo} from "react";
import Draggable from "react-draggable";
import {UseFormSetValue, UseFormWatch} from "react-hook-form";
import {ExamQuestionForm} from "../../../EditQuestionForm";
import {classNames, useDimensions} from "peggirkit";

type Props = {
    index: number,
    loading: boolean,
    watch: UseFormWatch<ExamQuestionForm>,
    setValue: UseFormSetValue<ExamQuestionForm>
    draggableContainerRef: RefObject<HTMLDivElement>
};

const DraggableTarget = ({
                             index,
                             loading,
                             watch,
                             setValue,
                             draggableContainerRef
                         }: Props) => {
    const {width: draggableContainerWidth, height: draggableContainerHeight} = useDimensions(draggableContainerRef);
    const draggableSize = useMemo(() => {
        const min = 25;
        const calculated = (64 / 938) * draggableContainerWidth;
        return calculated < min ? min : calculated;
    }, [draggableContainerWidth]);
    const toPercentageX = (pxOffsetX: number): number => (100 / draggableContainerWidth) * pxOffsetX;
    const toPercentageY = (pxOffsetY: number): number => (100 / draggableContainerHeight) * pxOffsetY;
    const fromPercentageX = (percentageOffsetX: number): number => (draggableContainerWidth / 100) * percentageOffsetX;
    const fromPercentageY = (percentageOffsetY: number): number => (draggableContainerHeight / 100) * percentageOffsetY;

    return (
        <Draggable
            axis="both"
            disabled={loading}
            positionOffset={{
                x: -draggableSize / 2,
                y: -draggableSize / 2,
            }}
            bounds={{
                top: draggableSize / 2,
                left: draggableSize / 2,
                right: draggableContainerWidth - (draggableSize / 2),
                bottom: draggableContainerHeight - (draggableSize / 2),
            }}
            position={{
                x: fromPercentageX(watch(`dragDropAnswer.answers.${index}.leftOffset`)),
                y: fromPercentageY(watch(`dragDropAnswer.answers.${index}.topOffset`))
            }}
            onStop={(_, {x, y}) => {
                setValue(`dragDropAnswer.answers.${index}.leftOffset`, toPercentageX(x));
                setValue(`dragDropAnswer.answers.${index}.topOffset`, toPercentageY(y));
            }}
        >
            <div
                className={classNames(
                    "absolute block cursor-move border border-black rounded-full text-sm font-semibold flex items-center justify-center z-10",
                    !watch(`dragDropAnswer.answers.${index}.draggableOption`)
                        ? "bg-[#B38600] opacity-90 border-dashed text-gray-700"
                        : "bg-[#F3B500] border-solid text-black"
                )}
                style={{
                    width: `${draggableSize}px`,
                    height: `${draggableSize}px`
                }}
            >
                {watch(`dragDropAnswer.answers.${index}.number`)}
            </div>
        </Draggable>
    );
};

export default DraggableTarget;