import React from "react";
import {classNames, InputField} from "peggirkit";
import {useIntl} from "react-intl";
import Field from "peggirkit/dist/components/Input/Field/Field";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import "../../../quill/quill.scss";
import {Controller} from "react-hook-form";
import {EditFaqQuestionProps} from "../EditFaqQuestionContainer";

type Props = {
    questionField: "questionNl" | "questionAr",
    answerField: "answerNl" | "answerAr",
};

const ContentCard = ({
                         loading,
                         control,
                         register,
                         errors,
                         watch,
                         questionField,
                         answerField
                     }: EditFaqQuestionProps & Props) => {
    const intl = useIntl();
    return (
        <div className={"flex flex-col gap-6"}>
            <InputField
                type={"text"}
                id={questionField}
                reactHookForm={register(questionField, {
                    required: intl.formatMessage({id: "mustEnterField"}),
                    maxLength: {
                        value: 512,
                        message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 512}),
                    },
                })}
                error={errors[questionField] && errors[questionField]?.message}
                rtl={questionField === "questionAr"}
                displayName={intl.formatMessage({id: "question"})}
                tip={intl.formatMessage({id: "nOutOfTCharacters"}, {n: watch(questionField)?.length || 0, t: 512})}
                disabled={loading}
            />

            <Controller
                name={answerField}
                control={control}
                rules={{required: intl.formatMessage({id: "mustEnterField"})}}
                render={({field: {onChange, value}}) => (
                    <Field
                        id={answerField}
                        displayName={intl.formatMessage({id: "answer"})}
                        error={errors[answerField] && errors[answerField]?.message}
                        inputEl={
                            <ReactQuill
                                readOnly={loading}
                                theme="snow"
                                value={value}
                                onChange={onChange}
                                className={classNames(
                                    "group shadow-sm",
                                    answerField === "answerAr" ? "ql-editor-rtl" : ""
                                )}
                                modules={{
                                    toolbar: [
                                        [{header: [2, 3, 4, false]}],
                                        ["bold", "italic", "underline"],
                                        ["link"],
                                        [{"list": "ordered"}, {"list": "bullet"}],
                                    ],
                                }}
                                formats={["header", "bold", "italic", "underline", "link", "list",]}
                            />
                        }
                    />
                )}
            />
        </div>
    );
};

export default ContentCard;