import React from "react";
import {breadcrumbItemBlog} from "../../../../routing/Router";
import TheorieGoFrame from "../layout/TheorieGoFrame";
import BlogOverview from "../../../widgets/blog/overview/BlogOverview";

const Blog = () => {
    return (
        <TheorieGoFrame title={breadcrumbItemBlog.name}>
            <BlogOverview/>
        </TheorieGoFrame>
    );
};

export default Blog;