import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useSearchParams} from "react-router-dom";
import SetPasswordField from "./fields/SetPasswordField";
import SetPasswordRepeatedField from "./fields/SetPasswordRepeatedField";
import {AuthButton, Form, useAuth} from "peggirkit";
import {confirmPasswordReset} from "../../../data/ApiEndpoints";
import {useIntl} from "react-intl";

export type FormValues = {
    password: string,
    passwordRepeated: string
};

const AuthFormResetPasswordConfirmation = () => {
    const intl = useIntl();
    const [searchParams] = useSearchParams();
    const {register, getValues, handleSubmit, watch, formState: {errors}} = useForm<FormValues>();
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);
    const authContext = useAuth();

    useEffect(() => {
        if (!loading) {
            return;
        }

        const emailAddress = searchParams.get("emailAddress");
        const token = searchParams.get("token");
        if (emailAddress === null || token === null) {
            setFormError(intl.formatMessage({id: "couldNotResetPassword"}));
            setLoading(false);
            return;
        }

        const submittedData = getValues();
        confirmPasswordReset(
            emailAddress,
            token,
            submittedData.password
        ).then(() => {
            authContext?.onSignIn(emailAddress, submittedData.password).catch(() => {
                setLoading(false);
                setFormError(intl.formatMessage({id: "errorSigningInAfterReset"}));
            });
        }).catch(() => {
            setFormError(intl.formatMessage({id: "couldNotResetPassword"}));
            setLoading(false);
        });
    }, [loading]);

    return (
        <Form
            onSubmit={handleSubmit(() => setLoading(true))}
            error={formError}
        >
            <SetPasswordField
                watch={watch}
                register={register}
                disabled={loading}
                fieldError={errors.password}
                fieldName={"password"}
                displayName={intl.formatMessage({id: "password"})}
            />

            <SetPasswordRepeatedField
                compareValue={watch("password")}
                register={register}
                disabled={loading}
                fieldError={errors.passwordRepeated}
            />

            <AuthButton
                disabled={loading}
                loading={loading}
                text={intl.formatMessage({id: "save"})}
            />
        </Form>
    );
};

export default AuthFormResetPasswordConfirmation;