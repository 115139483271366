import React from "react";
import {classNames} from "peggirkit";
import {SelectedAnswerType} from "./AnswerType";

type Props = {
    forAnswer: SelectedAnswerType,
    currentAnswer: SelectedAnswerType,
    children: JSX.Element
};

const AnswerWrapper = ({forAnswer, currentAnswer, children}: Props) => {
    return (
        <div
            className={classNames(
                "col-span-6 lg:col-span-4",
                forAnswer !== currentAnswer ? "hidden" : ""
            )}
        >
            {children}
        </div>
    );
};

export default AnswerWrapper;