import React, {useEffect, useState} from "react";
import {BreadcrumbsContent, PageHeading} from "peggirkit";
import {useIntl} from "react-intl";
import {breadcrumbItemDashboard, breadcrumbItemFaq} from "../../../../routing/Router";
import {listFaqSections} from "../../../../data/ApiEndpoints";
import {FaqSection} from "../../../../data/Entities";
import FaqSectionDetails from "./FaqSectionDetails";

const FaqSectionsOverview = () => {
    const intl = useIntl();
    const [sections, setSections] = useState<FaqSection[]>([]);
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemFaq]
    };

    // Get sections data
    useEffect(() => {
        listFaqSections().then(s => setSections(s));
    }, []);

    return (
        <>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={breadcrumbItemFaq.name}
            />

            <div className={"flex flex-col gap-24 mt-8 pb-24"}>
                {sections.map((s, i) => <FaqSectionDetails key={i} {...s}/>)}
            </div>
        </>
    );
};

export default FaqSectionsOverview;