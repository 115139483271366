import React from "react";
import {Alert} from "peggirkit";
import {useIntl} from "react-intl";

type Props = {
    isDemo: boolean
};

const DemoAlert = ({isDemo}: Props) => {
    const intl = useIntl();
    if (!isDemo) {
        return <></>;
    }

    return (
        <div className="col-span-6 lg:col-span-4">
            <Alert
                type={"info"}
                title={intl.formatMessage({id: "demoExam"})}
                description={intl.formatMessage({id: "demoExamExplanation"})}
            />
        </div>
    );
};

export default DemoAlert;