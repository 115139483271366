import React, {useMemo, useState} from "react";
import {
    capitalizeFirstLetter,
    DataTable,
    DataTableColumn, Icon,
    NotificationService,
    TableButton,
    TableColumn,
    TableData
} from "peggirkit";
import {theorieGoAxios} from "../../../../data/ApiEndpoints";
import {TrashIcon} from "@heroicons/react/24/outline";
import ActiveIndicationBadge from "./ActiveIndicationBadge";
import {Student, StudentStatus} from "../../../../data/Entities";
import router from "../../../../routing/Router";
import DeleteStudentsModal from "../student/DeleteStudentsModal";
import {useIntl} from "react-intl";

type Props = {
    studentStatusFilter: StudentStatus
};

type DeleteStudentsOptions = {
    showDialog: boolean,
    selectedStudentsToDelete: Student[]
};

const dataTableBaseUrl = (studentStatusFilter: StudentStatus, search: boolean): string => {
    const dataUrl = new URL(`${process.env.BACKEND_API_BASE_URL}/admin/students${search ? "/search" : ""}`);
    dataUrl.searchParams.set("status", studentStatusFilter);
    return dataUrl.toString();
}

const StudentsDataTable = ({studentStatusFilter}: Props) => {
    const intl = useIntl();
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const [deleteStudentsOptions, setDeleteStudentsOptions] = useState<DeleteStudentsOptions>({
        showDialog: false,
        selectedStudentsToDelete: [],
    });

    const columns: (TableColumn & DataTableColumn)[] = useMemo(() => {
        return [
            {
                dataName: "id",
                name: intl.formatMessage({id: "id"}),
                sortable: true,
                secondary: true
            },
            {
                dataName: "name",
                name: intl.formatMessage({id: "name"}),
                sortable: true,
                secondary: false,
                formatter: (name: string) => capitalizeFirstLetter(name)
            },
            {
                dataName: "emailAddress",
                name: intl.formatMessage({id: "emailAddress"}),
                sortable: true,
                secondary: true,
                formatter: (name: string) => capitalizeFirstLetter(name)
            },
            {
                dataName: "code",
                name: intl.formatMessage({id: "code"}),
                sortable: true,
                secondary: true
            },
            {
                dataName: "expirationDate",
                name: intl.formatMessage({id: "active"}),
                sortable: false,
                secondary: true,
                formatter: (expirationDate: string) => <ActiveIndicationBadge expirationDate={expirationDate}/>
            },
            {
                dataName: "creationDate",
                name: intl.formatMessage({id: "registeredOn"}),
                sortable: true,
                secondary: true,
                sorted: "desc",
                formatter: (creationDate: string) => new Date(creationDate).toLocaleString()
            },
            {
                hideLabel: true,
                name: intl.formatMessage({id: "options"}),
                sortable: false,
                alignRight: true,
                secondary: true,
                formatter: (_, student): JSX.Element => (
                    <TableButton
                        text={intl.formatMessage({id: "view"})}
                        href={router.student.absolutePath(student.id)}
                    />
                )
            }
        ]
    }, []);

    return (
        <>
            <DataTable
                initialLastUpdated={lastUpdated}
                withUrlPageParameter={true}
                scrollToTopOnPageChange={true}
                initialPageRequest={{
                    axios: theorieGoAxios,
                    baseUrl: dataTableBaseUrl(studentStatusFilter, false),
                    pageSize: 20,
                    pageNumber: 0,
                    search: {
                        baseUrl: dataTableBaseUrl(studentStatusFilter, true),
                        urlParameter: "query",
                        messages: {
                            search: intl.formatMessage({id: "search"}),
                            placeholder: intl.formatMessage({id: "emailNameOrCode"}),
                            clear: intl.formatMessage({id: "clear"})
                        }
                    }
                }}
                columns={columns}
                summaryFormatter={(from: number, to: number, total: number): JSX.Element | string => (
                    intl.formatMessage({id: "paginationSummary"}, {from: from, to: to, total: total})
                )}
                messages={{
                    next: intl.formatMessage({id: "next"}),
                    previous: intl.formatMessage({id: "previous"})
                }}
                batchActions={{
                    dropdownButtonText: (itemsSelected: number) => intl.formatMessage({id: "nStudentsSelected"}, {n: itemsSelected}),
                    toggleSelectAllText: intl.formatMessage({id: "selectAll"}),
                    selectRowText: intl.formatMessage({id: "selectRow"}),
                    actions: [
                        [
                            {
                                type: "danger",
                                icon: TrashIcon as Icon,
                                text: intl.formatMessage({id: "delete"}),
                                disabled: false,
                                onClick: (rawData: TableData[]) => {
                                    setDeleteStudentsOptions({
                                        ...deleteStudentsOptions,
                                        selectedStudentsToDelete: rawData as Student[],
                                        showDialog: true
                                    });
                                }
                            }
                        ]
                    ]
                }}
                alwaysShowToolbar={true}
            />

            <DeleteStudentsModal
                show={deleteStudentsOptions.showDialog}
                setShow={(show: boolean) => setDeleteStudentsOptions({...deleteStudentsOptions, showDialog: show})}
                students={deleteStudentsOptions.selectedStudentsToDelete}
                cb={() => {
                    setLastUpdated(new Date());
                    NotificationService.notify({
                        type: "success",
                        text: intl.formatMessage(
                            {id: "nStudentsDeleted"},
                            {n: deleteStudentsOptions.selectedStudentsToDelete.length})
                    });
                    setDeleteStudentsOptions({
                        ...deleteStudentsOptions,
                        showDialog: false,
                        selectedStudentsToDelete: []
                    });
                }}
            />
        </>
    );
};

export default StudentsDataTable;