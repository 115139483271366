import React from "react";
import {FieldError, UseFormRegister, UseFormWatch} from "react-hook-form";
import {InputField, InputPasswordStrengthBar} from "peggirkit";
import {useIntl} from "react-intl";

type Props = {
    fieldName: string,
    displayName: string,
    watch: UseFormWatch<any>,
    register: UseFormRegister<any>,
    disabled: boolean,
    fieldError?: FieldError,
};

const SetPasswordField = ({watch, register, disabled, fieldError, fieldName, displayName}: Props) => {
    const intl = useIntl();

    return (
        <InputField
            id={fieldName}
            displayName={displayName}
            type={"password"}
            placeholder="••••••••••••••••••"
            disabled={disabled}
            error={fieldError && fieldError.message}
            reactHookForm={{
                ...register(fieldName, {
                    required: intl.formatMessage({id: "enterAPassword"}),
                    minLength: {
                        value: 8,
                        message: intl.formatMessage({id: "mustBeMinCharacters"}, {min: 8})
                    },
                    maxLength: {
                        value: 128,
                        message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 128})
                    }
                })
            }}
            after={<InputPasswordStrengthBar
                password={watch(fieldName)}
                minLength={8}
                scoreWords={{
                    bad: intl.formatMessage({id: "weak"}),
                    weak: intl.formatMessage({id: "weak"}),
                    ok: intl.formatMessage({id: "ok"}),
                    good: intl.formatMessage({id: "good"}),
                    strong: intl.formatMessage({id: "strong"})
                }}
                shortScoreWord={intl.formatMessage({id: "mustBeMinCharacters"}, {min: 8})}
            />}
        />
    );
};

export default SetPasswordField;