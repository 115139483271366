import React, {useEffect, useState} from "react";
import {AlertModal, AlertText} from "peggirkit";
import {FormattedMessage, useIntl} from "react-intl";
import {deleteFaqQuestions} from "../../../../data/ApiEndpoints";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void,
    questions: number[],
    cb: () => void
};

const DeleteContactFormsModal = ({show, setShow, questions, cb}: Props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        deleteFaqQuestions(questions).then(() => {
            setLoading(false);
            cb();
        });
    }, [loading]);

    return (
        <AlertModal
            type={"danger"}
            loading={loading}
            show={show}
            onClose={() => setShow(false)}
            title={
                intl.formatMessage(
                    {id: questions.length === 1 ? "deleteFaqQuestionTitle" : "deleteFaqQuestionsTitle"},
                    {n: questions.length})
            }
            cancelText={intl.formatMessage({id: "cancel"})}
            onContinue={() => setLoading(true)}
            continueText={intl.formatMessage({id: "continue"})}
        >
            <AlertText>
                <FormattedMessage
                    id={questions.length === 1
                        ? "confirmDeleteFaqQuestion.single"
                        : "confirmDeleteFaqQuestion.multiple"}
                    values={{n: questions.length}}
                />
            </AlertText>
        </AlertModal>
    );
};

export default DeleteContactFormsModal;