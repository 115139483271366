import React, {useEffect, useState} from "react";
import {AlertModal, AlertText} from "peggirkit";
import {FormattedMessage, useIntl} from "react-intl";
import {ExamCategory} from "../../../data/Entities";
import {deleteCategories} from "../../../data/ApiEndpoints";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void,
    categories: ExamCategory[],
    cb: () => void
};

const DeleteCategoriesModal = ({show, setShow, categories, cb}: Props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        deleteCategories(categories.map(c => c.id || -1)).then(() => {
            setLoading(false);
            cb();
        });
    }, [loading]);

    return (
        <AlertModal
            type={"danger"}
            loading={loading}
            show={show}
            onClose={() => setShow(false)}
            title={
                intl.formatMessage(
                    {id: categories.length === 1 ? "deleteCategoryTitle" : "deleteCategoriesTitle"},
                    {nCategories: categories.length})
            }
            cancelText={intl.formatMessage({id: "cancel"})}
            onContinue={() => setLoading(true)}
            continueText={intl.formatMessage({id: "delete"})}
        >
            <AlertText>
                <FormattedMessage
                    id={categories.length === 1 ? "confirmDeleteCategory.single" : "confirmDeleteCategory.multiple"}
                    values={{nCategories: categories.length}}
                />
            </AlertText>
        </AlertModal>
    );
};

export default DeleteCategoriesModal;