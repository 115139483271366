import React, {useState} from "react";
import {ExamCategory} from "../../../../data/Entities";
import {AlertModal, AlertText, Table, TableButton} from "peggirkit";
import {FormattedMessage, useIntl} from "react-intl";

type Props = {
    category: ExamCategory
};

const RequirementsFormatter = ({category}: Props) => {
    const [show, setShow] = useState(false);
    const intl = useIntl();
    return (
        <>
            <TableButton
                text={intl.formatMessage({id: "view"})}
                href={"#"}
                onClick={e => {
                    e.preventDefault();
                    setShow(true);
                }}
            />

            <AlertModal
                type={"info"}
                loading={false}
                show={show}
                onClose={() => setShow(false)}
                title={intl.formatMessage({id: "examRequirements"})}
                cancelText={intl.formatMessage({id: "close"})}
            >
                <AlertText>
                    <FormattedMessage id={"categoryRequiredInExamExplanation"}/>
                </AlertText>
                <Table
                    className={"mt-6"}
                    columns={[
                        {
                            name: intl.formatMessage({id: "hazardRecognition"}),
                            sortable: false,
                            secondary: true,
                        },
                        {
                            name: intl.formatMessage({id: "knowledge"}),
                            sortable: false,
                            secondary: true,
                        },
                        {
                            name: intl.formatMessage({id: "insight"}),
                            sortable: false,
                            secondary: true,
                        }
                    ]}
                    data={[{
                        [intl.formatMessage({id: "hazardRecognition"})]: category.requiredInHazardRecognitionSection,
                        [intl.formatMessage({id: "knowledge"})]: category.requiredInKnowledgeSection,
                        [intl.formatMessage({id: "insight"})]: category.requiredInInsightSection
                    }]}
                />
            </AlertModal>
        </>
    );
};

export default RequirementsFormatter;