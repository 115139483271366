import React, {useMemo, useRef} from "react";
import {AdjustmentsHorizontalIcon} from "@heroicons/react/24/outline";
import {Card, Icon, InputField} from "peggirkit";
import {useIntl} from "react-intl";
import "react-quill/dist/quill.snow.css";
import "../../../quill/quill.scss";
import {EditBlogPostProps} from "../BlogPostEditor";
import {Controller} from "react-hook-form";
import Field from "peggirkit/dist/components/Input/Field/Field";
import ReactQuill, {Quill} from "react-quill";
// @ts-ignore
import ImageResize from "quill-image-resize-module-react";
import ImageUploader from "../../../assets/ImageUploader";

Quill.register('modules/imageResize', ImageResize);

type Props = {
    setMetadataSliderOpen: React.Dispatch<React.SetStateAction<boolean>>,
};

const ContentCard = ({
                         setMetadataSliderOpen,
                         loading,
                         control,
                         register,
                         errors,
                         watch
                     }: Props & EditBlogPostProps) => {
    const intl = useIntl();
    const quillEditorRef = useRef<ReactQuill>(null);
    const imageUploaderRef = useRef<HTMLInputElement | null>(null);
    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{header: [2, 3, 4, false]}],
                ["bold", "italic", "underline"],
                ["link"],
                [{"list": "ordered"}, {"list": "bullet"}],
                ["image"],
            ],
            handlers: {
                image: () => imageUploaderRef.current && imageUploaderRef.current.click()
            },
        },
        imageResize: {
            parchment: Quill.import("parchment"),
            modules: ["Resize", "DisplaySize"]
        }
    }), []);

    return (
        <Card
            header={{
                title: intl.formatMessage({id: "content"}),
                action: {
                    className: "xl:hidden",
                    type: "white",
                    text: intl.formatMessage({id: "metadata"}),
                    icon: AdjustmentsHorizontalIcon as Icon,
                    onClick: () => setMetadataSliderOpen(true)
                },
            }}
        >
            <div className={"flex flex-col gap-6"}>
                <InputField
                    type={"text"}
                    id={"title"}
                    reactHookForm={register("title", {
                        required: intl.formatMessage({id: "mustEnterField"}),
                        maxLength: {
                            value: 512,
                            message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 512}),
                        },
                    })}
                    error={errors["title"] && errors["title"]?.message}
                    displayName={intl.formatMessage({id: "title"})}
                    tip={intl.formatMessage({id: "nOutOfTCharacters"}, {n: watch("title")?.length || 0, t: 512})}
                    disabled={loading}
                />

                <Controller
                    name={"content"}
                    control={control}
                    rules={{required: intl.formatMessage({id: "mustEnterField"})}}
                    render={({field: {onChange, value}}) => (
                        <Field
                            id={"content"}
                            displayName={intl.formatMessage({id: "content"})}
                            error={errors["content"] && errors["content"]?.message}
                            inputEl={
                                <ReactQuill
                                    ref={quillEditorRef}
                                    readOnly={loading}
                                    theme="snow"
                                    value={value}
                                    onChange={onChange}
                                    className={"group shadow-sm"}
                                    modules={modules}
                                    formats={[
                                        "header", "bold", "italic", "underline", "link", "list", "image", "alt",
                                        "height", "width", "style", "size"
                                    ]}
                                />
                            }
                            tip={intl.formatMessage({id: "blogContentTip"})}
                        />
                    )}
                />

                <ImageUploader
                    assetDestination={"BLOG"}
                    afterUpload={(img) => {
                        if (!quillEditorRef.current) {
                            return;
                        }

                        const editor = quillEditorRef.current.getEditor();
                        const range = editor.getSelection()
                        const value = `${process.env.ASSETS_BASE_URL}/blog/${img}`;

                        if (range) {
                            editor.insertEmbed(range.index, "image", value, "user");
                        }
                    }}
                    uploaderRef={imageUploaderRef}
                />
            </div>
        </Card>
    );
};

export default ContentCard;