const messages = {
    "dashboard": "Dashboard",
    "database": "Database",
    "account": "Account",
    "students": "Studenten",
    "studentWithNumber": "Student {studentId}",
    "signOut": "Uitloggen",
    "homepage": "Homepagina",
    "drivingSchoolEasyGo": "Autorijschool Easy Go",
    "appName": "TheorieGO Admin",
    "external": "Extern",
    "back": "Terug",
    "pickATab": "Kies een tab",
    "general": "Algemeen",
    "security": "Beveiliging",
    "signedInAs": "Ingelogd als: {user}",
    "error": "Error",
    "resetPassword": "Reset wachtwoord",
    "backToSignIn": "Terug naar inloggen?",
    "signIn": "Inloggen",
    "setPassword": "Wachtwoord instellen",
    "resetLinkExpired": "Resetlink verlopen?",
    "requestResetAgain": "Reset opnieuw aanvragen",
    "generalAccountDetails": "Algemene accountgegevens.",
    "emailAddress": "E-mailadres",
    "securitySettingsAccount": "Beveiligingsinstellingen voor jouw account.",
    "password": "Wachtwoord",
    "update": "Updaten",
    "passwordUpdated": "Wachtwoord bijgewerkt.",
    "incorrectCurrentPassword": "Onjuiste huidige wachtwoord.",
    "errorUpdatingPassword": "Fout tijdens bijwerken wachtwoord.",
    "updatePassword": "Update wachtwoord",
    "cancel": "Annuleren",
    "save": "Opslaan",
    "enterFieldsToUpdatePassword": "Voer de velden hieronder in om jouw wachtwoord bij te werken.",
    "enterFieldsToUpdateCategory": "Voer de velden hieronder in om de categorie bij te werken.",
    "currentPassword": "Huidig wachtwoord",
    "newPassword": "Nieuw wachtwoord",
    "enterAPassword": "Voer een wachtwoord in.",
    "enterYourPassword": "Voer jouw wachtwoord in.",
    "enterAnEmailAddress": "Voer een e-mailadres in.",
    "mustBeMinCharacters": "Moet min. {min} karakter(s) bevatten.",
    "mustBeMaxCharacters": "Kan max. {max} karakter(s) bevatten.",
    "weak": "Zwak",
    "ok": "Ok",
    "good": "Goed",
    "strong": "Sterk",
    "repeatPassword": "Herhaal wachtwoord",
    "repeatYourPassword": "Herhaal je wachtwoord.",
    "passwordsMustMatch": "Wachtwoorden moeten gelijk zijn.",
    "goToHomePage": "Naar homepagina gaan?",
    "passwordResetRequestedCheckInbox": "Wachtwoord reset aangevraagd. Check je inbox om verder te gaan.",
    "errorRequestingReset": "Fout bij wachtwoordreset aanvraag.",
    "emailAddressPlaceholder": "Voorbeeld@theoriego.nl",
    "emailAddressOfExistingAccount": "E-mailadres van bestaand account.",
    "requestReset": "Reset aanvragen",
    "couldNotResetPassword": "Kon wachtwoord niet resetten.",
    "errorSigningInAfterReset": "Fout tijdens inloggen na reset.",
    "invalidEmailOrPassword": "Onjuiste e-mailadres en/of wachtwoord.",
    "forgotPassword": "Wachtwoord vergeten?",
    "adminDatabaseOperation": "Admin operaties m.b.t. de database.",
    "indexing": "Indexeren",
    "indexDbDescription": "Indexeer database data voor interne zoekfuncties.",
    "startIndexing": "Indexeer",
    "searchDataReIndexed.success": "Zoekdata in database geherindexeerd.",
    "searchDataReIndexed.error": "Fout bij herindexeren zoekdata in database.",
    "indexSearchData": "Indexeer zoekdata",
    "continue": "Doorgaan",
    "confirmStartIndexingDatabase": "Weet je zeker dat je door wilt gaan? Klik op \"Doorgaan\" om het her-indexeren te starten.",
    "unactivated": "Ongeactiveerd",
    "activated": "Geactiveerd",
    "expired": "Verlopen",
    "active": "Actief",
    "expirationDateOn": "Verloopdatum: {date}",
    "id": "ID",
    "name": "Naam",
    "code": "Code",
    "registeredOn": "Geregistreerd op",
    "options": "Opties",
    "view": "Bekijken",
    "search": "Zoeken",
    "emailNameOrCode": "E-mail, naam of code...",
    "clear": "Wissen",
    "paginationSummary": "Toont {from} tot {to} van {total}",
    "next": "Volgende",
    "previous": "Vorige",
    "nStudentsSelected": "{n} student(en)",
    "nFormsSelected": "{n} formulier(en)",
    "nItemsSelected": "{n} item(s)",
    "selectAll": "Selecteer alles",
    "selectRow": "Selecteer rij",
    "delete": "Verwijderen",
    "nStudentsDeleted": "{n} student(en) verwijderd.",
    "nBlogPostsDeleted": "{n} blogpost(s) verwijderd.",
    "nContactFormsDeleted": "{n} contactformulier(en) verwijderd.",
    "nQuestionsDeleted": "{n} vraag/vragen verwijderd.",
    "nExamsDeleted": "{n} examen(s) verwijderd.",
    "nCategoriesDeleted": "{n} categorie(ën) verwijderd.",
    "mailing": "Mailing",
    "studentMailingDescription": "Standaard e-mails die naar studenten worden verzonden.",
    "welcomeMail": "Welkomsmail",
    "welcomeMailDescription": "Handmatig versturen van de welkomsmail met inlogcode. Dient gebruikt te worden na het handmatig toevoegen van een student.",
    "send": "Verzenden",
    "expirationReminderMail": "Herinneringsmail",
    "expirationReminderMail.description": "Automatische mail herinnering verloopdatum.",
    "notYetSent": "Nog niet verzonden. Wordt ongeveer één uur voor de verloopdatum verstuurd.",
    "sentOnDate": "Verzonden op: {date}",
    "welcomeMailSent": "Welkomsmail verzonden.",
    "sendWelcomeMail": "Welkomsmail versturen",
    "confirmSendWelcomeMail": "Weet je zeker dat je de welkomsmail wilt verzenden? Dit hoef je alleen te doen als je de student handmatig hebt aangemaakt.",
    "permanentlyDeleteStudent": "Student permanent verwijderen.",
    "arabic": "Arabisch",
    "dutch": "Nederlands",
    "studentUpdated": "Student bijgewerkt.",
    "operationError": "Fout tijdens operatie.",
    "generalPersonData": "Algemene persoongegevens.",
    "internalIdReferencePurposes": "Interne ID voor referentie doeleinden.",
    "codeToSignInWith": "Code die de student gebruikt om mee in te loggen.",
    "mustEnterField": "Voer verplicht veld in.",
    "mustSelectImage": "Kies een afbeelding.",
    "mustPickOption": "Kies een optie.",
    "preferredLanguage": "Voorkeurstaal",
    "languageEmailsAreSentIn": "De taal waarin e-mails verstuurd zullen worden.",
    "addOption": "Toevoegen: {option}",
    "pickALanguage": "Kies een taal...",
    "activeState": "Actief-status",
    "activeState.description": "Gegevens m.b.t. de actief-status van de student.",
    "activation": "Activatie",
    "activation.description": "Voor studenten die ongeactiveerd zijn, wordt de verloopdatum pas gezet nadat de student voor de eerste keer inlogt. De verloopdatum is dan de datum & tijd op dat moment + de waarde van \"geldigheidsduur\" (minuten). Accounts die gekocht worden via de website zijn altijd direct actief, de verloopdatum is dan nooit leeg. Ongeactiveerde accounts worden alleen voor speciale momenten aangemaakt (cadeaubonnen, promoties, etc.).",
    "activatedDuration": "Geldigheidsduur",
    "activatedDuration.description": "Het aantal minuten dat het studentenaccount actief is nadat er voor het eerst wordt ingelogd.",
    "expirationDate": "Verloopdatum",
    "expirationDate.description": "Na deze datum kan de student niet meer inloggen. Veld leeglaten en alléén geldigheidsduur invoeren als het account (nog) niet actief moet zijn. Deze datum wordt dan na de eerste inlog automatisch berekend.",
    "chooseDate": "Kies datum",
    "backToStudents": "Terug naar studenten",
    "saveChanges": "Wijzigingen opslaan",
    "time": "Tijdstip",
    "hour": "Uur",
    "minute": "Minuut",
    "details": "Gegevens",
    "viewOrEditDetails": "Bekijk of wijzig de studentengegevens.",
    "confirmDeleteStudent.single": "Weet je zeker dat je deze student permanent wilt verwijderen?",
    "confirmDeleteStudent.multiple": "Weet je zeker dat je deze {nStudents} studenten permanent wilt verwijderen?",
    "confirmDeleteBlogPost.single": "Weet je zeker dat je deze blogpost permanent wilt verwijderen?",
    "confirmDeleteBlogPost.multiple": "Weet je zeker dat je deze {n} blogposts permanent wilt verwijderen?",
    "confirmDeleteContactForms.single": "Weet je zeker dat je deze contactformulier permanent wilt verwijderen?",
    "confirmDeleteContactForms.multiple": "Weet je zeker dat je deze {nForms} contactformulieren permanent wilt verwijderen?",
    "confirmDeleteCategory.single": "Weet je zeker dat je deze categorie permanent wilt verwijderen? Dit kan effect hebben op examens.",
    "confirmDeleteCategory.multiple": "Weet je zeker dat je deze {nCategories} categorieën permanent wilt verwijderen? Dit kan effect hebben op examens.",
    "confirmDeleteFaqQuestion.single": "Weet je zeker dat je deze FAQ-vraag permanent wilt verwijderen?",
    "confirmDeleteFaqQuestion.multiple": "Weet je zeker dat je deze {n} FAQ-vragen permanent wilt verwijderen?",
    "deleteStudentTitle": "Student verwijderen",
    "deleteStudentsTitle": "{nStudents} studenten verwijderen",
    "deleteBlogPostTitle": "Blogpost verwijderen",
    "deleteBlogPostsTitle": "{n} blogposts verwijderen",
    "deleteContactFormTitle": "Contactformulier verwijderen",
    "deleteContactFormsTitle": "{nForms} contactformulieren verwijderen",
    "deleteCategoryTitle": "Categorie verwijderen",
    "deleteCategoriesTitle": "{nCategories} categorieën verwijderen",
    "deleteFaqQuestionTitle": "FAQ-vraag verwijderen",
    "deleteFaqQuestionsTitle": "{n} FAQ-vragen verwijderen",
    "actions": "Acties",
    "allStudents": "Alle studenten",
    "more": "Meer",
    "newStudent": "Nieuwe student",
    "categories": "Categorieën",
    "nameNl": "Naam (NL)",
    "nameAr": "Naam (AR)",
    "edit": "Bewerken",
    "examCategories": "Examen categorieën",
    "examCategories.explanation": "Alle examenvragen vallen onder een categorie. Iedere categorie heeft zelf weer drie onderdelen, namelijk: gevaarherkenning, kennis en inzicht. Let op: na het verwijderen van een categorie of het wijzigen van een categoriecode worden de willekeurige examens mogelijk niet meer correct gegenereerd. Nieuwe categorieën worden niet automatisch aan willekeurige examens toegevoegd.",
    "add": "Toevoegen",
    "updatedCategories": "Categorieën geupdatet.",
    "editCategory": "Categorie bewerken",
    "categorySaved": "Categorie opgeslagen.",
    "examQuestions": "Examenvragen",
    "questionWithId": "Examenvraag {questionId}",
    "faqQuestionWithId": "FAQ-vraag {id}",
    "newQuestion": "Nieuwe examenvraag",
    "newFaqQuestion": "Nieuwe FAQ-vraag",
    "saveAndNew": "Opslaan en nieuw",
    "backToOverview": "Terug naar overzicht",
    "public": "Publiek",
    "concept": "Concept",
    "editExamQuestion.section.general.description": "Onder de algemene vraaggegevens vallen de categorieën, secties en live status. Van examens die live (publiek) zijn kun je de status niet wijzigen, omdat publieke examens dan mogelijk niet meer voldoen aan de CBR regels. Wil je dus een publieke vraag omzetten naar concept of verwijderd, wijzig dan eerst de examens zodat de vraag wordt vervangen met een andere.",
    "editExamQuestion.section.questionOrStatement.description": "De vraag of stelling die de student moet beantwoorden tijdens een examen.",
    "editExamQuestion.section.answerAndExplanation.description": "Hier geef je aan wat voor type vraag dit is (meerkeuze, sleepvraag, etc.). Vervolgens geef je de opties en het correcte antwoord aan. Onder toelichting geef je een uitleg van de vraag en antwoord (dit wordt aan de student getoond op de resultatenpagina).",
    "editExamQuestion.section.image.description": "Heb je gekozen voor een sleepvraag of multiple-choice vraag mét afbeeldingen, dan wordt deze afbeelding alleen getoond op de resultatenpagina. Kies je géén afbeelding, dan wordt de TheorieGO placeholder afbeelding gebruikt.",
    "editExamQuestion.section.delete.description": "Examenvraag permanent verwijderen. Let op: deze operatie is onomkeerbaar. Vragen kunnen alleen worden verwijderd  als deze niet in examens zijn.",
    "status": "Status",
    "pickAStatus": "Kies een status...",
    "category": "Categorie",
    "pickACategory": "Kies een categorie...",
    "sections": "Sectie(s)",
    "hazardRecognition": "Gevaarherkenning",
    "knowledge": "Kennis",
    "insight": "Inzicht",
    "hazardRecognitionCount": "Gevaarherkenning: {count}",
    "knowledgeCount": "Kennis: {count}",
    "insightCount": "Inzicht: {count}",
    "hazardRecognition.shortened": "GH",
    "knowledge.shortened": "K",
    "insight.shortened": "I",
    "questionOrStatement": "Vraag of stelling",
    "answerAndExplanation": "Antwoord en toelichting",
    "answerType": "Antwoordtype",
    "pickAnAnswerType": "Kies een antwoordtype...",
    "pickAnAnswer": "Kies een antwoord...",
    "yesNo": "Ja/nee",
    "number": "Getal",
    "dragDropQuestion": "Sleepvraag",
    "dragDropQuestionImage": "Sleepvraagafbeelding",
    "dutchExplanation": "Nederlandse toelichting",
    "arabicExplanation": "Arabische toelichting",
    "dutchAnswer": "Nederlands antwoord",
    "arabicAnswer": "Arabisch antwoord",
    "image": "Afbeelding",
    "upload": "Uploaden",
    "questionImageAspectRatioTip": "Verhouding: 938x639",
    "numberAsAnswer": "Getal als antwoord",
    "yesNoAnswer": "Ja/nee-antwoord",
    "yes": "Ja",
    "no": "Nee",
    "withImages": "Met afbeeldingen",
    "correct": "Correct",
    "new": "Nieuw",
    "noMultipleChoiceAnswerSpecified": "Er is geen multiple choice antwoord gespecificeerd.",
    "noDragDropAnswerSpecified": "Er is geen sleepvraagantwoord gespecificeerd.",
    "needsTwoMultipleChoiceAnswers": "Er moeten minstens twee multiple choice antwoorden zijn.",
    "needsTwoDragDropOptions": "Er moeten minstens twee sleepopties zijn.",
    "oneMultChoiceAnswersNeedToBeCorrect": "Er moet precies één multiple choice antwoord correct zijn.",
    "mustBeAtLeastOneCorrectOption": "Er moet minstens één juiste optie zijn.",
    "cannotBeWithImagesAndMultResponse": "Antwoord kan niet zoal met afbeeldingen als multiple response zijn.",
    "multipleChoiceOptions": "Multiple-choice opties",
    "multipleChoice": "Multiple-choice",
    "multipleChoiceWithImages": "Multiple-choice met afbeeldingen",
    "hotspot": "Hotspot",
    "answerOption": "Antwoordoptie",
    "usePreset": "Preset toepassen",
    "composeDragDropQuestion": "Sleepvraag samenstellen",
    "composeDragDropQuestion.explanation": "Voeg nieuwe antwoordopties toe. De student moet kiezen waar welke antwoordoptie hoort. Als je de student slechts één optie wilt kunnen laten slepen naar 3 mogelijke posities, voeg dan 3 opties toe en vink de sleepbaarheid van 2 opties uit.",
    "draggable": "Sleepbaar",
    "copyToQuestionImage": "Kopieer naar vraagafbeelding",
    "oneDragDropOptionNeedsToBeDraggable": "Er moet minstens één sleepvraag-optie sleepbaar zijn.",
    "dragDropOptionNumbersMustBeUnique": "Sleepvraag-optie nummers moeten uniek zijn.",
    "removeImage": "Afbeelding weghalen",
    "inExams": "In examens",
    "conceptQuestionExplanationTip": "Alléén publieke vragen worden gebruikt in publieke- en AltijdUniek examens.",
    "none": "Geen",
    "statusCannotBeConceptWhenInPublicExam": "Vraag kan geen concept zijn omdat het in een publieke examen is. Verwijder de vraag uit de examen(s) of markeer de examen(s) als concept om deze vraag een concept te maken.",
    "deleteExamQuestion": "Examenvraag verwijderen",
    "confirm": "Bevestigen",
    "confirmDeleteExamQuestion": "Weet je zeker dat je deze examenvraag wilt verwijderen? Deze operatie is onomkeerbaar.",
    "confirmDeleteExam": "Weet je zeker dat je deze examen wilt verwijderen? Deze operatie is onomkeerbaar.",
    "confirmDeleteExamQuestionsDescription": "Weet je zeker dat je deze {nQuestions} examenvragen wilt verwijderen? Deze operatie is onomkeerbaar.",
    "confirmDeleteExamsDescription": "Weet je zeker dat je deze {nExams} examens wilt verwijderen? Deze operatie is onomkeerbaar.",
    "questionIsStillInExam": "Vraag zit in één of meerdere examen(s).",
    "confirmDeleteExamQuestions": "{nQuestions} examenvragen verwijderen",
    "confirmDeleteExamQuestionTitle": "Examenvraag verwijderen",
    "confirmDeleteExams": "{nQuestions} examens verwijderen",
    "confirmDeleteExamTitle": "Examen verwijderen",
    "questionSaved": "Examenvraag opgeslagen!",
    "anyStatus": "Elke status",
    "question": "Vraag",
    "examsWithQuestion": "{count} examen(s) met vraag {questionId}",
    "total": "Totaal",
    "anySection": "Elke sectie",
    "anyCategory": "Elke categorie",
    "section": "Sectie",
    "noSection": "Géén sectie",
    "cannotDeleteQuestions": "Kan vragen niet verwijderen",
    "cannotDeleteQuestions.explanation": "Kan één of meerdere geselecteerde examenvragen niet verwijderen, omdat ze nog in examens zijn. Haal de desbetreffende vragen eerst uit de examens om ze te verwijderen.",
    "cannotDeleteExams": "Kan examen(s) niet verwijderen",
    "cannotDeleteExams.explanation": "Kan één of meerdere geselecteerde examens niet verwijderen, omdat er een demo tussen zit.",
    "close": "Sluiten",
    "exams": "Examens",
    "exam": "Examen",
    "type": "Type",
    "anyType": "Elke type",
    "demo": "Demo",
    "nonDemo": "Niet-demo",
    "newExam": "Nieuwe examen",
    "addedOn": "Toegevoegd op",
    "examRequirements": "Exameneisen",
    "categoryRequiredInExamExplanation": "Volgens het CBR moet iedere examen-onderdeel de volgende hoeveelheid vragen van deze categorie bevatten:",
    "requiredInHazardRecognitionSection": "Aantal nodig in gevaarherkenningsonderdeel",
    "requiredInKnowledgeSection": "Aantal nodig in kennisonderdeel",
    "requiredInInsightSection": "Aantal nodig in inzichtsonderdeel",
    "applyFilter": "Filter toepassen",
    "thumbnail": "Thumbnail",
    "dutchName": "Nederlandse naam",
    "arabicName": "Arabische naam",
    "examSaved": "Examen opgeslagen.",
    "remove": "Weghalen",
    "pickSection": "Kies sectie",
    "demoExam": "Demo-examen",
    "demoExamExplanation": "Dit is de demo-examen die door gasten gratis op de homepagina kan worden gemaakt. Deze examen heeft geen thumbnail en naam nodig.",
    "examStatusTip": "Zorg ervoor dat alle vereiste vragen in het examen zitten wanneer de status publiek is.",
    "noActions": "Géén acties",
    "noAction": "Géén actie",
    "shuffleOrder": "Shuffle volgorde",
    "noQuestions": "Geen vragen",
    "noQuestions.explanation": "Gebruik de linker tabel om vragen aan deze sectie toe te voegen.",
    "deleteExam": "Examen verwijderen",
    "cannotDeleteDemoExam": "Kan demo-examen niet verwijderen.",
    "deleteExamExplanation": "Examen permanent verwijderen. Let op: deze operatie is onomkeerbaar.",
    "generalExamDetailsExplanation": "Algemene examengegevens.",
    "addDragDropOption": "Sleepoptie toevoegen",
    "multipleResponse": "Multiple response",
    "multipleChoiceExplanation.title": "Meerkeuze, hotspot & multiple response",
    "multipleChoiceExplanation.description": "Onder het type multiple-choice vraag kun je drie type examenvragen maken: de meerkeuzevraag, de hotspotvraag en de multiple-responsevraag. Voor de hotspotvraag dien je \"Met afbeeldingen\" in te schakelen. De antwoordvelden kun je dan leeg laten. Voor de multple-responsevraag dien je \"Multiple response\" in te schakelen. Je kunt dan meerdere correcte antwoorden aanvinken.",
    "preferences": "Voorkeuren",
    "studentPreferencesDescription": "Voorkeuren (/ instellingen) die studenten zelf kunnen beheren.",
    "startExamWithExplanation": "Start examen met uitleg",
    "startExamWithExplanation.description": "Studentenvoorkeur om telkens een algemene examenuitlegscherm wilt zien voordat het examen begint. Student kan deze instelling zelf uitschakelen (d.m.v. \"Niet meer tonen\"-knop).",
    "showExplanationScreen": "Toon uitlegscherm",
    "finances": "Financiën",
    "financesDescription": "Financiële gegevens van de student. Student die handmatig zijn aangemaakt of zijn gekocht via de site van Autorijschool Easy Go, zullen hier geen gegevens hebben.",
    "paymentDetails": "Betaalgegevens",
    "noStudentPaymentDetailsDescription": "Geen betaalgegevens beschikbaar van deze student. Mogelijk is deze student handmatig aangemaakt waardoor er geen betaling (via TheorieGO.nl) is gemaakt. Of de student heeft destijds via de site van Autorijschool Easy Go een pakket gekocht.",
    "invoicedPerson": "Gefactureerde persoon",
    "invoiceEmail": "Gefactureerde e-mail",
    "givenPreferredLanguage": "Opgegeven voorkeurstaal",
    "couldBeChangeInMeanTime": "Kan in de tussentijd zijn veranderd.",
    "descriptionOnInvoice": "Omschrijving op factuur",
    "alsoNameOfPlan": "Tevens naam van het bestelde pakket.",
    "subtotal": "Subtotaal",
    "tax21Percent": "Btw (21%)",
    "notYetPaid": "Nog niet betaald",
    "paymentMethod": "Betaalmethode",
    "mollieTransaction": "Mollie transactie",
    "orderStartedOn": "Bestelling gestart op",
    "paymentReceivedOn": "Betaling ontvangen op",
    "audio": "Audio",
    "editExamQuestion.section.audio.description": "Text-to-speech fragmenten voor examens met geluid. Worden automatisch gedownload voor kennis en inzichtsvragen.",
    "text-to-speech-status": "Text-to-speech status",
    "text-to-speech-status.description.new-question": "Voor nieuwe examenvragen worden text-to-speech fragmenten gecreëerd als het in de kennis en/of inzicht-sectie zit.",
    "text-to-speech-status.description.no-audio": "Deze examenvraag heeft géén audiofragmenten.",
    "text-to-speech-status.description.in-queue": "De audiofragmenten voor deze vraag staan in de wachtrij om gedownload te worden.",
    "dutch-audio": "Nederlandse audio",
    "arabic-audio": "Arabische audio",
    "factuurSturenClientNumber": "FactuurSturen.nl klant",
    "factuurSturenInvoiceNumber": "FactuurSturen.nl factuur",
    "contactForms": "Contactformulieren",
    "contactForm": "Contactformulier",
    "sentOn": "Verzonden op",
    "open": "Open",
    "closed": "Gesloten",
    "all": "Alle",
    "student": "Student",
    "studentCode": "Studentencode",
    "firstName": "Voornaam",
    "lastName": "Achternaam",
    "telephoneNumber": "Telefoonnummer",
    "message": "Bericht",
    "internalNote": "Interne notitie",
    "manuallyEnteredInForm": "Handmatig ingevuld in formulier.",
    "studentSubmittedWasSignedInWith": "Waarmee persoon was ingelogd tijdens het verzenden.",
    "nOutOfTCharacters": "{n}/{t} karakter(s).",
    "savedChanged": "Wijzigingen opgeslagen.",
    "contactFormStatusTip": "Door een formulier te sluiten i.p.v. te verwijderen, kun je oude formulieren bewaren.",
    "faq": "FAQ",
    "isFeatured": "Uitgelicht",
    "updatedOn": "Bijgewerkt op",
    "creationDate": "Aangemaakt op",
    "selectTab": "Selecteer tab.",
    "answer": "Antwoord",
    "metadata": "Metadata",
    "questionAndAnswer": "Vraag en antwoord",
    "slug": "Slug",
    "slugNl": "Slug (NL)",
    "slugAr": "Slug (AR)",
    "description": "Beschrijving",
    "descriptionNl": "Beschrijving (NL)",
    "descriptionAr": "Beschrijving (AR)",
    "order": "Volgorde",
    "orderInSection": "Volgorde binnen sectie",
    "orderInSection.description": "Vragen binnen een sectie worden gesorteerd op nummers van klein naar groot.",
    "orderInFeaturedSection": "Volgorde binnen uitgelichte vragen",
    "orderInFeaturedSection.description": "Als je wilt dat deze vraag wordt weergeven op o.a. de homepagina als uitgelichte vraag, geeft het dan een volgordenummer. Laat veld leeg als het geen uitgelichte vraag is.",
    "cannotSave": "Kan niet opslaan",
    "enterAllRequiredFields": "Voer alle verplichte velden in.",
    "lastNDays": "Laatste {n} dagen",
    "statistics": "Statistieken",
    "revenueInclTax": "Omzet (incl. btw)",
    "numberOfSales": "Aantal sales",
    "revenueAndSales": "Omzet & sales",
    "dateRange": "{from} t/m {to}",
    "dateRangeShort": "{from} - {to}",
    "sales": "Sales",
    "salesStatsDescription": "Statistieken m.b.t. sales via TheorieGO.nl.",
    "period": "Periode",
    "pickARange": "Kies een periode",
    "soldPlans": "Verkochte pakketten",
    "apply": "Toepassen",
    "month": "Maand",
    "quarter": "Kwartaal",
    "year": "Jaar",
    "studentsAndExams": "Studenten & examens",
    "studentsAndExamsStatsDescription": "Statistieken m.b.t. studenten & gemaakte examens.",
    "takenExams": "Afgelegde examens",
    "totalExams": "Totaal examens",
    "passed": "Geslaagd",
    "failed": "Gezakt",
    "notFinished": "Niet afgerond",
    "examsWithAudio": "Examens met audio",
    "examsWithTime": "Examens met tijd",
    "examsWithExtraTime": "Examens met extra tijd",
    "blog": "Blog",
    "title": "Titel",
    "newPost": "Nieuwe post",
    "newBlogPost": "Nieuwe blogpost",
    "blogPostWithNumber": "Blogpost {blogPostId}",
    "content": "Inhoud",
    "planning": "Planning",
    "publishOn": "Publiceer op",
    "blogPublishOnTip": "Kies een toekomstige datum om een post in te plannen en/of privé te houden.",
    "choose": "Kiezen",
    "lastEdited": "Laatst bijgewerkt",
    "seo": "SEO",
    "banner": "Banner",
    "blogContentTip": "Max. breedte van afbeeldingen is 1000px.",
    "published": "Gepubliceerd",
    "scheduled": "Gepland",
    "postingSavedNotification": "Opgeslagen. Het kan 10 min. duren voor wijzigingen zichtbaar zijn.",
    "": "",
};

/*
const intl = useIntl();
intl.formatMessage({id: ""})
<FormattedMessage id={""}/>
 */

export default messages;