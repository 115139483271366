import React from "react";
import {EditFaqQuestionProps} from "../EditFaqQuestionContainer";
import EditSlugCard from "./EditSlugCard";
import EditDescriptionCard from "./EditDescriptionCard";
import EditOrderCard from "./EditOrderCard";

const EditMetadata = (props: EditFaqQuestionProps) => {
    return (
        <div className={"flex flex-col gap-6 pb-12"}>
            <EditSlugCard {...props}/>
            <EditDescriptionCard {...props}/>
            <EditOrderCard {...props}/>
        </div>
    );
};

export default EditMetadata;