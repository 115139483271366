import React from "react";
import {Icon, InputCheckbox, InputField} from "peggirkit";
import {TrashIcon} from "@heroicons/react/24/outline";
import {UseFieldArrayRemove, UseFormRegister} from "react-hook-form";
import {ExamQuestionForm} from "../../../EditQuestionForm";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {useIntl} from "react-intl";

type Props = {
    id: string,
    loading: boolean,
    index: number,
    remove: UseFieldArrayRemove,
    register: UseFormRegister<ExamQuestionForm>,
    errors: FieldErrors<ExamQuestionForm>
};

const Option = ({id, index, register, loading, remove}: Props) => {
    const intl = useIntl();
    return (
        <div
            data-title={"drag-drop-answer-option"}
            className={"flex flex-col gap-2"}
        >
            <InputField
                type={"number"}
                id={id}
                reactHookForm={{
                    ...register(`dragDropAnswer.answers.${index}.number`, {
                        valueAsNumber: true
                    })
                }}
                displayName={intl.formatMessage({id: "answerOption"})}
                disabled={loading}
                trailingButton={{
                    type: "danger",
                    iconOnly: true,
                    icon: TrashIcon as Icon,
                    text: intl.formatMessage({id: "delete"}),
                    disabled: loading,
                    onClick: e => {
                        e.preventDefault();
                        remove(index);
                    }
                }}
            />
            <InputCheckbox
                id={`${id}-chk`}
                disabled={loading}
                displayName={intl.formatMessage({id: "draggable"})}
                reactHookForm={{...register(`dragDropAnswer.answers.${index}.draggableOption`)}}
            />
        </div>
    );
};

export default Option;