import React, {useState} from "react";
import {DayPicker, Icon, InputField} from "peggirkit";
import {useIntl} from "react-intl";
import {EditBlogPostProps} from "../BlogPostEditor";
import EditMetadataCard from "../../../faq/questions/metadata/EditMetadataCard";
import {CalendarIcon} from "@heroicons/react/24/outline";
import dayjs from "dayjs";

const BlogPostSlugCard = ({
                              loading,
                              register,
                              errors,
                              watch,
                              setValue,
                          }: EditBlogPostProps) => {
    const intl = useIntl();
    const [showSelectPublishDay, setShowSelectPublishDay] = useState(false);

    return (
        <EditMetadataCard name={intl.formatMessage({id: "planning"})}>
            <InputField
                type={"text"}
                id={"publishDate"}
                displayName={intl.formatMessage({id: "publishOn"})}
                tip={intl.formatMessage({id: "blogPublishOnTip"})}
                reactHookForm={{
                    ...register("publishDate", {
                        required: intl.formatMessage({id: "mustEnterField"}),
                        maxLength: {
                            value: 256,
                            message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 256}),
                        }
                    })
                }}
                error={errors.publishDate && errors.publishDate.message}
                disabled={loading}
                trailingButton={{
                    type: "gray",
                    text: intl.formatMessage({id: "chooseDate"}),
                    icon: CalendarIcon as Icon,
                    disabled: loading,
                    onClick: (e) => {
                        e.preventDefault();
                        setShowSelectPublishDay(true);
                    }
                }}
            />

            <DayPicker
                defaultSelected={watch("publishDate") ? new Date(watch("publishDate")) : new Date()}
                title={intl.formatMessage({id: "chooseDate"})}
                cancelText={intl.formatMessage({id: "cancel"})}
                continueText={intl.formatMessage({id: "choose"})}
                show={showSelectPublishDay}
                setShow={setShowSelectPublishDay}
                onSelect={(selected) => {
                    if (selected) {
                        setValue("publishDate", dayjs(selected).format("YYYY-MM-DD[T]HH:mm:ss[+0000]"));
                    }
                }}
            />

            <InputField
                type={"text"}
                id={"lastEditedDate"}
                displayName={intl.formatMessage({id: "lastEdited"})}
                disabled={true}
                reactHookForm={{...register("lastEditedDate")}}
            />
        </EditMetadataCard>
    );
};

export default BlogPostSlugCard;