import React from "react";
import {EditQuestionSectionProps} from "../../EditQuestionForm";
import {useIntl} from "react-intl";
import {FormLayoutCard, InputCreatableSelect, InputField} from "peggirkit";
import NumberAnswer from "./number/NumberAnswer";
import AnswerWrapper from "./AnswerWrapper";
import YesNoAnswer from "./yesNo/YesNoAnswer";
import MultipleChoiceAnswer from "./multipleChoice/MultipleChoiceAnswer";
import {SelectedAnswerType, SelectedAnswerTypeOption, selectedAnswerTypeOptions} from "./AnswerType";
import DragDropAnswer from "./dragDrop/DragDropAnswer";

export type AnswerTypeProps = {
    selectedAnswerType: SelectedAnswerType
};

const AnswerSection = ({
                           loading,
                           control,
                           register,
                           errors,
                           watch,
                           setValue
                       }: EditQuestionSectionProps) => {
    const intl = useIntl();
    return (
        <FormLayoutCard
            title={intl.formatMessage({id: "answerAndExplanation"})}
            description={intl.formatMessage({id: "editExamQuestion.section.answerAndExplanation.description"})}
        >
            <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 lg:col-span-4">
                    <InputCreatableSelect
                        id={"question-type"}
                        displayName={intl.formatMessage({id: "answerType"})}
                        nameHookForm={"selectedAnswerType"}
                        rules={{
                            required: intl.formatMessage({id: "pickAnAnswerType"}),
                            validate: (option: SelectedAnswerTypeOption) => option.value === SelectedAnswerType.NONE
                                ? intl.formatMessage({id: "pickAnAnswerType"})
                                : true,
                        }}
                        options={selectedAnswerTypeOptions}
                        disabled={loading}
                        control={control}
                        placeholder={intl.formatMessage({id: "pickAnAnswerType"})}
                        formatCreateLabel={_ => ""}
                        isValidNewOption={_ => false}
                        error={errors.selectedAnswerType && errors.selectedAnswerType.message}
                    />
                </div>

                <AnswerWrapper
                    forAnswer={SelectedAnswerType.MULTIPLE_CHOICE}
                    currentAnswer={watch("selectedAnswerType").value}
                >
                    <MultipleChoiceAnswer
                        loading={loading}
                        control={control}
                        register={register}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                        selectedAnswerType={watch("selectedAnswerType").value}
                    />
                </AnswerWrapper>

                <AnswerWrapper
                    forAnswer={SelectedAnswerType.YES_NO}
                    currentAnswer={watch("selectedAnswerType").value}
                >
                    <YesNoAnswer
                        loading={loading}
                        control={control}
                        register={register}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                        selectedAnswerType={watch("selectedAnswerType").value}
                    />
                </AnswerWrapper>

                <AnswerWrapper
                    forAnswer={SelectedAnswerType.NUMBER}
                    currentAnswer={watch("selectedAnswerType").value}
                >
                    <NumberAnswer
                        loading={loading}
                        control={control}
                        register={register}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                        selectedAnswerType={watch("selectedAnswerType").value}
                    />
                </AnswerWrapper>

                <AnswerWrapper
                    forAnswer={SelectedAnswerType.DRAG_DROP}
                    currentAnswer={watch("selectedAnswerType").value}
                >
                    <DragDropAnswer
                        loading={loading}
                        control={control}
                        register={register}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                        selectedAnswerType={watch("selectedAnswerType").value}
                    />
                </AnswerWrapper>

                <div className="col-span-6 lg:col-span-4">
                    <InputField
                        type={"textarea"}
                        id={"question-explanation-nl"}
                        textareaHeight={"h-32"}
                        displayName={intl.formatMessage({id: "dutchExplanation"})}
                        tip={`${watch("explanationNl")?.length || 0}/1024`}
                        reactHookForm={{
                            ...register("explanationNl", {
                                required: intl.formatMessage({id: "mustEnterField"}),
                                maxLength: {
                                    value: 1024,
                                    message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 1024})
                                }
                            })
                        }}
                        error={errors.explanationNl && errors.explanationNl.message}
                        disabled={loading}
                    />
                </div>
                <div className="col-span-6 lg:col-span-4">
                    <InputField
                        type={"textarea"}
                        id={"question-explanation-ar"}
                        textareaHeight={"h-32"}
                        displayName={intl.formatMessage({id: "arabicExplanation"})}
                        tip={`${watch("explanationAr")?.length || 0}/1024`}
                        alignRight={true}
                        rtl={true}
                        reactHookForm={{
                            ...register("explanationAr", {
                                required: intl.formatMessage({id: "mustEnterField"}),
                                maxLength: {
                                    value: 1024,
                                    message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 1024})
                                }
                            })
                        }}
                        error={errors.explanationAr && errors.explanationAr.message}
                        disabled={loading}
                    />
                </div>
            </div>
        </FormLayoutCard>
    );
};

export default AnswerSection;