import {BreadcrumbItem} from "peggirkit";
import {createIntl, createIntlCache} from "react-intl";
import DutchMessages from "../messages/nl-NL";

type Route = {
    relativePath: string,
    absolutePath: (...param: any) => string
}

type Router = {
    [routes: string]: Route
}

// Base:
const index: Route = {
    relativePath: "/",
    absolutePath: () => "/"
};

// Unauthenticated:
const authPart = "auth";
const auth: Route = {
    relativePath: authPart,
    absolutePath: () => `${index.absolutePath()}${authPart}`
};

const signInPart = "sign-in";
const signIn: Route = {
    relativePath: signInPart,
    absolutePath: () => `${auth.absolutePath()}/${signInPart}`
};

const resetPart = "reset";
const reset: Route = {
    relativePath: resetPart,
    absolutePath: () => `${auth.absolutePath()}/${resetPart}`
};

const confirmResetPart = "confirm";
const confirmReset: Route = {
    relativePath: confirmResetPart,
    absolutePath: () => `${reset.absolutePath()}/${confirmResetPart}`
};

// // Authenticated:
const dashboardPart = "dashboard";
const dashboard: Route = {
    relativePath: dashboardPart,
    absolutePath: () => `${index.absolutePath()}${dashboardPart}`
};

const accountPart = "account";
const account: Route = {
    relativePath: accountPart,
    absolutePath: () => `${index.absolutePath()}${accountPart}`
};

const contactFormsPart = "contact-forms";
const contactForms: Route = {
    relativePath: contactFormsPart,
    absolutePath: () => `${index.absolutePath()}${contactFormsPart}`
};

const contactFormPart = ":contactFormId";
const contactForm: Route = {
    relativePath: contactFormPart,
    absolutePath: (contactFormId: number) => `${contactForms.absolutePath()}/${contactFormId}`
};

const blogPart = "blog";
const blog: Route = {
    relativePath: blogPart,
    absolutePath: () => `${index.absolutePath()}${blogPart}`
};

const blogPostPart = ":blogPostId";
const blogPost: Route = {
    relativePath: blogPostPart,
    absolutePath: (blogPostId: number) => `${blog.absolutePath()}/${blogPostId}`
};

export const newBlogPostPart = "new";
const newBlogPost: Route = {
    relativePath: newBlogPostPart,
    absolutePath: () => `${blog.absolutePath()}/${newBlogPostPart}`
};

const studentsPart = "students";
const students: Route = {
    relativePath: studentsPart,
    absolutePath: () => `${index.absolutePath()}${studentsPart}`
};

const studentPart = ":studentId";
const student: Route = {
    relativePath: studentPart,
    absolutePath: (studentId: number) => `${students.absolutePath()}/${studentId}`
};

export const newStudentPart = "new";
const newStudent: Route = {
    relativePath: newStudentPart,
    absolutePath: () => `${students.absolutePath()}/${newStudentPart}`
};

const databasePart = "database";
const database: Route = {
    relativePath: databasePart,
    absolutePath: () => `${index.absolutePath()}${databasePart}`
};

const categoriesPart = "categories";
const categories: Route = {
    relativePath: categoriesPart,
    absolutePath: () => `${index.absolutePath()}${categoriesPart}`
};

const questionsPart = "questions";
const questions: Route = {
    relativePath: questionsPart,
    absolutePath: () => `${index.absolutePath()}${questionsPart}`
};

const editQuestionPart = ":questionId";
const editQuestion: Route = {
    relativePath: editQuestionPart,
    absolutePath: (questionId: number) => `${questions.absolutePath()}/${questionId}`
};

export const newQuestionPart = "new";
const newQuestion: Route = {
    relativePath: newQuestionPart,
    absolutePath: () => `${questions.absolutePath()}/${newQuestionPart}`
};

export const errorPart = "error";
const error: Route = {
    relativePath: errorPart,
    absolutePath: () => `${index.absolutePath()}${errorPart}`
};

const examsPart = "exams";
const exams: Route = {
    relativePath: examsPart,
    absolutePath: () => `${index.absolutePath()}${examsPart}`
};

const editExamPart = ":examId";
const editExam: Route = {
    relativePath: editExamPart,
    absolutePath: (examId: number) => `${exams.absolutePath()}/${examId}`
};

export const newExamPart = "new";
const newExam: Route = {
    relativePath: newExamPart,
    absolutePath: () => `${exams.absolutePath()}/${newExamPart}`
};

const statisticsPart = "statistics";
const statistics: Route = {
    relativePath: statisticsPart,
    absolutePath: () => `${index.absolutePath()}${statisticsPart}`
};

const faqPart = "faq";
const faq: Route = {
    relativePath: faqPart,
    absolutePath: () => `${index.absolutePath()}${faqPart}`
};

const editFaqQuestionPart = "question/:faqQuestionId";
const editFaqQuestion: Route = {
    relativePath: editFaqQuestionPart,
    absolutePath: (questionId: number) => `${faq.absolutePath()}/question/${questionId}`
};

export const newFaqQuestionPart = ":faqSectionId/new";
const newFaqQuestion: Route = {
    relativePath: newFaqQuestionPart,
    absolutePath: (faqSectionId: number) => `${faq.absolutePath()}/${faqSectionId}/new`
};

const router: Router = {};
router.index = index;
router.auth = auth;
router.signIn = signIn;
router.dashboard = dashboard;
router.account = account;
router.reset = reset;
router.confirmReset = confirmReset;
router.students = students;
router.newStudent = newStudent;
router.student = student;
router.database = database;
router.categories = categories;
router.questions = questions;
router.editQuestion = editQuestion;
router.newQuestion = newQuestion;
router.exams = exams;
router.editExam = editExam;
router.newExam = newExam;
router.contactForms = contactForms;
router.contactForm = contactForm;
router.faq = faq;
router.newFaqQuestion = newFaqQuestion;
router.editFaqQuestion = editFaqQuestion;
router.statistics = statistics;
router.blog = blog;
router.blogPost = blogPost;
router.newBlogPost = newBlogPost;
router.error = error;

// Breadcrumbs:
const cache = createIntlCache();
const intl = createIntl({locale: 'nl-NL', messages: DutchMessages}, cache);

export const breadcrumbItemDashboard: BreadcrumbItem = {
    name: intl.formatMessage({id: "dashboard"}),
    href: router.dashboard.absolutePath()
};

export const breadcrumbItemStatistics: BreadcrumbItem = {
    name: intl.formatMessage({id: "statistics"}),
    href: router.statistics.absolutePath()
};

export const breadcrumbItemDatabase: BreadcrumbItem = {
    name: intl.formatMessage({id: "database"}),
    href: router.database.absolutePath()
};

export const breadcrumbItemAccount: BreadcrumbItem = {
    name: intl.formatMessage({id: "account"}),
    href: router.account.absolutePath()
};

export const breadcrumbItemStudents: BreadcrumbItem = {
    name: intl.formatMessage({id: "students"}),
    href: router.students.absolutePath()
};

export const breadcrumbItemBlog: BreadcrumbItem = {
    name: intl.formatMessage({id: "blog"}),
    href: router.blog.absolutePath()
};

export const breadcrumbItemBlogPost = (blogPostId: number): BreadcrumbItem => ({
    name: intl.formatMessage({id: blogPostId === -1 ? "newBlogPost" : "blogPostWithNumber"}, {blogPostId: blogPostId}),
    href: blogPostId === -1 ? router.newBlogPost.absolutePath() : router.blogPost.absolutePath(blogPostId)
});

export const breadcrumbItemContactForms: BreadcrumbItem = {
    name: intl.formatMessage({id: "contactForms"}),
    href: router.contactForms.absolutePath()
};

export const breadcrumbItemContactForm = (contactFormId: number): BreadcrumbItem => ({
    name: intl.formatMessage({id: "contactForm"}),
    href: router.contactForm.absolutePath(contactFormId)
});

export const breadcrumbItemExams: BreadcrumbItem = {
    name: intl.formatMessage({id: "exams"}),
    href: router.exams.absolutePath()
};

export const breadcrumbItemStudent = (studentId: number): BreadcrumbItem => ({
    name: intl.formatMessage({id: studentId === -1 ? "newStudent" : "studentWithNumber"}, {studentId: studentId}),
    href: studentId === -1 ? router.newStudent.absolutePath() : router.student.absolutePath(studentId)
});

export const breadcrumbItemCategories: BreadcrumbItem = {
    name: intl.formatMessage({id: "categories"}),
    href: router.categories.absolutePath()
};

export const breadcrumbItemQuestions: BreadcrumbItem = {
    name: intl.formatMessage({id: "examQuestions"}),
    href: router.questions.absolutePath()
};

export const breadcrumbItemFaq: BreadcrumbItem = {
    name: intl.formatMessage({id: "faq"}),
    href: router.faq.absolutePath()
};

export const breadcrumbItemEditFaqQuestion = (id: number): BreadcrumbItem => ({
    name: intl.formatMessage({id: id === -1 ? "newFaqQuestion" : "faqQuestionWithId"}, {id: id}),
    href: id === -1 ? "#" : router.editFaqQuestion.absolutePath(id)
});

export const breadcrumbItemEditQuestion = (questionId: number): BreadcrumbItem => ({
    name: intl.formatMessage({id: questionId === -1 ? "newQuestion" : "questionWithId"}, {questionId: questionId}),
    href: router.editQuestion.absolutePath(questionId)
});

export const breadcrumbItemEditExam = (examId: number, nameNl?: string): BreadcrumbItem => ({
    name: nameNl || intl.formatMessage({id: "newExam"}),
    href: examId === -1 ? router.newExam.absolutePath() : router.editExam.absolutePath(examId)
});

export default router;