import React, {useId} from "react";
import {ExamQuestionType, OverviewExamQuestion} from "../../../../../../data/Entities";
import {useIntl} from "react-intl";
import {Tooltip} from "peggirkit";
// @ts-ignore
import placeholder from "../../../../../../assets/img/placeholder.jpg";
import {getAbsoluteImageUrl} from "../../../../assets/AssetsUtil";
import {PhotoIcon} from "@heroicons/react/24/outline";

type Props = {
    questionNl: string,
    data: any,
    separateImageTooltip?: boolean
};

const QuestionFormatter = ({questionNl, data, separateImageTooltip = false}: Props) => {
    const intl = useIntl();
    const tooltipId = useId();
    const question = data as OverviewExamQuestion;
    const questionTypeName = (type: ExamQuestionType): string => {
        switch (type) {
            case "NUMBER":
                return intl.formatMessage({id: "number"});
            case "YES_NO":
                return intl.formatMessage({id: "yesNo"});
            case "DRAG_DROP":
                return intl.formatMessage({id: "dragDropQuestion"});
            case "MULTIPLE_CHOICE_IMAGES":
                return intl.formatMessage({id: "hotspot"});
            case "MULTIPLE_RESPONSE":
                return intl.formatMessage({id: "multipleResponse"});
            case "MULTIPLE_CHOICE":
                return intl.formatMessage({id: "multipleChoice"});
        }
        return "";
    };

    const tooltipProps = {
        "data-tip": true,
        "data-for": tooltipId
    };

    return (
        <div
            {...!separateImageTooltip ? {...tooltipProps} : {}}
            className={"w-fit flex flex-col items-start justify-start min-w-[300px] whitespace-normal"}
        >
            <span>{questionNl}</span>
            <span className={"text-gray-500 text-xs flex flex-row items-center gap-2"}>
                {separateImageTooltip ? <PhotoIcon {...tooltipProps} className={"w-5"}/> : <></>}
                {questionTypeName(question.questionType)}
            </span>
            <Tooltip
                tooltipProps={{
                    id: tooltipId,
                    padding: "0",
                    insecure: false,
                    arrowColor: "transparent",
                }}
            >
                <img
                    className={"w-80 aspect-[938/639] object-contain opacity-100"}
                    alt={intl.formatMessage({id: "image"})}
                    src={
                        question.image
                            ? getAbsoluteImageUrl(question.image)
                            : placeholder
                    }
                />
            </Tooltip>
        </div>
    )
};

export default QuestionFormatter;