import React from 'react';
import {AdjustmentsHorizontalIcon, DocumentTextIcon} from "@heroicons/react/24/outline";
import GeneralSection, {EditExamGeneralSectionProps} from "./general/GeneralSection";
import ComposeSection, {ComposeSectionProps} from "./compose/ComposeSection";
import {TabControl} from "peggirkit";
import {useIntl} from "react-intl";

const EditExamSections = ({
                              watch,
                              control,
                              loading,
                              register,
                              errors,
                              setValue,
                              handleSubmit,
                              setLoading
                          }: ComposeSectionProps & EditExamGeneralSectionProps) => {
    const intl = useIntl();
    return (
        <TabControl
            selectTabText={intl.formatMessage({id: "pickATab"})}
            tabs={[
                {
                    name: intl.formatMessage({id: "general"}),
                    icon: AdjustmentsHorizontalIcon,
                    defaultTab: true,
                    current: true,
                    hash: "general",
                    content: <GeneralSection
                        handleSubmit={handleSubmit}
                        setLoading={setLoading}
                        loading={loading}
                        watch={watch}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        control={control}
                    />
                },
                {
                    name: intl.formatMessage({id: "examQuestions"}),
                    icon: DocumentTextIcon,
                    defaultTab: false,
                    current: false,
                    hash: "questions",
                    content: <ComposeSection control={control} watch={watch}/>
                }
            ]}
        />
    );
};

export default EditExamSections;