import React from "react";
import {FormLayoutCard, InputCheckbox, InputCreatableSelect} from "peggirkit";
import {FormattedMessage, useIntl} from "react-intl";
import {EditQuestionSectionProps, NumberValueLabel, ValueLabel} from "../../EditQuestionForm";
import {conceptOptions} from "./ConceptState";
import InExams from "./InExams/InExams";
import {QuestionInExam} from "../../../../../../data/Entities";

type Props = {
    categoryOptions: NumberValueLabel[],
    questionInExams?: QuestionInExam[]
};

const isInPublicExam = (questionsInExam?: QuestionInExam[]): boolean => {
    if (!questionsInExam) {
        return false;
    }

    for (let i = 0; i < questionsInExam.length; i++) {
        const q = questionsInExam[i];
        if (!q.exam.concept) {
            return true;
        }
    }

    return false;
};

const GeneralSection = ({
                            loading,
                            control,
                            register,
                            errors,
                            categoryOptions,
                            questionInExams
                        }: EditQuestionSectionProps & Props) => {
    const intl = useIntl();

    return (
        <FormLayoutCard
            title={intl.formatMessage({id: "general"})}
            description={intl.formatMessage({id: "editExamQuestion.section.general.description"})}
        >
            <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 lg:col-span-4">
                    <InputCreatableSelect
                        id={"question-status"}
                        displayName={intl.formatMessage({id: "status"})}
                        options={conceptOptions}
                        disabled={loading}
                        control={control}
                        placeholder={intl.formatMessage({id: "pickAStatus"})}
                        nameHookForm={"concept"}
                        isValidNewOption={_ => false}
                        formatCreateLabel={inputValue => intl.formatMessage({id: "addOption"}, {option: inputValue})}
                        rules={{
                            required: intl.formatMessage({id: "mustPickOption"}),
                            validate: (option: ValueLabel) => option.value === "true" && isInPublicExam(questionInExams)
                                ? intl.formatMessage({id: "statusCannotBeConceptWhenInPublicExam"})
                                : true
                        }}
                        error={errors.concept && errors.concept.message}
                        tip={intl.formatMessage({id: "conceptQuestionExplanationTip"})}
                    />
                </div>
                <div className="col-span-6 lg:col-span-4">
                    <InputCreatableSelect
                        id={"question-category"}
                        displayName={intl.formatMessage({id: "category"})}
                        options={categoryOptions}
                        disabled={loading}
                        control={control}
                        placeholder={intl.formatMessage({id: "pickACategory"})}
                        nameHookForm={"category"}
                        rules={{required: intl.formatMessage({id: "mustPickOption"})}}
                        error={errors.category && errors.category.message}
                        isValidNewOption={_ => false}
                        formatCreateLabel={inputValue => intl.formatMessage({id: "addOption"}, {option: inputValue})}
                    />
                </div>
                <div className="col-span-6 lg:col-span-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        <FormattedMessage id={"sections"}/>
                    </label>
                    <div className={"flex flex-col gap-2"}>
                        <InputCheckbox
                            id={"question-section-hazard-recognition"}
                            displayName={intl.formatMessage({id: "hazardRecognition"})}
                            error={errors.hazardRecognitionSection && errors.hazardRecognitionSection.message}
                            reactHookForm={register("hazardRecognitionSection")}
                            disabled={loading}
                        />
                        <InputCheckbox
                            id={"question-section-knowledge"}
                            displayName={intl.formatMessage({id: "knowledge"})}
                            error={errors.knowledgeSection && errors.knowledgeSection.message}
                            reactHookForm={register("knowledgeSection")}
                            disabled={loading}
                        />
                        <InputCheckbox
                            id={"question-section-insight"}
                            displayName={intl.formatMessage({id: "insight"})}
                            error={errors.insightSection && errors.insightSection.message}
                            reactHookForm={register("insightSection")}
                            disabled={loading}
                        />
                    </div>
                </div>
                {

                    <InExams questionInExams={questionInExams}/>
                }
            </div>
        </FormLayoutCard>
    );
};

export default GeneralSection;