import React, {useEffect, useState} from "react";
import {SectionHeading} from "peggirkit";
import {ExamStats} from "../../../../data/Entities";
import {useIntl} from "react-intl";
import {StatisticsSettings} from "../StatisticsOverview";
import TakenExamsCard from "./takenExams/TakenExamsCard";
import ExamSummaries from "./examSummaries/ExamSummaries";
import {getStudentExamsStats} from "../../../../data/ApiEndpoints";

type Props = {
    statisticsSettings: StatisticsSettings,
    setStatisticsSettings: React.Dispatch<React.SetStateAction<StatisticsSettings>>,
};

const StudentStatistics = ({statisticsSettings, setStatisticsSettings}: Props) => {
    const intl = useIntl();
    const [examStats, setExamStats] = useState<ExamStats | undefined>();

    useEffect(() => {
        if (!statisticsSettings.dateRange.from
            || !statisticsSettings.dateRange.to
            || !statisticsSettings.loadingStudentStats) {
            return;
        }

        getStudentExamsStats(statisticsSettings.dateRange.from, statisticsSettings.dateRange.to)
            .then(x => {
                setExamStats(x);
                setStatisticsSettings((prev) => ({...prev, loadingStudentStats: false}));
            });
    }, [statisticsSettings.loadingStudentStats]);

    return (
        <div className={"mt-12 pb-24"}>
            <SectionHeading
                title={intl.formatMessage({id: "studentsAndExams"})}
                description={intl.formatMessage({id: "studentsAndExamsStatsDescription"})}
            />

            <div className={"w-full flex flex-col gap-6"}>
                <div className={"grid grid-cols-2 lg:grid-cols-3 gap-6"}>
                    <div className={"col-span-2"}>
                        <TakenExamsCard
                            statisticsSettings={statisticsSettings}
                            examStats={examStats}
                        />
                    </div>
                    <div className={"col-span-2 md:col-span-1"}>
                        <ExamSummaries
                            statisticsSettings={statisticsSettings}
                            examStats={examStats}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentStatistics;