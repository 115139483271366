import React from "react";
import {ContactForm} from "../../../../data/Entities";
import ContactFormFieldsCard from "./details/ContactFormFieldsCard";
import ContactFormAdminCard from "./details/ContactFormAdminCard";

type Props = {
    contactForm: ContactForm | undefined,
    setContactForm: (contactForm: ContactForm) => void,
};

const ContactFormDetails = ({contactForm, setContactForm}: Props) => {
    return (
        <div className={"w-full mt-6 xl:mt-12 grid xl:grid-cols-3 gap-6 xl:gap-12"}>
            <div className={"xl:col-span-2"}>
                <ContactFormFieldsCard contactForm={contactForm}/>
            </div>

            <div>
                <ContactFormAdminCard
                    contactForm={contactForm}
                    setContactForm={setContactForm}
                />
            </div>
        </div>
    );
};

export default ContactFormDetails;