import React, {useEffect, useState} from "react";
import {AlertModal, AlertText, NotificationService} from "peggirkit";
import {resendStudentCreatedMail} from "../../../../../../data/ApiEndpoints";
import {FormattedMessage, useIntl} from "react-intl";

type Props = {
    studentId: number,
    show: boolean,
    setShow: (show: boolean) => void,
};

const ResendWelcomeMailModal = ({studentId, show, setShow}: Props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        resendStudentCreatedMail(studentId).then(() => {
            setShow(false);
            setLoading(false);
            NotificationService.notify({type: "success", text: intl.formatMessage({id: "welcomeMailSent"})});
        });
    }, [loading]);

    return (
        <AlertModal
            type={"info"}
            loading={loading}
            show={show}
            onClose={() => setShow(false)}
            title={intl.formatMessage({id: "sendWelcomeMail"})}
            cancelText={intl.formatMessage({id: "cancel"})}
            continueText={intl.formatMessage({id: "continue"})}
            onContinue={() => setLoading(true)}
        >
            <AlertText>
                <FormattedMessage id={"confirmSendWelcomeMail"}/>
            </AlertText>
        </AlertModal>
    );
};

export default ResendWelcomeMailModal;