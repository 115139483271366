import React from "react";
import {PrimaryNavigationItemProps} from "peggirkit";
import {ArrowRightIcon} from "@heroicons/react/24/outline";

const DashboardLink = (item: PrimaryNavigationItemProps) => {
    return (
        <a
            className={"focus:outline-2 group shadow-sm bg-white border border-gray-200 p-3 lg:p-6 rounded-xl flex flex-col gap-3 lg:gap-6"}
            href={item.href}
        >
            <div className={"w-full flex items-start justify-between"}>
                <div className={"bg-blue-700/10 text-blue-700 rounded p-2 lg:p-3"}>
                    <item.icon className={"w-4 h-4 lg:w-6 lg:h-6"}/>
                </div>
                <ArrowRightIcon
                    className={"transition-colors w-4 lg:w-6 text-gray-300 group-hover:text-gray-400"}
                />
            </div>
            <strong className={"w-full block overflow-hidden text-lg lg:text-xl text-ellipsis"}>
                {item.name}
            </strong>
        </a>
    );
};

export default DashboardLink;