import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {AuthButton, Form, InputField} from "peggirkit";
import {requestPasswordReset} from "../../../data/ApiEndpoints";
import {useIntl} from "react-intl";

export type FormValues = {
    emailAddress: string
};

const AuthFormResetPassword = () => {
    const intl = useIntl();
    const {register, getValues, handleSubmit, formState: {errors}} = useForm<FormValues>();
    const [loading, setLoading] = useState(false);
    const [formDisabled, setFormDisabled] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);
    const [formSuccess, setFormSuccess] = useState<string | null>(null);

    useEffect(() => {
        if (!loading) {
            return;
        }

        const submittedData = getValues();
        requestPasswordReset(submittedData.emailAddress).then(() => {
            setFormSuccess(intl.formatMessage({id: "passwordResetRequestedCheckInbox"}));
            setFormError(null);
            setLoading(false);
            setFormDisabled(true);
        }).catch(() => {
            setFormSuccess(null);
            setFormError(intl.formatMessage({id: "errorRequestingReset"}));
            setLoading(false);
        });
    }, [loading]);

    return (
        <Form
            onSubmit={handleSubmit(() => setLoading(true))}
            error={formError}
            success={formSuccess}
        >
            <InputField
                id={"emailAddress"}
                displayName={intl.formatMessage({id: "emailAddress"})}
                type={"email"}
                placeholder={intl.formatMessage({id: "emailAddressPlaceholder"})}
                tip={intl.formatMessage({id: "emailAddressOfExistingAccount"})}
                disabled={loading || formDisabled}
                error={errors.emailAddress && errors.emailAddress.message}
                reactHookForm={{
                    ...register("emailAddress", {
                        required: intl.formatMessage({id: "enterAnEmailAddress"}),
                        minLength: {
                            value: 3,
                            message: intl.formatMessage({id: "mustBeMinCharacters"}, {min: 3})
                        },
                        maxLength: {
                            value: 128,
                            message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 128})
                        }
                    })
                }}
            />

            <AuthButton
                disabled={loading || formDisabled}
                loading={loading}
                text={intl.formatMessage({id: "requestReset"})}
            />
        </Form>
    );
};

export default AuthFormResetPassword;