export type Message = {
    message: string
};

export type User = {
    emailAddress: string
};

export type PreferredLanguage = "nl" | "ar";

export type AssetDestination = "GENERAL" | "BLOG";

export type Order = {
    id: number,
    name: string,
    emailAddress: string,
    preferredLang: string,
    invoiceDescription: string,
    priceExTax: number,
    taxPrice: number,
    priceInTax: number,
    mollieTransactionId: string,
    paymentMethod: string | null,
    paidOn: string | null,
    factuurSturenClientNumber: number | null,
    factuurSturenInvoiceNumber: string | null,
    creationDate: string,
};

export type Student = {
    id?: number,
    code?: string,
    emailAddress: string,
    name: string,
    startExamWithExplanation?: boolean,
    receivedReminder?: string,
    preferredLang: PreferredLanguage,
    activatedDurationMinutes?: number,
    expirationDate?: string,
    creationDate?: string,
    order?: Order | null,
};

export enum StudentStatus {
    ANY = "ANY",
    NOT_YET_ACTIVATED = "NOT_YET_ACTIVATED",
    ACTIVE = "ACTIVE",
    EXPIRED = "EXPIRED",
}

export type ExamCategoryId = number;

export type BaseCategory = {
    id: ExamCategoryId
};

export type ExamCategory = {
    id?: ExamCategoryId,
    code: string | null,
    nameNl: string,
    nameAr: string,
    requiredInHazardRecognitionSection: number,
    requiredInKnowledgeSection: number,
    requiredInInsightSection: number,
    hazardRecognitionQuestionsCount: number,
    knowledgeQuestionsCount: number,
    insightQuestionsCount: number,
};

export type ExamMultipleChoiceAnswerOption = {
    id?: number,
    orderNr: number,
    answerNl: string | null,
    answerAr: string | null,
    correct: boolean,
    image: string | null
};

export type ExamMultipleChoiceAnswer = {
    id?: number,
    withImages: boolean,
    multipleResponse: boolean,
    answers: ExamMultipleChoiceAnswerOption[]
};

export type ExamDragDropAnswerOption = {
    id?: number,
    number: number,
    draggableOption: boolean,
    topOffset: number,
    leftOffset: number,
};

export type ExamDragDropAnswer = {
    id?: number,
    image: string,
    answers: ExamDragDropAnswerOption[]
};

export type Exam = {
    id: number,
    nameNl: string,
    concept: boolean
};

export type OverviewExam = Exam & {
    demo: boolean
    creationDate: string
};

type BaseExamDetails = {
    id: number | null,
    nameNl: string,
    nameAr: string,
    image: string | null,
    concept: boolean,
    demo: boolean,
};

export type ExamDetails = BaseExamDetails & {
    hazardRecognitionQuestions: QuestionInExamDetails[],
    knowledgeQuestions: QuestionInExamDetails[],
    insightQuestions: QuestionInExamDetails[],
};

export type ExamDetailsForSubmission = BaseExamDetails & {
    hazardRecognitionQuestions: QuestionInExamDetailsForSubmission[],
    knowledgeQuestions: QuestionInExamDetailsForSubmission[],
    insightQuestions: QuestionInExamDetailsForSubmission[],
};

export type QuestionInExamDetails = {
    id: number | null,
    orderNr: number,
    question: OverviewExamQuestion,
    hazardRecognitionSection: boolean,
    knowledgeSection: boolean,
    insightSection: boolean,
};

export type QuestionInExamDetailsForSubmission = Omit<QuestionInExamDetails, "question" | "id"> & {
    question: OverviewExamQuestionInExamForSubmission,
};

export type QuestionInExam = {
    id: number | null
    exam: Exam
};

type BaseExamQuestion = {
    id?: number,
    questionNl: string,
    concept: boolean,
    hazardRecognitionSection: boolean,
    knowledgeSection: boolean,
    insightSection: boolean,
    questionInExams?: QuestionInExam[],
    image: string | null,
};

export type OverviewExamQuestionInExamForSubmission = {
    id: number
};

export type ExamQuestionType =
    "NUMBER"
    | "YES_NO"
    | "MULTIPLE_CHOICE"
    | "MULTIPLE_RESPONSE"
    | "MULTIPLE_CHOICE_IMAGES"
    | "DRAG_DROP";

export type OverviewExamQuestion = BaseExamQuestion & {
    questionType: ExamQuestionType
    category: ExamCategory
};

export type AudioFragment = {
    id: number,
    file: string,
    queued: boolean,
};

export type ExamQuestion = BaseExamQuestion & {
    questionAr: string,
    answerYes: boolean | null,
    numberAnswer: number | null,
    multipleChoiceAnswer: ExamMultipleChoiceAnswer | null,
    dragDropAnswer: ExamDragDropAnswer | null,
    explanationNl: string,
    explanationAr: string,
    category: BaseCategory,
    questionAudio: AudioFragment | null,
};

export type ContactFormAdminFields = {
    id: number,
    internalNote: string,
    openStatus: boolean | null,
};

export type ContactForm = ContactFormAdminFields & {
    studentId: number | null,
    studentCode: string | null,
    firstName: string,
    lastName: string | null,
    emailAddress: string,
    telephoneNumber: string | null,
    message: string,
    creationDate: string,
};

export type FaqQuestionInOverview = {
    id: number,
    featuredOrderNr: number,
    questionNl: string,
    updatedOn: string,
    creationDate: string,
};

export type FaqSection = {
    id: number,
    nameNl: string,
    nameAr: string,
    questions: FaqQuestionInOverview[],
};

export type FaqSectionAsParent = {
    id: number,
};

export type FaqQuestionDetails = {
    id: number | undefined | null,
    orderNr: number,
    featuredOrderNr: number | null,
    questionNl: string,
    questionAr: string,
    answerNl: string,
    answerAr: string,
    slugNl: string,
    slugAr: string,
    metaDescriptionNl: string,
    metaDescriptionAr: string,
    faqSection: FaqSectionAsParent,
    updatedOn: string,
    creationDate: string,
};

export type Range = {
    from: string | Date,
    to: string | Date,
};

export type OrderedPlanCount = {
    [key: string]: number
};

export type RevenuePerDay = {
    date: string,
    totalPriceInTax: number,
    salesCount: number,
};

export type RevenueStats = {
    range: Range,
    priceInTaxSum: number,
    totalSalesCount: number,
    orderedPlans: OrderedPlanCount,
    revenuePerDay: RevenuePerDay[],
};

export type ExamsPerDay = {
    date: string,
    totalExams: number,
    passed: number,
    failed: number,
    notFinished: number,
};

export type ExamStats = {
    range: Range,
    totalExams: number,
    timeEnabled: number,
    extraTime: number,
    soundEnabled: number,
    examsPerDay: ExamsPerDay[],
};

export type BlogPost = {
    id?: number,
    publishDate: string,
    lastEditedDate?: string,
    title: string,
    slug: string,
    description: string,
    banner: string,
    content: string,
};
