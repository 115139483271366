import React, {useEffect, useState} from "react";
import {AlertModal, AlertText} from "peggirkit";
import {FormattedMessage, useIntl} from "react-intl";
import {deleteExams} from "../../../../data/ApiEndpoints";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void,
    examIds: number[],
    cb: () => void
};

const DeleteExamsModal = ({show, setShow, examIds, cb}: Props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        deleteExams(examIds).then(_ => {
            setLoading(false);
            cb();
        });
    }, [loading]);

    return (
        <AlertModal
            type={"danger"}
            loading={loading}
            show={show}
            onClose={() => setShow(false)}
            title={
                intl.formatMessage(
                    {id: examIds.length === 1 ? "confirmDeleteExamTitle" : "confirmDeleteExams"},
                    {nQuestions: examIds.length})
            }
            cancelText={intl.formatMessage({id: "cancel"})}
            continueText={intl.formatMessage({id: "continue"})}
            onContinue={() => setLoading(true)}
        >
            <AlertText>
                <FormattedMessage
                    id={examIds.length === 1
                        ? "confirmDeleteExam"
                        : "confirmDeleteExamsDescription"
                    }
                    values={{nExams: examIds.length}}
                />
            </AlertText>
        </AlertModal>
    );
};

export default DeleteExamsModal;