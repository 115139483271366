import React, {useEffect, useState} from "react";
import {AlertModal, AlertText} from "peggirkit";
import {deleteStudents} from "../../../../data/ApiEndpoints";
import {Student} from "../../../../data/Entities";
import {FormattedMessage, useIntl} from "react-intl";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void,
    students: Student[],
    cb: () => void
};

const DeleteStudentsModal = ({show, setShow, students, cb}: Props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        deleteStudents(students.map(s => s.id || -1)).then(() => {
            setLoading(false);
            cb();
        });
    }, [loading]);

    return (
        <AlertModal
            type={"danger"}
            loading={loading}
            show={show}
            onClose={() => setShow(false)}
            title={
                intl.formatMessage(
                    {id: students.length === 1 ? "deleteStudentTitle" : "deleteStudentsTitle"},
                    {nStudents: students.length})
            }
            cancelText={intl.formatMessage({id: "cancel"})}
            onContinue={() => setLoading(true)}
            continueText={intl.formatMessage({id: "continue"})}
        >
            <AlertText>
                <FormattedMessage
                    id={students.length === 1 ? "confirmDeleteStudent.single" : "confirmDeleteStudent.multiple"}
                    values={{nStudents: students.length}}
                />
            </AlertText>
        </AlertModal>
    );
};

export default DeleteStudentsModal;