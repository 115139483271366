import React from "react";
import {FieldError, UseFormRegister} from "react-hook-form";
import {InputField} from "peggirkit";
import {useIntl} from "react-intl";

type Props = {
    register: UseFormRegister<any>,
    disabled: boolean,
    fieldError?: FieldError,
    compareValue: string | undefined
};

const SetPasswordRepeatedField = ({register, disabled, fieldError, compareValue}: Props) => {
    const intl = useIntl();
    return (
        <InputField
            id={"passwordRepeated"}
            displayName={intl.formatMessage({id: "repeatPassword"})}
            type={"password"}
            placeholder="••••••••••••••••••"
            disabled={disabled}
            error={fieldError && fieldError.message}
            reactHookForm={{
                ...register("passwordRepeated", {
                    required: intl.formatMessage({id: "repeatYourPassword"}),
                    minLength: {
                        value: 8,
                        message: intl.formatMessage({id: "mustBeMinCharacters"}, {min: 8})
                    },
                    maxLength: {
                        value: 128,
                        message: intl.formatMessage({id: "mustBeMaxCharacters"}, {max: 128})
                    },
                    validate: val => val === compareValue
                        ? true
                        : intl.formatMessage({id: "passwordsMustMatch"})
                })
            }}
        />
    );
};

export default SetPasswordRepeatedField;