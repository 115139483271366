import {createIntl, createIntlCache} from "react-intl";
import DutchMessages from "../../../../../messages/nl-NL";
import {DataTableColumn, TableColumn} from "peggirkit";
import QuestionFormatter from "./formatter/QuestionFormatter";
import React from "react";
import {ExamCategory, QuestionInExam} from "../../../../../data/Entities";
import CategoryFormatter from "./formatter/CategoryFormatter";
import InExamsFormatter from "./formatter/inExams/InExamsFormatter";

const cache = createIntlCache();
const intl = createIntl({locale: 'nl-NL', messages: DutchMessages}, cache);

export const columnId: (DataTableColumn & TableColumn) = {
    dataName: "id",
    name: intl.formatMessage({id: "id"}),
    sortable: true,
    secondary: true,
    sorted: "desc"
};

export const columnQuestionNl = {
    dataName: "questionNl",
    name: intl.formatMessage({id: "question"}),
    sortable: true,
    secondary: false,
    formatter: (questionNl: string, data: any) => <QuestionFormatter questionNl={questionNl} data={data}/>
};

export const columnCategory = {
    dataName: "category",
    name: intl.formatMessage({id: "category"}),
    sortable: false,
    formatter: (category: ExamCategory) => <CategoryFormatter category={category}/>,
    secondary: true
};

export const columnQuestionInExams = {
    dataName: "questionInExams",
    name: intl.formatMessage({id: "inExams"}),
    sortable: false,
    formatter: (questionInExams: QuestionInExam[], q: any) =>
        <InExamsFormatter questionId={q.id} questionInExams={questionInExams}/>,
    secondary: true
};