import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import AudioPlayer, {AudioPlayerProps} from "./AudioPlayer";

type Props = {
    file: string,
}

const AudioPlayers = ({file}: Props) => {
    const intl = useIntl();
    const players: AudioPlayerProps[] = useMemo(() => ([
        {
            label: intl.formatMessage({id: "dutch-audio"}),
            locale: "nl-NL",
            file: file,
        },
        {
            label: intl.formatMessage({id: "arabic-audio"}),
            locale: "ar-AR",
            file: file,
        },
    ]), [intl, file]);

    return (
        <div className={"flex flex-col gap-6"}>
            {players.map((p: AudioPlayerProps, i: number) => (
                <AudioPlayer key={i} {...p}/>
            ))}
        </div>
    );
};

export default AudioPlayers;