import React from "react";
import {EditQuestionSectionProps} from "../../../EditQuestionForm";
import {FormattedMessage, useIntl} from "react-intl";
import {AnswerTypeProps} from "../AnswerSection";
import {Alert, Button, RadioGroup} from "peggirkit";
import {PlusIcon} from "@heroicons/react/24/outline";
import Option from "./Option";
import {Controller, useFieldArray} from "react-hook-form";
import {SelectedAnswerType} from "../AnswerType";

const MultipleChoiceAnswer = ({
                                  selectedAnswerType,
                                  loading,
                                  control,
                                  register,
                                  errors,
                                  watch,
                                  setValue,
                              }: EditQuestionSectionProps & AnswerTypeProps) => {
    const intl = useIntl();
    const {fields, append, remove} = useFieldArray({
        control,
        name: "multipleChoiceAnswer.answers"
    });

    // useEffect(() => {
    //     console.log("watch(\"multipleChoiceAnswer.multipleResponse\")", watch("multipleChoiceAnswer.multipleResponse"));
    // }, [watch("multipleChoiceAnswer.multipleResponse")]);

    return (
        <>
            <Alert
                className={"mb-4"}
                type={"info"}
                title={intl.formatMessage({id: "multipleChoiceExplanation.title"})}
                description={intl.formatMessage({id: "multipleChoiceExplanation.description"})}
            />

            <div className={"w-full mb-4"}>
                <Controller
                    control={control}
                    name={"multipleChoiceAnswer.withImages"}
                    render={({field: {onChange, onBlur, value, name}}) => (
                        <RadioGroup
                            title={intl.formatMessage({id: "withImages"})}
                            name={name}
                            disabled={loading}
                            error={
                                errors.multipleChoiceAnswer?.withImages
                                && errors.multipleChoiceAnswer.withImages.message
                            }
                            options={[
                                {
                                    label: intl.formatMessage({id: "yes"}),
                                    checked: value === true,
                                    onChange: () => {
                                        onChange(true);
                                        setValue("multipleChoiceAnswer.multipleResponse", false);
                                    }
                                },
                                {
                                    label: intl.formatMessage({id: "no"}),
                                    checked: value === false,
                                    onChange: () => onChange(false)
                                }
                            ]}
                            onBlur={onBlur}
                        />
                    )}
                />
            </div>

            <div className={"w-full mb-8"}>
                <Controller
                    control={control}
                    name={"multipleChoiceAnswer.multipleResponse"}
                    render={({field: {onChange, onBlur, value, name}}) => (
                        <RadioGroup
                            title={intl.formatMessage({id: "multipleResponse"})}
                            name={name}
                            disabled={loading}
                            error={
                                errors.multipleChoiceAnswer?.multipleResponse
                                && errors.multipleChoiceAnswer.multipleResponse.message
                            }
                            options={[
                                {
                                    label: intl.formatMessage({id: "yes"}),
                                    checked: value === true,
                                    onChange: () => {
                                        onChange(true);
                                        setValue("multipleChoiceAnswer.withImages", false);
                                    }
                                },
                                {
                                    label: intl.formatMessage({id: "no"}),
                                    checked: value === false,
                                    onChange: () => {
                                        onChange(false);
                                        for (let i = 0; i < fields.length; i++) {
                                            setValue(`multipleChoiceAnswer.answers.${i}.correct`, false);
                                        }
                                    }
                                }
                            ]}
                            onBlur={onBlur}
                        />
                    )}
                />
            </div>

            <div className={"w-full"}>
                <div className={"flex flex-col"}>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        <FormattedMessage id={"multipleChoiceOptions"}/>
                    </label>

                    <div className={"w-full overflow-x-auto mb-3"}>
                        {
                            fields.map((a, index) =>
                                <Option
                                    key={a.id}
                                    loading={loading}
                                    index={index}
                                    remove={remove}
                                    register={register}
                                    watch={watch}
                                    errors={errors}
                                    setValue={setValue}
                                    totalOptions={fields.length}
                                    withImages={watch("multipleChoiceAnswer.withImages") === true}
                                    multipleResponse={watch("multipleChoiceAnswer.multipleResponse") === true}
                                    answerRequired={selectedAnswerType === SelectedAnswerType.MULTIPLE_CHOICE}
                                />)
                        }
                    </div>
                </div>
            </div>

            <div>
                <Button
                    type={"white"}
                    text={intl.formatMessage({id: "add"})}
                    icon={PlusIcon}
                    disabled={loading}
                    onClick={e => {
                        e.preventDefault();
                        append({
                            id: undefined,
                            answerNl: "",
                            answerAr: "",
                            correct: false,
                            orderNr: fields.length + 1,
                            image: null
                        });
                    }}
                />
            </div>
        </>
    );
};

export default MultipleChoiceAnswer;