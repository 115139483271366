import React from "react";
import {BreadcrumbsContent, PageHeading} from "peggirkit";
import {breadcrumbItemCategories, breadcrumbItemDashboard} from "../../../routing/Router";
import {PlusIcon} from "@heroicons/react/24/outline";
import {useIntl} from "react-intl";
import {EditCategoryOptions} from "./CategoriesOverview";

type Props = {
    editCategoryOptions: EditCategoryOptions,
    setEditCategoryOptions: (options: EditCategoryOptions) => void
};

const CategoriesPageHeading = ({editCategoryOptions, setEditCategoryOptions}: Props) => {
    const intl = useIntl();
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemCategories]
    };

    return (
        <PageHeading
            breadcrumbsContent={breadcrumbs}
            title={breadcrumbItemCategories.name}
            actions={{
                moreButtonText: intl.formatMessage({id: "more"}),
                actions: [{
                    actionType: "button",
                    text: intl.formatMessage({id: "add"}),
                    icon: PlusIcon,
                    primary: true,
                    onClick: () => setEditCategoryOptions({
                        ...editCategoryOptions,
                        showDialog: true,
                        category: null
                    }),
                }]
            }}
        />
    );
};

export default CategoriesPageHeading;