import React from "react";
import {breadcrumbItemStatistics} from "../../../routing/Router";
import TheorieGoFrame from "./layout/TheorieGoFrame";
import StatisticsOverview from "../../widgets/statistics/StatisticsOverview";

const Statistics = () => {
    return (
        <TheorieGoFrame title={breadcrumbItemStatistics.name}>
            <StatisticsOverview/>
        </TheorieGoFrame>
    );
};

export default Statistics;