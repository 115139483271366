import React from "react";
import {useIntl} from "react-intl";
import {Payload} from "recharts/types/component/DefaultTooltipContent";
import {formatDate} from "../../../../../../utils/DateUtils";
import {formatMoney} from "../../../../../../utils/MoneyUtils";
import ChartTooltip from "../../../ChartTooltip";

type Props = {
    active?: boolean,
    payload?: Payload<number, string>[],
    label?: string
};

const RevenueChartTooltip = ({active, payload, label}: Props) => {
    const intl = useIntl();

    if (!active || !payload || !payload.length) {
        return;
    }

    return (
        <ChartTooltip
            title={formatDate(label)}
            values={[
                `${intl.formatMessage({id: "numberOfSales"})}: ${payload[0].value}`,
                `${intl.formatMessage({id: "revenueInclTax"})}: ${formatMoney(payload[1].value || 0)}`
            ]}
        />
    );
};

export default RevenueChartTooltip;