import React, {useEffect, useState} from 'react';
import {OverviewExamQuestion, QuestionInExamDetails} from "../../../../../../data/Entities";
import {TableIconButton} from "peggirkit";
import {MinusCircleIcon, PlusCircleIcon} from "@heroicons/react/24/outline";
import {QuestionFilterSection} from "../../../../questions/overview/QuestionsOverview";
import {UseFormWatch} from "react-hook-form";
import {ExamQuestionOperations} from "./QuestionsDataTable";
import {ExamDetailsForm} from "../../EditExamContainer";
import {useIntl} from "react-intl";

type Props = {
    watch: UseFormWatch<ExamDetailsForm>,
    question: OverviewExamQuestion,
    sectionFilter: QuestionFilterSection,
}

const AddToExamFormatter = ({
                                watch,
                                question,
                                sectionFilter,
                                hrRemove,
                                hrAppend,
                                kRemove,
                                kAppend,
                                iRemove,
                                iAppend,
                            }: Props & ExamQuestionOperations) => {
    const intl = useIntl();
    const [ixHr, setIxHr] = useState(-1);
    const [ixK, setIxK] = useState(-1);
    const [ixI, setIxI] = useState(-1);
    const [inExam, setInExam] = useState(false);

    useEffect(() => {
        const ixHr = watch("hazardRecognitionQuestions").findIndex(qie => qie.question.id === question.id);
        const ixK = watch("knowledgeQuestions").findIndex(qie => qie.question.id === question.id);
        const ixI = watch("insightQuestions").findIndex(qie => qie.question.id === question.id);
        const inExam = ixHr !== -1 || ixK !== -1 || ixI !== -1;

        setIxHr(ixHr);
        setIxK(ixK);
        setIxI(ixI);
        setInExam(inExam);
    }, [watch("hazardRecognitionQuestions"), watch("knowledgeQuestions"), watch("insightQuestions")]);

    const removeFromExam = () => {
        if (sectionFilter === QuestionFilterSection.HAZARD_RECOGNITION) {
            hrRemove(ixHr);
        } else if (sectionFilter === QuestionFilterSection.KNOWLEDGE) {
            kRemove(ixK);
        } else if (sectionFilter === QuestionFilterSection.INSIGHT) {
            iRemove(ixI);
        }
    };

    const addToExam = () => {
        const newQuestionInExam: QuestionInExamDetails = {
            question: question,
            hazardRecognitionSection: sectionFilter === QuestionFilterSection.HAZARD_RECOGNITION,
            knowledgeSection: sectionFilter === QuestionFilterSection.KNOWLEDGE,
            insightSection: sectionFilter === QuestionFilterSection.INSIGHT,
            id: null,
            orderNr: -1
        };

        if (sectionFilter === QuestionFilterSection.HAZARD_RECOGNITION) {
            hrAppend(newQuestionInExam);
        } else if (sectionFilter === QuestionFilterSection.KNOWLEDGE) {
            kAppend(newQuestionInExam);
        } else if (sectionFilter === QuestionFilterSection.INSIGHT) {
            iAppend(newQuestionInExam);
        }
    };

    const toggleAddToExam = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        if (inExam) {
            removeFromExam();
            return;
        }

        addToExam();
    };

    return (
        <TableIconButton
            type={inExam ? "danger" : "default"}
            title={inExam ? intl.formatMessage({id: "remove"}) : intl.formatMessage({id: "add"})}
            icon={inExam ? MinusCircleIcon : PlusCircleIcon}
            href={"#"}
            onClick={toggleAddToExam}
        />
    );
};

export default AddToExamFormatter;