import React, {useEffect, useState} from "react";
import {Alert, BreadcrumbItem, Card, isEmpty, NotificationService, SlideOver, useWindowDimensions} from "peggirkit";
import {BreadcrumbsContent} from "peggirkit/dist/components/Heading/Page/Breadcrumbs";
import {FaqQuestionDetails} from "../../../../data/Entities";
import PageHeading from "./PageHeading";
import {Control, useForm, UseFormRegister, UseFormWatch} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import ContentCard from "./content/ContentCard";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import EditMetadata from "./metadata/EditMetadata";
import {upsertFaqQuestion} from "../../../../data/ApiEndpoints";
import router from "../../../../routing/Router";

type Props = {
    breadcrumbs: BreadcrumbsContent,
    currentBreadcrumbItem: BreadcrumbItem,
    loadingData: boolean,
    existingQuestion?: FaqQuestionDetails,
    setExistingQuestion: (q: FaqQuestionDetails) => void,
    faqSectionId?: string | number,
    faqQuestionId?: string,
};

export type EditFaqQuestionProps = {
    loading: boolean,
    register: UseFormRegister<FaqQuestionDetails>
    control: Control<FaqQuestionDetails>,
    errors: FieldErrors<FaqQuestionDetails>
    watch: UseFormWatch<FaqQuestionDetails>
};

const EditExamContainer = ({
                               breadcrumbs,
                               currentBreadcrumbItem,
                               loadingData,
                               existingQuestion,
                               setExistingQuestion,
                               faqSectionId,
                               faqQuestionId,
                           }: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const {innerWidth: windowWidth} = useWindowDimensions();
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [metadataSliderOpen, setMetadataSliderOpen] = useState(false);
    const {
        register,
        control,
        getValues,
        watch,
        handleSubmit,
        reset,
        formState: {errors}
    } = useForm<FaqQuestionDetails>({
        defaultValues: existingQuestion
            ? {...existingQuestion}
            : {id: undefined}
    });

    // Reset default values for new question
    useEffect(() => {
        if (!existingQuestion) {
            reset();
        }
    }, [existingQuestion]);

    // Save question
    useEffect(() => {
        if (!loading || faqSectionId === undefined) {
            return;
        }

        const values: FaqQuestionDetails = getValues();

        // React-hook-form doesn't validate the tab page that isn't visible, so
        // we need to validate it manually.
        setFormError(false);
        if (isEmpty(values.questionNl)
            || isEmpty(values.questionAr)
            || isEmpty(values.answerNl)
            || isEmpty(values.answerAr)) {
            setFormError(true);
            setLoading(false);
            return;
        }

        upsertFaqQuestion({
            ...values,
            id: faqQuestionId === undefined ? null : parseInt(faqQuestionId),
            featuredOrderNr: isNaN(values.featuredOrderNr as any) ? null : values.featuredOrderNr,
            faqSection: {
                // @ts-ignore
                id: parseInt(faqSectionId),
            },
        }).then(data => {
            if (watch("id") === undefined || watch("id") === null) {
                navigate(router.editFaqQuestion.absolutePath(data.id));
            }

            NotificationService.notify({type: "success", text: intl.formatMessage({id: "postingSavedNotification"})});
            setExistingQuestion(data);
            setLoading(false);
        }).catch(_ => {
            NotificationService.notify({type: "danger", text: intl.formatMessage({id: "operationError"})});
            setLoading(false);
        });
    }, [loading]);

    const editProps: EditFaqQuestionProps = {
        loading: loading,
        register: register,
        errors: errors,
        watch: watch,
        control: control,
    };

    return (
        <>
            <PageHeading
                breadcrumbs={breadcrumbs}
                currentBreadcrumbItem={currentBreadcrumbItem}
                handleSubmit={handleSubmit}
                loading={loading || loadingData}
                setLoading={setLoading}
                existingQuestion={existingQuestion}
            />

            <div className={"mt-4 md:mt-12 grid grid-cols-1 xl:grid-cols-3 gap-6 xl:gap-12"}>
                <div className={"xl:col-span-3"}>
                    {formError && <Alert
                        type={"danger"}
                        title={intl.formatMessage({id: "cannotSave"})}
                        description={intl.formatMessage({id: "enterAllRequiredFields"})}
                    />}
                </div>

                <div className={"col-span-1 xl:col-span-2"}>
                    <ContentCard
                        setMetadataSliderOpen={setMetadataSliderOpen}
                        {...editProps}
                    />
                </div>

                {
                    windowWidth <= 1280
                        ? (
                            <SlideOver
                                open={metadataSliderOpen}
                                setOpen={setMetadataSliderOpen}
                                title={intl.formatMessage({id: "metadata"})}
                            >
                                <EditMetadata {...editProps}/>
                            </SlideOver>
                        )
                        : (
                            <div className={"col-span-1"}>
                                <Card header={{title: intl.formatMessage({id: "metadata"})}}>
                                    <EditMetadata {...editProps}/>
                                </Card>
                            </div>
                        )
                }
            </div>
        </>
    );
};

export default EditExamContainer;