import React from "react";
import {Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatToShortDate} from "../../../../../utils/DateUtils";
import {formatMoney} from "../../../../../utils/MoneyUtils";
import RevenueChartTooltip from "./chart/RevenueChartTooltip";
import {Card, classNames} from "peggirkit";
import {useIntl} from "react-intl";
import {RevenueStats} from "../../../../../data/Entities";
import {StatisticsSettings} from "../../StatisticsOverview";

export const axisStyling = {
    fontSize: "0.75rem",
    fill: "rgb(107 114 128)"
};

type Props = {
    dateRange: StatisticsSettings,
    revenueStats: RevenueStats | undefined,
};

const RevenueCard = ({dateRange, revenueStats}: Props) => {
    const intl = useIntl();
    return (
        <Card header={{title: intl.formatMessage({id: "revenueAndSales"})}}>
            <div
                className={classNames(
                    "w-full h-[250px] lg:h-[250px]",
                    dateRange.loadingSaleStats ? "animate-pulse" : ""
                )}
            >
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        width={500}
                        height={300}
                        data={revenueStats?.revenuePerDay || []}
                    >
                        <CartesianGrid
                            stroke="#e5e7eb"
                            strokeDasharray="5 5"
                        />
                        <XAxis
                            tick={axisStyling}
                            dataKey="date"
                            tickMargin={12}
                            tickLine={false}
                            strokeWidth={3}
                            stroke={"#e5e7eb"}
                            tickFormatter={val => formatToShortDate(val)}
                        />
                        <YAxis
                            tick={axisStyling}
                            yAxisId="left"
                            tickLine={false}
                            axisLine={false}
                            tickFormatter={(val) => formatMoney(val)}
                        />
                        <YAxis
                            tick={axisStyling}
                            yAxisId="right"
                            orientation="right"
                            allowDecimals={false}
                            tickLine={false}
                            axisLine={false}
                            width={15}
                        />
                        <Tooltip content={RevenueChartTooltip}/>
                        <Legend wrapperStyle={{fontSize: "0.75rem"}}/>
                        <Bar
                            yAxisId={"right"}
                            dataKey="salesCount"
                            name={intl.formatMessage({id: "numberOfSales"})}
                            barSize={10}
                            radius={[6, 6, 0, 0]}
                            fill="#94a3b8"
                        />
                        <Line
                            yAxisId={"left"}
                            type="monotone"
                            dataKey="totalPriceInTax"
                            name={intl.formatMessage({id: "revenueInclTax"})}
                            stroke="#cc9900"
                            strokeWidth={3}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </Card>
    );
};

export default RevenueCard;