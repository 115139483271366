import React from "react";
import ReactDOM from "react-dom/client";
import "./styling/App.css";
import "@fontsource/inter/variable-full.css";
import {createBrowserRouter, Navigate, Outlet, RouterProvider} from "react-router-dom";
import DutchMessages from "./messages/nl-NL";
import {IntlProvider} from "react-intl";
import SignIn from "./component/page/unauthenticated/SignIn";
import Dashboard from "./component/page/authenticated/Dashboard";
import Error from "./component/page/unauthenticated/Error";
import {getCurrentUser, signIn, signOut} from "./data/ApiEndpoints";
import Account from "./component/page/authenticated/Account";
import ResetPassword from "./component/page/unauthenticated/ResetPassword";
import ResetPasswordConfirmation from "./component/page/unauthenticated/ResetPasswordConfirmation";
import Students from "./component/page/authenticated/Students";
import Database from "./component/page/authenticated/Database";
import Student from "./component/page/authenticated/Student";
import Categories from "./component/page/authenticated/Categories";
import EditQuestion from "./component/page/authenticated/EditQuestion";
import Questions from "./component/page/authenticated/Questions";
import Exams from "./component/page/authenticated/Exams";
import EditExam from "./component/page/authenticated/EditExam";
import ContactForms from "./component/page/authenticated/ContactForms";
import ContactForm from "./component/page/authenticated/ContactForm";
import FaqSections from "./component/page/authenticated/FaqSections";
import EditFaqQuestion from "./component/page/authenticated/EditFaqQuestion";
import Statistics from "./component/page/authenticated/Statistics";
import Blog from "./component/page/authenticated/blog/Blog";
import BlogPost from "./component/page/authenticated/blog/BlogPost";
import router from "./routing/Router";
import {AuthProvider, ProtectedRoute} from "peggirkit";

const routes = createBrowserRouter([
    {
        element: (
            <IntlProvider
                locale="nl-NL"
                messages={DutchMessages}
            >
                <AuthProvider
                    // @ts-ignore
                    signIn={signIn}
                    signOut={signOut}
                    getCurrentUser={getCurrentUser}
                    onSignInRedirectTo={router.index.absolutePath()}
                    onSignOutRedirectTo={router.signIn.absolutePath()}
                >
                    <Outlet/>
                </AuthProvider>
            </IntlProvider>
        ),
        errorElement: <Error/>,
        children: [
            {
                path: router.auth.relativePath,
                children: [
                    {
                        path: router.signIn.relativePath,
                        element: <SignIn/>,
                    },
                    {
                        path: router.reset.relativePath,
                        children: [
                            {
                                index: true,
                                element: <ResetPassword/>,
                            },
                            {
                                path: router.confirmReset.relativePath,
                                element: <ResetPasswordConfirmation/>,
                            },
                        ]
                    },
                ],
            },
            {
                path: router.index.relativePath,
                element: <Navigate to={router.dashboard.relativePath}/>
            },
            {
                path: router.dashboard.relativePath,
                element: (
                    <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                        <Dashboard/>
                    </ProtectedRoute>
                ),
            },
            {
                path: router.account.relativePath,
                element: (
                    <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                        <Account/>
                    </ProtectedRoute>
                ),
            },
            {
                path: router.students.relativePath,
                children: [
                    {
                        index: true,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <Students/>
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: router.student.relativePath,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <Student/>
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: router.newStudent.relativePath,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <Student/>
                            </ProtectedRoute>
                        ),
                    },
                ],
            },
            {
                path: router.blog.relativePath,
                children: [
                    {
                        index: true,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <Blog/>
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: router.blogPost.relativePath,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <BlogPost/>
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: router.newBlogPost.relativePath,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <BlogPost/>
                            </ProtectedRoute>
                        ),
                    },
                ],
            },
            {
                path: router.database.relativePath,
                element: (
                    <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                        <Database/>
                    </ProtectedRoute>
                ),
            },
            {
                path: router.categories.relativePath,
                element: (
                    <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                        <Categories/>
                    </ProtectedRoute>
                ),
            },
            {
                path: router.questions.relativePath,
                children: [
                    {
                        index: true,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <Questions/>
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: router.newQuestion.relativePath,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <EditQuestion/>
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: router.editQuestion.relativePath,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <EditQuestion/>
                            </ProtectedRoute>
                        ),
                    },
                ],
            },
            {
                path: router.exams.relativePath,
                children: [
                    {
                        index: true,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <Exams/>
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: router.newExam.relativePath,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <EditExam/>
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: router.editExam.relativePath,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <EditExam/>
                            </ProtectedRoute>
                        ),
                    },
                ],
            },
            {
                path: router.contactForms.relativePath,
                children: [
                    {
                        index: true,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <ContactForms/>
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: router.contactForm.relativePath,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <ContactForm/>
                            </ProtectedRoute>
                        ),
                    },
                ],
            },
            {
                path: router.faq.relativePath,
                children: [
                    {
                        index: true,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <FaqSections/>
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: router.newFaqQuestion.relativePath,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <EditFaqQuestion/>
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: router.editFaqQuestion.relativePath,
                        element: (
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <EditFaqQuestion/>
                            </ProtectedRoute>
                        ),
                    },
                ],
            },
            {
                path: router.statistics.relativePath,
                element: (
                    <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                        <Statistics/>
                    </ProtectedRoute>
                ),
            },
        ],
    },
]);

// @ts-ignore
ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={routes}/>
);