import React, {useEffect, useState} from "react";
import {
    ArrowLeftOnRectangleIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    CircleStackIcon,
    Cog6ToothIcon,
    DocumentTextIcon,
    EnvelopeIcon,
    HomeIcon,
    PencilSquareIcon,
    PresentationChartLineIcon,
    QuestionMarkCircleIcon,
    Squares2X2Icon,
    UsersIcon
} from "@heroicons/react/24/outline";
import {
    breadcrumbItemAccount,
    breadcrumbItemBlog,
    breadcrumbItemCategories,
    breadcrumbItemContactForms,
    breadcrumbItemDashboard,
    breadcrumbItemDatabase,
    breadcrumbItemExams,
    breadcrumbItemFaq,
    breadcrumbItemQuestions,
    breadcrumbItemStatistics,
    breadcrumbItemStudents
} from "../../../../routing/Router";
import {DashboardFrame, Icon, PrimaryNavigationItemProps, SecondaryNavigationItemProps, useAuth} from "peggirkit";
import {useIntl} from "react-intl";
import {getContactFormsOpenCount} from "../../../../data/ApiEndpoints";

const logo = require("../../../../assets/img/theoriego-small.svg");

type Props = {
    title: string,
    children: JSX.Element[] | JSX.Element
};

export const getNavItems = (openContactForms: number | null): PrimaryNavigationItemProps[] => ([
    {
        name: breadcrumbItemStatistics.name,
        href: breadcrumbItemStatistics.href,
        icon: PresentationChartLineIcon as Icon,
        current: false
    },
    {
        name: breadcrumbItemBlog.name,
        href: breadcrumbItemBlog.href,
        icon: PencilSquareIcon as Icon,
        current: false
    },
    {
        name: breadcrumbItemCategories.name,
        href: breadcrumbItemCategories.href,
        icon: Squares2X2Icon as Icon,
        current: false
    },
    {
        name: breadcrumbItemExams.name,
        href: breadcrumbItemExams.href,
        icon: DocumentTextIcon as Icon,
        current: false
    },
    {
        name: breadcrumbItemQuestions.name,
        href: breadcrumbItemQuestions.href,
        icon: QuestionMarkCircleIcon as Icon,
        current: false
    },
    {
        name: breadcrumbItemStudents.name,
        href: breadcrumbItemStudents.href,
        icon: UsersIcon as Icon,
        current: false
    },
    {
        name: `${breadcrumbItemContactForms.name} ${openContactForms === null ? "" : `(${openContactForms})`}`,
        href: breadcrumbItemContactForms.href,
        icon: EnvelopeIcon as Icon,
        current: false,
    },
    {
        name: breadcrumbItemFaq.name,
        href: breadcrumbItemFaq.href,
        icon: ChatBubbleOvalLeftEllipsisIcon as Icon,
        current: false
    },
    {
        name: breadcrumbItemDatabase.name,
        href: breadcrumbItemDatabase.href,
        icon: CircleStackIcon as Icon,
        current: false
    },
    {
        name: breadcrumbItemAccount.name,
        href: breadcrumbItemAccount.href,
        icon: Cog6ToothIcon as Icon,
        current: false
    },
]);

const TheorieGoFrame = ({title, children}: Props) => {
    const intl = useIntl();
    const [isSigningOut, setIsSigningOut] = useState(false);
    const [contactFormCount, setContactFormCount] = useState<number | null>(null);
    const authContext = useAuth();

    // Retrieve contact form count
    useEffect(() => {
        getContactFormsOpenCount().then(c => setContactFormCount(c));
    }, []);

    // Sign out
    useEffect(() => {
        if (!isSigningOut) {
            return;
        }

        authContext?.onSignOut().catch(() => {
            setIsSigningOut(false);
        });
    }, [isSigningOut]);

    const navigation: PrimaryNavigationItemProps[] = [
        {
            name: breadcrumbItemDashboard.name,
            href: breadcrumbItemDashboard.href,
            icon: HomeIcon as Icon,
            current: false
        },
        ...getNavItems(contactFormCount),
        {
            name: intl.formatMessage({id: "signOut"}),
            onClick: () => setIsSigningOut(true),
            icon: ArrowLeftOnRectangleIcon as Icon,
            current: false
        },
    ];

    const secondaryNavigation: SecondaryNavigationItemProps[] = [
        {
            name: intl.formatMessage({id: "homepage"}),
            href: process.env.LANDING_BASE_URL || ""
        },
        {
            name: intl.formatMessage({id: "drivingSchoolEasyGo"}),
            href: "https://autorijschooleasygo.nl/"
        },
    ];

    return (
        <DashboardFrame
            appName={intl.formatMessage({id: "appName"})}
            title={title}
            logo={logo}
            primaryNavigation={navigation}
            secondaryNavigation={secondaryNavigation}
            secondaryNavigationTitle={intl.formatMessage({id: "external"})}
        >
            {children}
        </DashboardFrame>
    );
};

export default TheorieGoFrame;