import {createIntl, createIntlCache} from "react-intl";
import DutchMessages from "../../../../../../messages/nl-NL";

const cache = createIntlCache();
const intl = createIntl({locale: 'nl-NL', messages: DutchMessages}, cache);

export enum SelectedAnswerType {
    NONE = "",
    MULTIPLE_CHOICE = "0",
    YES_NO = "1",
    NUMBER = "2",
    DRAG_DROP = "3",
}

export type SelectedAnswerTypeOption = {
    value: SelectedAnswerType,
    label: string,
    disabled?: boolean
};

export const selectedAnswerTypeOptions: SelectedAnswerTypeOption[] = [
    {
        value: SelectedAnswerType.NONE,
        label: intl.formatMessage({id: "pickAnAnswerType"}),
        disabled: true
    },
    {
        value: SelectedAnswerType.MULTIPLE_CHOICE,
        label: intl.formatMessage({id: "multipleChoice"})
    },
    {
        value: SelectedAnswerType.YES_NO,
        label: intl.formatMessage({id: "yesNo"})
    },
    {
        value: SelectedAnswerType.NUMBER,
        label: intl.formatMessage({id: "number"})
    },
    {
        value: SelectedAnswerType.DRAG_DROP,
        label: intl.formatMessage({id: "dragDropQuestion"})
    },
];

export const findSelectedAnswerTypeOption = (type: SelectedAnswerType): SelectedAnswerTypeOption => {
    return selectedAnswerTypeOptions.filter(o => o.value === type)[0];
};

