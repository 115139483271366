import React, {useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {
    Badge,
    DataTable,
    DataTableColumn,
    Icon,
    NotificationService,
    TableButton,
    TableColumn,
    TableData
} from "peggirkit";
import router from "../../../../routing/Router";
import {theorieGoAxios} from "../../../../data/ApiEndpoints";
import {TrashIcon} from "@heroicons/react/24/outline";
import DeleteBlogPostsModal from "../DeleteBlogPostsModal";
import {BlogPost} from "../../../../data/Entities";

type DeleteBlogPostsOptions = {
    showDialog: boolean,
    selectedBlogPostsToDelete: BlogPost[]
};

const BlogDataTable = () => {
    const intl = useIntl();
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const [deleteBlogPostsOptions, setDeleteBlogPostsOptions] = useState<DeleteBlogPostsOptions>({
        showDialog: false,
        selectedBlogPostsToDelete: [],
    });

    const columns: (TableColumn & DataTableColumn)[] = useMemo(() => {
        return [
            {
                dataName: "id",
                name: intl.formatMessage({id: "id"}),
                sortable: true,
                secondary: true
            },
            {
                dataName: "title",
                name: intl.formatMessage({id: "title"}),
                sortable: true,
                secondary: false,
            },
            {
                dataName: "publishDate",
                name: intl.formatMessage({id: "published"}),
                sortable: true,
                secondary: true,
                alignRight: true,
                formatter: (publishDateString: string) => {
                    const publishDate = new Date(publishDateString);
                    const isPublished = publishDate.getTime() < new Date().getTime();
                    return <Badge
                        type={isPublished ? "green" : "red"}
                        content={intl.formatMessage({id: isPublished ? "public" : "scheduled"})}
                        tooltip={publishDate.toLocaleString()}
                    />
                }
            },
            {
                dataName: "creationDate",
                name: intl.formatMessage({id: "creationDate"}),
                sortable: true,
                secondary: true,
                alignRight: true,
                sorted: "desc",
                formatter: (creationDate: string) => new Date(creationDate).toLocaleString()
            },
            {
                hideLabel: true,
                name: intl.formatMessage({id: "options"}),
                sortable: false,
                alignRight: true,
                secondary: true,
                formatter: (_, blogPost): JSX.Element => (
                    <TableButton
                        text={intl.formatMessage({id: "view"})}
                        href={router.blogPost.absolutePath(blogPost.id)}
                    />
                )
            }
        ]
    }, []);

    return (
        <>
            <DataTable
                initialLastUpdated={lastUpdated}
                withUrlPageParameter={true}
                scrollToTopOnPageChange={true}
                initialPageRequest={{
                    axios: theorieGoAxios,
                    baseUrl: `${process.env.BACKEND_API_BASE_URL}/admin/blog`,
                    pageSize: 20,
                    pageNumber: 0,
                }}
                columns={columns}
                summaryFormatter={(from: number, to: number, total: number): JSX.Element | string => (
                    intl.formatMessage({id: "paginationSummary"}, {from: from, to: to, total: total})
                )}
                messages={{
                    next: intl.formatMessage({id: "next"}),
                    previous: intl.formatMessage({id: "previous"})
                }}
                batchActions={{
                    dropdownButtonText: (itemsSelected: number) =>
                        intl.formatMessage({id: "nItemsSelected"}, {n: itemsSelected}),
                    toggleSelectAllText: intl.formatMessage({id: "selectAll"}),
                    selectRowText: intl.formatMessage({id: "selectRow"}),
                    actions: [
                        [
                            {
                                type: "danger",
                                icon: TrashIcon as Icon,
                                text: intl.formatMessage({id: "delete"}),
                                disabled: false,
                                onClick: (rawData: TableData[]) => {
                                    setDeleteBlogPostsOptions({
                                        ...deleteBlogPostsOptions,
                                        selectedBlogPostsToDelete: rawData as BlogPost[],
                                        showDialog: true
                                    });
                                }
                            }
                        ]
                    ]
                }}
                alwaysShowToolbar={false}
            />

            <DeleteBlogPostsModal
                show={deleteBlogPostsOptions.showDialog}
                setShow={(show: boolean) => setDeleteBlogPostsOptions({...deleteBlogPostsOptions, showDialog: show})}
                blogPosts={deleteBlogPostsOptions.selectedBlogPostsToDelete}
                cb={() => {
                    setLastUpdated(new Date());
                    NotificationService.notify({
                        type: "success",
                        text: intl.formatMessage(
                            {id: "nBlogPostsDeleted"},
                            {n: deleteBlogPostsOptions.selectedBlogPostsToDelete.length})
                    });
                    setDeleteBlogPostsOptions({
                        ...deleteBlogPostsOptions,
                        showDialog: false,
                        selectedBlogPostsToDelete: []
                    });
                }}
            />
        </>
    );
};

export default BlogDataTable;