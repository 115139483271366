import React from "react";
import {OverviewExam} from "../../../../../../data/Entities";
import {Badge} from "peggirkit";

type Props = {
    exam: OverviewExam
};

const NameFormatter = ({exam}: Props) => {
    if (!exam.demo) {
        return (
            <>
                {exam.nameNl}
            </>
        )
    }

    return (
        <>
            <span className={"mr-2"}>
                {exam.nameNl}
            </span>
            <Badge type={"gray"} content={"Demo"}/>
        </>
    );

};

export default NameFormatter;