import React, {useEffect, useState} from "react";
import PageHeading from "./PageHeading";
import QuestionsDataTable from "./dataTable/QuestionsDataTable";
import {ExamCategory} from "../../../../data/Entities";
import {listCategories} from "../../../../data/ApiEndpoints";
import {QuestionFilterCategory} from "../../categories/CategoryFilterOptions";

export type QuestionFilterConcept = boolean | null;

export enum QuestionFilterSection {
    ANY = "ANY",
    NONE = "NONE",
    HAZARD_RECOGNITION = "HAZARD_RECOGNITION",
    KNOWLEDGE = "KNOWLEDGE",
    INSIGHT = "INSIGHT",
}

const QuestionsOverview = () => {
    const [conceptFilter, setConceptFilter] = useState<QuestionFilterConcept>(null);
    const [sectionFilter, setSectionFilter] = useState<QuestionFilterSection>(QuestionFilterSection.ANY);
    const [categoryFilter, setCategoryFilter] = useState<QuestionFilterCategory>(null);
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState<ExamCategory[]>([]);

    // Load categories
    useEffect(() => {
        listCategories().then(page => {
            setCategories(page.content as ExamCategory[]);
            setLoading(false);
        });
    }, []);

    return (
        <>
            <PageHeading
                conceptFilter={conceptFilter}
                setConceptFilter={setConceptFilter}
                sectionFilter={sectionFilter}
                setSectionFilter={setSectionFilter}
                categoryFilter={categoryFilter}
                setCategoryFilter={setCategoryFilter}
                categories={categories}
                loading={loading}
            />

            <div className={"mt-8 md:mt-16"}>
                <QuestionsDataTable
                    conceptFilter={conceptFilter}
                    sectionFilter={sectionFilter}
                    categoryFilter={categoryFilter}
                />
            </div>
        </>
    );
};

export default QuestionsOverview;