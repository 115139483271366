import React from "react";
import {breadcrumbItemContactForms, breadcrumbItemDashboard} from "../../../routing/Router";
import {CheckIcon, FunnelIcon} from "@heroicons/react/24/outline";
import {BreadcrumbsContent, Icon, PageHeading} from "peggirkit";
import {IntlShape, useIntl} from "react-intl";
import {StatusOpenFilter} from "./ContactFormsOverview";

type Props = {
    statusOpenFilter: StatusOpenFilter,
    setStatusOpenFilter: (open: StatusOpenFilter) => void
};

export const statusOpenFilterToString = (intl: IntlShape, statusOpenFilter: StatusOpenFilter): string => {
    if (statusOpenFilter === true) {
        return intl.formatMessage({id: "open"});
    }

    if (statusOpenFilter === false) {
        return intl.formatMessage({id: "closed"});
    }

    return intl.formatMessage({id: "all"});
};

const ContactFormsOverviewPageHeading = ({statusOpenFilter, setStatusOpenFilter}: Props) => {
    const intl = useIntl();
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemContactForms]
    };

    return (
        <PageHeading
            breadcrumbsContent={breadcrumbs}
            title={breadcrumbItemContactForms.name}
            actions={{
                moreButtonText: intl.formatMessage({id: "more"}),
                disabled: false,
                actions: [
                    {
                        type: statusOpenFilter !== null ? "whiteBlueAccent" : undefined,
                        actionType: "dropdown",
                        text: statusOpenFilterToString(intl, statusOpenFilter),
                        primary: false,
                        iconOnly: false,
                        icon: FunnelIcon as Icon,
                        options: [
                            [
                                {
                                    disabled: statusOpenFilter === null,
                                    icon: statusOpenFilter === null ? CheckIcon as Icon : undefined,
                                    text: statusOpenFilterToString(intl, null),
                                    onClick: () => setStatusOpenFilter(null)
                                }
                            ],
                            [
                                {
                                    disabled: statusOpenFilter === true,
                                    icon: statusOpenFilter === true
                                        ? CheckIcon as Icon
                                        : undefined,
                                    text: statusOpenFilterToString(intl, true),
                                    onClick: () => setStatusOpenFilter(true)
                                },
                                {
                                    disabled: statusOpenFilter === false,
                                    icon: statusOpenFilter === false ? CheckIcon as Icon : undefined,
                                    text: statusOpenFilterToString(intl, false),
                                    onClick: () => setStatusOpenFilter(false)
                                },
                            ],
                        ]
                    },
                    {
                        actionType: "button",
                        primary: true,
                        text: intl.formatMessage({id: "noAction"}),
                        onClick: () => {},
                    }
                ]
            }}
        />
    );
};

export default ContactFormsOverviewPageHeading;