import React, {useState} from "react";
import {Alert, NotificationService} from "peggirkit";
import CategoriesDataTable from "./CategoriesDataTable";
import {useIntl} from "react-intl";
import {ExamCategory} from "../../../data/Entities";
import CategoriesPageHeading from "./CategoriesPageHeading";
import EditCategoryModal from "./EditCategoryModal";

export type EditCategoryOptions = {
    showDialog: boolean,
    category: ExamCategory | null
};

const CategoriesOverview = () => {
    const intl = useIntl();
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const [editCategoryOptions, setEditCategoryOptions] = useState<EditCategoryOptions>({
        showDialog: false,
        category: null,
    });

    return (
        <>
            <CategoriesPageHeading
                editCategoryOptions={editCategoryOptions}
                setEditCategoryOptions={setEditCategoryOptions}
            />

            <Alert
                className={"mt-8"}
                type={"info"}
                title={intl.formatMessage({id: "examCategories"})}
                description={intl.formatMessage({id: "examCategories.explanation"})}
            />

            <CategoriesDataTable
                editCategoryOptions={editCategoryOptions}
                setEditCategoryOptions={setEditCategoryOptions}
                lastUpdated={lastUpdated}
                setLastUpdated={setLastUpdated}
            />

            <EditCategoryModal
                show={editCategoryOptions.showDialog}
                setShow={(show: boolean) => setEditCategoryOptions({...editCategoryOptions, showDialog: show})}
                category={editCategoryOptions.category}
                cb={() => {
                    setLastUpdated(new Date());
                    NotificationService.notify({
                        type: "success",
                        text: intl.formatMessage({id: "updatedCategories"})
                    });
                    setEditCategoryOptions({...editCategoryOptions, showDialog: false});
                }}
            />
        </>
    );
};

export default CategoriesOverview;