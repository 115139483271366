import React, {useEffect, useState} from "react";
import {capitalizeFirstLetter, DescriptionList, SectionHeading, useAuth} from "peggirkit";
import {User} from "../../../data/Entities";
import {useIntl} from "react-intl";

const AccountTabGeneral = () => {
    const intl = useIntl();
    const authContext = useAuth();
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        authContext?.currentUser().then(user => {
            setUser(user);
        });
    }, []);

    return (
        <>
            <SectionHeading
                title={intl.formatMessage({id: "general"})}
                description={intl.formatMessage({id: "generalAccountDetails"})}
            />
            <DescriptionList
                items={[
                    {
                        key: intl.formatMessage({id: "emailAddress"}),
                        value: user === null ? undefined : capitalizeFirstLetter(user?.emailAddress)
                    }
                ]}
            />
        </>
    );
};

export default AccountTabGeneral;