import React from "react";
import {breadcrumbItemQuestions} from "../../../routing/Router";
import TheorieGoFrame from "./layout/TheorieGoFrame";
import QuestionsOverview from "../../widgets/questions/overview/QuestionsOverview";

const Questions = () => {
    return (
        <TheorieGoFrame title={breadcrumbItemQuestions.name}>
            <QuestionsOverview/>
        </TheorieGoFrame>
    );
};

export default Questions;