import React from "react";
import {EditBlogPostProps} from "../BlogPostEditor";
import BlogPostSeoCard from "./BlogPostSeoCard";
import BlogPostDatesCard from "./BlogPostDatesCard";
import BlogPostBannerCard from "./BlogPostBannerCard";

const BlogPostMetadataPanel = (props: EditBlogPostProps) => {
    return (
        <div className={"flex flex-col gap-6 pb-12"}>
            <BlogPostDatesCard {...props}/>
            <BlogPostBannerCard {...props}/>
            <BlogPostSeoCard {...props}/>
        </div>
    );
};

export default BlogPostMetadataPanel;