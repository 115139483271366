import React from "react";
import {ExamQuestion} from "../../../../data/Entities";
import {FormLayoutFooterOptions, TwoColumnFormLayout} from "peggirkit";
import router from "../../../../routing/Router";
import {useIntl} from "react-intl";
import {Control, UseFormHandleSubmit, UseFormRegister, UseFormSetValue, UseFormWatch} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import GeneralSection from "./section/general/GeneralSection";
import QuestionSection from "./section/question/QuestionSection";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import AnswerSection from "./section/answer/AnswerSection";
import ImageSection from "./section/image/ImageSection";
import {SelectedAnswerTypeOption} from "./section/answer/AnswerType";
import DeleteSection from "./section/delete/DeleteSection";
import AudioSection from "./section/audio/AudioSection";

export type ValueLabel = {
    value: string,
    label: string
};
export type NumberValueLabel = {
    value: number,
    label: string
};

export type ExamQuestionForm = Omit<ExamQuestion, "concept" | "category" | "answerYes"> & {
    concept: ValueLabel,
    category: NumberValueLabel,
    answerYes: ValueLabel | null,
    selectedAnswerType: SelectedAnswerTypeOption
};

export type EditQuestionSectionProps = {
    loading: boolean,
    control: Control<ExamQuestionForm>,
    register: UseFormRegister<ExamQuestionForm>,
    errors: FieldErrors<ExamQuestionForm>,
    watch: UseFormWatch<ExamQuestionForm>,
    setValue: UseFormSetValue<ExamQuestionForm>
};

type Props = {
    setLoading: (loading: boolean) => void,
    handleSubmit: UseFormHandleSubmit<ExamQuestionForm>,
    formError: string | null,
    categoryOptions: NumberValueLabel[],
    examQuestion?: ExamQuestion
};

const EditQuestionForm = ({
                              setLoading,
                              loading,
                              control,
                              register,
                              errors,
                              watch,
                              setValue,
                              handleSubmit,
                              formError,
                              categoryOptions,
                              examQuestion
                          }: EditQuestionSectionProps & Props) => {
    const intl = useIntl();
    const navigate = useNavigate();

    return (
        <TwoColumnFormLayout
            onSubmit={handleSubmit(() => setLoading(true))}
            error={formError}
        >
            <GeneralSection
                loading={loading}
                control={control}
                register={register}
                errors={errors}
                watch={watch}
                setValue={setValue}
                categoryOptions={categoryOptions}
                questionInExams={examQuestion?.questionInExams}
            />

            <QuestionSection
                loading={loading}
                control={control}
                register={register}
                errors={errors}
                watch={watch}
                setValue={setValue}
            />

            <AnswerSection
                loading={loading}
                control={control}
                register={register}
                errors={errors}
                watch={watch}
                setValue={setValue}
            />

            <ImageSection
                loading={loading}
                control={control}
                register={register}
                errors={errors}
                watch={watch}
                setValue={setValue}
            />

            <AudioSection
                question={examQuestion}
            />

            <DeleteSection
                questionId={watch("id")}
                questionInExams={examQuestion?.questionInExams}
            />

            <FormLayoutFooterOptions
                cancelText={intl.formatMessage({id: "backToOverview"})}
                onCancel={() => navigate(router.questions.absolutePath())}
                submitText={intl.formatMessage({id: "save"})}
                submitLoading={loading}
                disabled={loading}
            />
        </TwoColumnFormLayout>
    );
};

export default EditQuestionForm;