import {createIntl, createIntlCache} from "react-intl";
import DutchMessages from "../../../../../../messages/nl-NL";
import {ValueLabel} from "../../EditQuestionForm";

const cache = createIntlCache();
const intl = createIntl({locale: 'nl-NL', messages: DutchMessages}, cache);

export const conceptOptions: ValueLabel[] = [
    {value: "false", label: intl.formatMessage({id: "public"})},
    {value: "true", label: intl.formatMessage({id: "concept"})},
];

export const findConceptOption = (state: string): ValueLabel => {
    return conceptOptions.filter(c => c.value === state)[0];
};