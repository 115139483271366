import React, {useMemo} from "react";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import {Card, classNames} from "peggirkit";
import {useIntl} from "react-intl";
import {RevenueStats} from "../../../../../data/Entities";
import PlansChartTooltip from "./chart/PlansChartTooltip";
import {StatisticsSettings} from "../../StatisticsOverview";

const colors = ["#2563eb", "#16a34a", "#ca8a04", "#c2410c"];

type DataPoint = {
    name: string,
    value: number,
};

type Props = {
    dateRange: StatisticsSettings,
    revenueStats: RevenueStats | undefined,
};

const PlansCard = ({dateRange, revenueStats}: Props) => {
    const intl = useIntl();
    const data: DataPoint[] = useMemo(() => {
        if (!revenueStats) {
            return [];
        }

        return Object.entries(revenueStats.orderedPlans).map(([plan, share]) => ({
            name: plan,
            value: share,
        }));
    }, [revenueStats?.orderedPlans]);

    return (
        <Card header={{title: intl.formatMessage({id: "soldPlans"})}}>
            <div
                className={classNames(
                    "w-full h-[250px] lg:h-[250px]",
                    dateRange.loadingSaleStats ? "animate-pulse" : ""
                )}
            >
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            data={data}
                            innerRadius={"60%"}
                            fill="#8884d8"
                            dataKey="value"
                            label
                            paddingAngle={5}
                            cornerRadius={6}
                        >
                            {data.map((_, i) => <Cell key={i} fill={colors[i % colors.length]}/>)}
                        </Pie>
                        <Tooltip
                            content={props =>
                                // @ts-ignore
                                <PlansChartTooltip
                                    totalSalesCount={revenueStats?.totalSalesCount || 0}
                                    {...props}
                                />
                            }
                        />
                        <Legend wrapperStyle={{fontSize: "0.75rem"}}/>
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </Card>
    );
};

export default PlansCard;