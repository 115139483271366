import React, {useEffect, useState} from "react";
import {BlogPost} from "../../../data/Entities";
import {FormattedMessage, useIntl} from "react-intl";
import {deleteBlogPosts} from "../../../data/ApiEndpoints";
import {AlertModal, AlertText} from "peggirkit";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void,
    blogPosts: BlogPost[],
    cb: () => void
};

const DeleteBlogPostsModal = ({show, setShow, blogPosts, cb}: Props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        deleteBlogPosts(blogPosts.map(s => s.id || -1)).then(() => {
            setLoading(false);
            cb();
        });
    }, [loading]);

    return (
        <AlertModal
            type={"danger"}
            loading={loading}
            show={show}
            onClose={() => setShow(false)}
            title={
                intl.formatMessage(
                    {id: blogPosts.length === 1 ? "deleteBlogPostTitle" : "deleteBlogPostsTitle"},
                    {n: blogPosts.length})
            }
            cancelText={intl.formatMessage({id: "cancel"})}
            onContinue={() => setLoading(true)}
            continueText={intl.formatMessage({id: "continue"})}
        >
            <AlertText>
                <FormattedMessage
                    id={blogPosts.length === 1 ? "confirmDeleteBlogPost.single" : "confirmDeleteBlogPost.multiple"}
                    values={{n: blogPosts.length}}
                />
            </AlertText>
        </AlertModal>
    );
};

export default DeleteBlogPostsModal;