import React, {useEffect, useState} from "react";
import {AlertModal, AlertText} from "peggirkit";
import {FormattedMessage, useIntl} from "react-intl";
import {deleteContactForms} from "../../../data/ApiEndpoints";
import {ContactForm} from "../../../data/Entities";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void,
    contactForms: ContactForm[],
    cb: () => void
};

const DeleteContactFormsModal = ({show, setShow, contactForms, cb}: Props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        deleteContactForms(contactForms.map(s => s.id)).then(() => {
            setLoading(false);
            cb();
        });
    }, [loading]);

    return (
        <AlertModal
            type={"danger"}
            loading={loading}
            show={show}
            onClose={() => setShow(false)}
            title={
                intl.formatMessage(
                    {id: contactForms.length === 1 ? "deleteContactFormTitle" : "deleteContactFormsTitle"},
                    {nForms: contactForms.length})
            }
            cancelText={intl.formatMessage({id: "cancel"})}
            onContinue={() => setLoading(true)}
            continueText={intl.formatMessage({id: "continue"})}
        >
            <AlertText>
                <FormattedMessage
                    id={contactForms.length === 1
                        ? "confirmDeleteContactForms.single"
                        : "confirmDeleteContactForms.multiple"}
                    values={{nForms: contactForms.length}}
                />
            </AlertText>
        </AlertModal>
    );
};

export default DeleteContactFormsModal;