import React, {useEffect, useMemo, useState} from "react";
import router, {
    breadcrumbItemDashboard,
    breadcrumbItemEditFaqQuestion,
    breadcrumbItemFaq,
    newExamPart
} from "../../../routing/Router";
import TheorieGoFrame from "./layout/TheorieGoFrame";
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import {FaqQuestionDetails} from "../../../data/Entities";
import {BreadcrumbsContent} from "peggirkit";
import EditFaqQuestionContainer from "../../widgets/faq/questions/EditFaqQuestionContainer";
import {getFaqQuestion} from "../../../data/ApiEndpoints";

const EditFaqQuestion = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const {faqSectionId, faqQuestionId} = useParams();
    const [loadingData, setLoadingData] = useState(true);
    const [question, setQuestion] = useState<FaqQuestionDetails | undefined>();
    const currentBreadcrumbItem = useMemo(
        () => breadcrumbItemEditFaqQuestion(question && question.id || -1),
        [question]);
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemFaq, currentBreadcrumbItem]
    };

    // Load existing exam
    useEffect(() => {
        if (!faqQuestionId || faqQuestionId === newExamPart) {
            setQuestion(undefined);
            setLoadingData(false);
            return;
        }

        getFaqQuestion(parseInt(faqQuestionId)).then((e: FaqQuestionDetails) => {
            setQuestion(e);
            setLoadingData(false);
        }).catch(_ => {
            navigate(router.error.absolutePath());
        });
    }, [faqQuestionId]);

    return (
        <TheorieGoFrame title={currentBreadcrumbItem.name}>
            <EditFaqQuestionContainer
                key={question?.id || ""}
                currentBreadcrumbItem={currentBreadcrumbItem}
                breadcrumbs={breadcrumbs}
                loadingData={loadingData}
                faqSectionId={question?.faqSection.id || faqSectionId}
                faqQuestionId={faqQuestionId}
                existingQuestion={question}
                setExistingQuestion={setQuestion}
            />
        </TheorieGoFrame>
    );
};

export default EditFaqQuestion;