import React, {useMemo} from "react";
import {breadcrumbItemDashboard, breadcrumbItemStatistics} from "../../../routing/Router";
import {CalendarDaysIcon} from "@heroicons/react/24/outline";
import {BreadcrumbsContent, Icon, PageHeading} from "peggirkit";
import {formatToShortDate} from "../../../utils/DateUtils";
import {useIntl} from "react-intl";
import {StatisticsSettings} from "./StatisticsOverview";

type Props = {
    statisticsSettings: StatisticsSettings,
    setStatisticsSettings: (newRange: StatisticsSettings) => void,
};

const StatisticsHeading = ({statisticsSettings, setStatisticsSettings}: Props) => {
    const intl = useIntl();
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemStatistics]
    };
    const formattedRangeShort = useMemo(
        () => intl.formatMessage({id: "dateRangeShort"}, {
            from: formatToShortDate(statisticsSettings.dateRange.from),
            to: formatToShortDate(statisticsSettings.dateRange.to)
        }),
        [intl, statisticsSettings]);

    return (
        <PageHeading
            breadcrumbsContent={breadcrumbs}
            title={breadcrumbItemStatistics.name}
            actions={{
                disabled: statisticsSettings.loadingSaleStats || statisticsSettings.loadingStudentStats,
                moreButtonText: intl.formatMessage({id: "more"}),
                actions: [{
                    type: "primary",
                    actionType: "button",
                    text: formattedRangeShort,
                    icon: CalendarDaysIcon as Icon,
                    onClick: () => setStatisticsSettings({...statisticsSettings, showPicker: true}),
                    primary: true,
                }]
            }}
        />
    );
};

export default StatisticsHeading;