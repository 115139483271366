import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {AuthButton, Form, InputField, InputLink, useAuth} from "peggirkit";
import router from "../../../routing/Router";
import {useIntl} from "react-intl";

export type FormValues = {
    emailAddress: string,
    password: string
};

const AuthFormSignIn = () => {
    const intl = useIntl();
    const {register, getValues, handleSubmit, formState: {errors}} = useForm<FormValues>();
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);
    const authContext = useAuth();

    useEffect(() => {
        if (!loading) {
            return;
        }

        const submittedData = getValues();
        authContext?.onSignIn(submittedData.emailAddress, submittedData.password).catch(() => {
            setLoading(false);
            setFormError(intl.formatMessage({id: "invalidEmailOrPassword"}));
        });
    }, [loading]);

    return (
        <Form
            onSubmit={handleSubmit(() => setLoading(true))}
            error={formError}
        >
            <InputField
                id={"emailAddress"}
                displayName={intl.formatMessage({id: "emailAddress"})}
                type={"email"}
                placeholder={intl.formatMessage({id: "emailAddressPlaceholder"})}
                disabled={loading}
                error={errors.emailAddress && errors.emailAddress.message}
                reactHookForm={{
                    ...register("emailAddress", {
                        required: intl.formatMessage({id: "enterAnEmailAddress"})
                    })
                }}
            />

            <InputField
                id={"password"}
                displayName={intl.formatMessage({id: "password"})}
                type={"password"}
                placeholder="••••••••••••••••••"
                disabled={loading}
                error={errors.password && errors.password.message}
                reactHookForm={{
                    ...register("password", {
                        required: intl.formatMessage({id: "enterYourPassword"})
                    })
                }}
            />

            <InputLink
                text={intl.formatMessage({id: "forgotPassword"})}
                href={router.reset.absolutePath()}
            />

            <AuthButton
                disabled={loading}
                loading={loading}
                text={intl.formatMessage({id: "signIn"})}
            />
        </Form>
    );
};

export default AuthFormSignIn;