import React, {useEffect, useState} from "react";
import {BreadcrumbItem, isEmpty, NotificationService} from "peggirkit";
import {BreadcrumbsContent} from "peggirkit/dist/components/Heading/Page/Breadcrumbs";
import {ExamDetails, ExamDetailsForSubmission, QuestionInExamDetails} from "../../../../data/Entities";
import {useForm} from "react-hook-form";
import {useIntl} from "react-intl";
import PageHeading from "./PageHeading";
import EditExamSections from "./EditExamSections";
import {ValueLabel} from "../../questions/question/EditQuestionForm";
import {findConceptOption} from "../../questions/question/section/general/ConceptState";
import router from "../../../../routing/Router";
import {useNavigate} from "react-router-dom";
import {upsertExam} from "../../../../data/ApiEndpoints";

type Props = {
    breadcrumbs: BreadcrumbsContent,
    currentBreadcrumbItem: BreadcrumbItem,
    loadingData: boolean,
    existingExam?: ExamDetails,
};

export type ExamDetailsForm = Omit<ExamDetails, "concept"> & {
    concept: ValueLabel,
};

const EditExamContainer = ({breadcrumbs, currentBreadcrumbItem, loadingData, existingExam}: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const {
        register,
        control,
        setValue,
        getValues,
        watch,
        handleSubmit,
        reset,
        formState: {errors}
    } = useForm<ExamDetailsForm>({
        defaultValues: existingExam
            ? {
                ...existingExam,
                concept: findConceptOption(existingExam.concept ? "true" : "false")
            }
            : {
                id: undefined,
                demo: false
            }
    });

    // Reset default values for new exam
    useEffect(() => {
        if (!existingExam) {
            reset();
        }
    }, [existingExam]);

    // Save exam
    useEffect(() => {
        if (!loading) {
            return;
        }

        const convertQuestionInExam = (questions: QuestionInExamDetails[]) => questions.map((qie, index) => ({
            ...qie,
            id: qie.id || null,
            orderNr: index + 1,
            question: {id: qie.question.id || -1}
        }));
        const values: ExamDetailsForm = getValues();

        if (!values.concept) {
            // When no status is picked, and the questions tab is open, then
            // the react-form-hook required validation does not work. Hence,
            // a manual validation here.
            NotificationService.notify({type: "danger", text: intl.formatMessage({id: "pickAStatus"})});
            setLoading(false);
            return;
        }

        const examDetails: ExamDetailsForSubmission = {
            id: values.id || null,
            nameNl: values.nameNl,
            nameAr: values.nameAr,
            image: isEmpty(values.image) ? null : values.image,
            concept: values.concept.value === "true",
            demo: values.demo,
            hazardRecognitionQuestions: convertQuestionInExam(values.hazardRecognitionQuestions),
            knowledgeQuestions: convertQuestionInExam(values.knowledgeQuestions),
            insightQuestions: convertQuestionInExam(values.insightQuestions),
        };

        upsertExam(examDetails).then(data => {
            if (watch("id") === undefined || watch("id") === null) {
                navigate(router.editExam.absolutePath(data.id));
            } else {
                setLoading(false);
                NotificationService.notify({type: "success", text: intl.formatMessage({id: "examSaved"})});
            }
        }).catch(_ => {
            NotificationService.notify({type: "danger", text: intl.formatMessage({id: "operationError"})});
            setLoading(false);
        });
    }, [loading]);

    return (
        <>
            <PageHeading
                breadcrumbs={breadcrumbs}
                currentBreadcrumbItem={currentBreadcrumbItem}
                handleSubmit={handleSubmit}
                loading={loading || loadingData}
                setLoading={setLoading}
            />

            <div className={"mt-4 md:mt-4"}>
                <EditExamSections
                    handleSubmit={handleSubmit}
                    setLoading={setLoading}
                    control={control}
                    watch={watch}
                    loading={loading || loadingData}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                />
            </div>
        </>
    );
};

export default EditExamContainer;