import React, {useEffect, useMemo, useState} from "react";
import TheorieGoFrame from "./layout/TheorieGoFrame";
import router, {
    breadcrumbItemDashboard,
    breadcrumbItemEditQuestion,
    breadcrumbItemQuestions,
    newQuestionPart
} from "../../../routing/Router";
import {BreadcrumbsContent} from "peggirkit";
import {useIntl} from "react-intl";
import {ExamCategory, ExamQuestion} from "../../../data/Entities";
import {useNavigate, useParams} from "react-router-dom";
import EditQuestionContainer from "../../widgets/questions/question/EditQuestionContainer";
import {getExamQuestion, listCategories} from "../../../data/ApiEndpoints";

const EditQuestion = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const {questionId} = useParams();
    const [loadingData, setLoadingData] = useState(true);
    const [question, setQuestion] = useState<ExamQuestion | undefined>();
    const [categories, setCategories] = useState<ExamCategory[]>([]);
    const currentBreadcrumbItem = useMemo(() => breadcrumbItemEditQuestion(question && question.id || -1), [question]);
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemQuestions, currentBreadcrumbItem]
    };

    // Load existing question
    useEffect(() => {
        listCategories().then(page => {
            setCategories(page.content as ExamCategory[]);
            if (questionId && questionId !== newQuestionPart) {
                getExamQuestion(parseInt(questionId)).then(q => {
                    setQuestion(q);
                    setLoadingData(false);
                }).catch(_ => {
                    navigate(router.error.absolutePath());
                });
            } else {
                setQuestion(undefined);
                setLoadingData(false);
            }
        });
    }, [questionId]);

    return (
        <TheorieGoFrame title={currentBreadcrumbItem.name}>
            <EditQuestionContainer
                key={question?.id || ""}
                breadcrumbs={breadcrumbs}
                currentBreadcrumb={currentBreadcrumbItem}
                existingQuestion={question}
                categoryOptions={categories.map(c => ({
                        value: c.id as number,
                        label: `${c.code ? `${c.code} - ` : ""}${c.nameNl}`
                    })
                )}
                loadingData={loadingData}
            />
        </TheorieGoFrame>
    );
};

export default EditQuestion;