import React from "react";
import AuthFormResetPassword from "../../widgets/auth/AuthFormResetPassword";
import TheorieGoAuthFrame from "./layout/TheorieGoAuthFrame";
import {AuthFormFooter, AuthFormPanel} from "peggirkit";
import router from "../../../routing/Router";
import {useIntl} from "react-intl";

const ResetPassword = () => {
    const intl = useIntl();

    return (
        <TheorieGoAuthFrame
            title={intl.formatMessage({id: "resetPassword"})}
        >
            <AuthFormPanel
                form={<AuthFormResetPassword/>}
                footer={
                    <AuthFormFooter
                        message={intl.formatMessage({id: "backToSignIn"})}
                        link={router.signIn.absolutePath()}
                        linkText={intl.formatMessage({id: "signIn"})}
                    />
                }
            />
        </TheorieGoAuthFrame>
    );
};

export default ResetPassword;