import React, {useState} from "react";
import ContactFormsDataTable from "./dataTable/ContactFormsDataTable";
import ContactFormsOverviewPageHeading from "./ContactFormsOverviewPageHeading";

export type StatusOpenFilter = boolean | null;

const ContactFormsOverview = () => {
    const [statusOpenFilter, setStatusOpenFilter] = useState<StatusOpenFilter>(true);

    return (
        <>
            <ContactFormsOverviewPageHeading
                statusOpenFilter={statusOpenFilter}
                setStatusOpenFilter={setStatusOpenFilter}
            />

            <div className={"mt-8 md:mt-16"}>
                <ContactFormsDataTable
                    statusOpenFilter={statusOpenFilter}
                />
            </div>
        </>
    );
};

export default ContactFormsOverview;