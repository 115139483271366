import {ExamQuestionForm} from "../../../EditQuestionForm";
import {createIntl, createIntlCache} from "react-intl";
import DutchMessages from "../../../../../../../messages/nl-NL";
import {SelectedAnswerType} from "../AnswerType";

const cache = createIntlCache();
const intl = createIntl({locale: 'nl-NL', messages: DutchMessages}, cache);

export const transformAndValidateMultipleChoiceAnswer = (values: ExamQuestionForm): ExamQuestionForm => {
    if (values.selectedAnswerType.value !== SelectedAnswerType.MULTIPLE_CHOICE) {
        return values;
    }

    if (values.multipleChoiceAnswer === null) {
        throw new Error(intl.formatMessage({id: "noMultipleChoiceAnswerSpecified"}));
    }

    // At least two options
    if (values.multipleChoiceAnswer?.answers.length < 2) {
        throw new Error(intl.formatMessage({id: "needsTwoMultipleChoiceAnswers"}));
    }

    // One option must be correct (when not mult. response)
    const numberOfCorrectAnswers = values.multipleChoiceAnswer?.answers.filter(a => a.correct).length;
    if (!values.multipleChoiceAnswer.multipleResponse
        && numberOfCorrectAnswers !== 1) {
        throw new Error(intl.formatMessage({id: "oneMultChoiceAnswersNeedToBeCorrect"}));
    }

    // At least 1 correct answer, regardless of multiple-response or multiple-choice
    if (numberOfCorrectAnswers === 0) {
        throw new Error(intl.formatMessage({id: "mustBeAtLeastOneCorrectOption"}));
    }

    // Multiple images validation
    if (!values.multipleChoiceAnswer?.withImages) {
        values = {
            ...values,
            multipleChoiceAnswer: {
                ...values.multipleChoiceAnswer,
                answers: values.multipleChoiceAnswer.answers.map(a => ({...a, image: null}))
            }
        };
    }

    // Both with images & multiple response not allowed
    if (values.multipleChoiceAnswer?.withImages && values.multipleChoiceAnswer.multipleResponse) {
        throw new Error(intl.formatMessage({id: "cannotBeWithImagesAndMultResponse"}));
    }

    // Answer options order numbers
    if (values.multipleChoiceAnswer?.answers) {
        values = {
            ...values,
            multipleChoiceAnswer: {
                ...values.multipleChoiceAnswer,
                answers: values.multipleChoiceAnswer.answers.map((a, index) => ({...a, orderNr: index + 1}))
            }
        };
    }

    return values;
};