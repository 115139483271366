import React, {useState} from "react";
import {Button, SectionHeading} from "peggirkit";
import {Student} from "../../../../../data/Entities";
import DeleteStudentsModal from "../DeleteStudentsModal";
import {useNavigate} from "react-router-dom";
import router from "../../../../../routing/Router";
import {useIntl} from "react-intl";

type Props = {
    student: Student
};

const DeleteSection = ({student}: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    if (!student.id) {
        return <></>;
    }

    return (
        <>
            <SectionHeading
                className={"mt-8"}
                title={intl.formatMessage({id: "delete"})}
                description={intl.formatMessage({id: "permanentlyDeleteStudent"})}
            />
            <Button
                type={"danger"}
                text={intl.formatMessage({id: "delete"})}
                onClick={() => setShowDeleteModal(true)}
            />

            <DeleteStudentsModal
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                students={[student]}
                cb={() => navigate(router.students.absolutePath())}
            />
        </>
    );
};

export default DeleteSection;