import React from "react";
import {AdjustmentsHorizontalIcon, ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import {DropdownButton} from "peggirkit";
import {UseFormSetValue} from "react-hook-form";
import {ExamQuestionForm} from "../../EditQuestionForm";
import {findSelectedAnswerTypeOption, SelectedAnswerType} from "./AnswerType";
import {useIntl} from "react-intl";

type Props = {
    loading: boolean,
    setValue: UseFormSetValue<ExamQuestionForm>
};

const Presets = ({loading, setValue}: Props) => {
    const intl = useIntl();

    const insertPresetHazardRecognition = (e: React.MouseEvent) => {
        e.preventDefault();
        setValue("questionNl", "Wat moet je doen?");
        setValue("questionAr", "ماذا عليك ان تفعل؟");
        setValue("answerYes", null);
        setValue("numberAnswer", null);
        setValue("dragDropAnswer", null);
        setValue("selectedAnswerType", findSelectedAnswerTypeOption(SelectedAnswerType.MULTIPLE_CHOICE));
        setValue("multipleChoiceAnswer.withImages", false);
        setValue("multipleChoiceAnswer.multipleResponse", false);
        setValue("multipleChoiceAnswer.answers", [
            {
                correct: false,
                orderNr: 1,
                answerNl: "Remmen",
                answerAr: "فرام",
                image: null
            },
            {
                correct: false,
                orderNr: 2,
                answerNl: "Gas loslaten",
                answerAr: "رفع القدم",
                image: null
            },
            {
                correct: false,
                orderNr: 3,
                answerNl: "Niets",
                answerAr: "لاشيء",
                image: null
            }
        ]);
    };

    return (
        <DropdownButton
            style={"white"}
            disabled={loading}
            text={intl.formatMessage({id: "usePreset"})}
            icon={AdjustmentsHorizontalIcon}
            alignRight={false}
            actions={[[
                {
                    icon: ExclamationTriangleIcon,
                    text: "Wat moet je doen?",
                    onClick: insertPresetHazardRecognition,
                }
            ]]}
        />
    );
};

export default Presets;