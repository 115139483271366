import React, {useEffect, useState} from "react";
import SetPasswordField from "../auth/fields/SetPasswordField";
import SetPasswordRepeatedField from "../auth/fields/SetPasswordRepeatedField";
import {useForm} from "react-hook-form";
import PasswordField from "../auth/fields/PasswordField";
import {AlertModal, AlertText, Form, NotificationService} from "peggirkit";
import {updatePassword} from "../../../data/ApiEndpoints";
import {FormattedMessage, useIntl} from "react-intl";

type Props = {
    show: boolean,
    onClose: () => void
};

type FormValues = {
    currentPassword: string,
    newPassword: string,
    passwordRepeated: string,
};

const UpdatePasswordModal = ({show, onClose}: Props) => {
    const intl = useIntl();
    const {register, getValues, handleSubmit, watch, reset, formState: {errors}} = useForm<FormValues>();
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);

    useEffect(() => {
        if (!loading) {
            return;
        }

        const submittedData = getValues();
        updatePassword(submittedData.currentPassword, submittedData.newPassword)
            .then(() => {
                setLoading(false);
                setFormError(null);
                onClose();
                reset();
                NotificationService.notify({type: "success", text: intl.formatMessage({id: "passwordUpdated"})});
            })
            .catch(e => {
                setFormError(intl.formatMessage({
                    id: e.response.status === 400
                        ? "incorrectCurrentPassword"
                        : "errorUpdatingPassword"
                }));
                setLoading(false);
            });
    }, [loading]);

    return (
        <AlertModal
            type="info"
            show={show}
            onClose={onClose}
            onContinue={handleSubmit(() => {
                setLoading(true)
            })}
            title={intl.formatMessage({id: "updatePassword"})}
            cancelText={intl.formatMessage({id: "cancel"})}
            continueText={intl.formatMessage({id: "save"})}
            loading={loading}
        >
            <div className={"mb-6 space-y-6"}>
                <AlertText>
                    <FormattedMessage id={"enterFieldsToUpdatePassword"}/>
                </AlertText>

                <Form
                    onSubmit={handleSubmit(() => {
                        setLoading(true)
                    })}
                    error={formError}
                >
                    <PasswordField
                        fieldName="currentPassword"
                        displayName={intl.formatMessage({id: "currentPassword"})}
                        register={register}
                        disabled={loading}
                        fieldError={errors.currentPassword}
                    />

                    <SetPasswordField
                        fieldName="newPassword"
                        displayName={intl.formatMessage({id: "newPassword"})}
                        watch={watch}
                        register={register}
                        disabled={loading}
                        fieldError={errors.newPassword}
                    />

                    <SetPasswordRepeatedField
                        register={register}
                        disabled={loading}
                        fieldError={errors.passwordRepeated}
                        compareValue={watch("newPassword")}
                    />
                </Form>
            </div>
        </AlertModal>
    );
};

export default UpdatePasswordModal;