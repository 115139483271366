import React from "react";
import {Badge} from "peggirkit";
import {LoaderSkeleton} from "../../sales/revenue/RevenueStats";

export type ExamSummaryCardProps = {
    loading: boolean,
    label: string,
    value: string | number,
    badgeValue?: string,
};

const ExamSummaryCard = ({loading, label, value, badgeValue}: ExamSummaryCardProps) => {
    return (
        <div className={"rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5 px-4 py-5 sm:p-6"}>
            <dt className="text-sm font-medium text-gray-500">
                {label}
            </dt>

            <dd className="mt-1 font-semibold text-xl tracking-tight text-gray-900">
                <div className="flex items-center gap-3">
                    {
                        loading
                            ? <LoaderSkeleton/>
                            : value
                    }
                    {
                        (badgeValue && !loading) &&
                        <Badge type={"green"} content={badgeValue}/>
                    }
                </div>
            </dd>
        </div>
    );
};

export default ExamSummaryCard;