import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {Payload} from "recharts/types/component/DefaultTooltipContent";
import ChartTooltip from "../../../ChartTooltip";

type Props = {
    totalSalesCount: number,
    active?: boolean,
    payload?: Payload<number, string>[],
    label?: string
};

const PlansChartTooltip = ({totalSalesCount, active, payload}: Props) => {
    const intl = useIntl();
    const percentage: number = useMemo(() => {
        if (!active || !payload || !payload.length) {
            return 0;
        }

        return Math.round((100 / totalSalesCount) * (payload[0].value || 0));
    }, [payload]);

    if (!active || !payload || !payload.length) {
        return;
    }

    return (
        <ChartTooltip
            title={payload[0].name || ""}
            values={[
                `${intl.formatMessage({id: "numberOfSales"})}: ${payload[0].value} (${percentage}%)`
            ]}
        />
    );
};

export default PlansChartTooltip;