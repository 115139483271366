import React, {useState} from "react";
import {CheckIcon, TrashIcon} from "@heroicons/react/24/outline";
import {BreadcrumbItem, Icon, PageHeading as PeggirKitPageHeading, PageHeadingActionButton} from "peggirkit";
import {BreadcrumbsContent} from "peggirkit/dist/components/Heading/Page/Breadcrumbs";
import {UseFormHandleSubmit} from "react-hook-form";
import {useIntl} from "react-intl";
import {FaqQuestionDetails} from "../../../../data/Entities";
import DeleteContactFormsModal from "./DeleteFaqQuestionModal";
import {useNavigate} from "react-router-dom";
import router from "../../../../routing/Router";

export type Props = {
    breadcrumbs: BreadcrumbsContent,
    currentBreadcrumbItem: BreadcrumbItem,
    handleSubmit: UseFormHandleSubmit<FaqQuestionDetails>,
    loading: boolean,
    setLoading: (loading: boolean) => void,
    existingQuestion?: FaqQuestionDetails,
};

const PageHeading = ({
                         breadcrumbs,
                         currentBreadcrumbItem,
                         handleSubmit,
                         loading,
                         setLoading,
                         existingQuestion
                     }: Props) => {
    const intl = useIntl();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const navigate = useNavigate();

    return (
        <>
            <PeggirKitPageHeading
                breadcrumbsContent={breadcrumbs}
                title={currentBreadcrumbItem.name}
                actions={{
                    disabled: loading,
                    moreButtonText: intl.formatMessage({id: "more"}),
                    actions: [
                        ...(existingQuestion === undefined
                                ? []
                                : [{
                                    primary: false,
                                    onClick: () => setShowDeleteDialog(true),
                                    text: intl.formatMessage({id: "delete"}),
                                    icon: TrashIcon as Icon,
                                    actionType: "button",
                                    type: "danger",
                                } as PageHeadingActionButton]
                        ),
                        {
                            primary: true,
                            onClick: handleSubmit(() => setLoading(true)),
                            text: intl.formatMessage({id: "save"}),
                            icon: CheckIcon as Icon,
                            actionType: "button"
                        },
                    ]
                }}
            />
            {
                (existingQuestion && existingQuestion.id) &&
                <DeleteContactFormsModal
                    questions={[existingQuestion.id]}
                    cb={() => {
                        navigate(router.faq.absolutePath());
                    }}
                    show={showDeleteDialog}
                    setShow={setShowDeleteDialog}
                />
            }
        </>
    );
};

export default PageHeading;