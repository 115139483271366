import React from "react";
import {Card, DescriptionList, Link} from "peggirkit";
import router from "../../../../../routing/Router";
import {useIntl} from "react-intl";
import {ContactForm} from "../../../../../data/Entities";

type Props = {
    contactForm: ContactForm | undefined,
};

const ContactFormFieldsCard = ({contactForm}: Props) => {
    const intl = useIntl();
    return (
        <Card header={{title: intl.formatMessage({id: "details"})}}>
            <DescriptionList
                whitespaceNormal={true}
                skipFirstBorder={true}
                items={[
                    {
                        key: intl.formatMessage({id: "firstName"}),
                        value: contactForm?.firstName,
                    },
                    {
                        key: intl.formatMessage({id: "lastName"}),
                        value: contactForm?.lastName,
                    },
                    {
                        key: intl.formatMessage({id: "studentCode"}),
                        keyTooltip: intl.formatMessage({id: "manuallyEnteredInForm"}),
                        value: contactForm?.studentCode,
                    },
                    {
                        key: intl.formatMessage({id: "student"}),
                        keyTooltip: intl.formatMessage({id: "studentSubmittedWasSignedInWith"}),
                        value: contactForm && contactForm.studentId != null
                            ? <Link
                                text={intl.formatMessage({id: "studentWithNumber"},
                                    {studentId: contactForm.studentId})}
                                href={router.student.absolutePath(contactForm.studentId)}
                            />
                            : "",
                    },
                    {
                        key: intl.formatMessage({id: "telephoneNumber"}),
                        value: contactForm?.telephoneNumber,
                    },
                    {
                        key: intl.formatMessage({id: "emailAddress"}),
                        value: contactForm?.emailAddress,
                    },
                    {
                        key: intl.formatMessage({id: "sentOn"}),
                        value: contactForm ? new Date(contactForm.creationDate).toLocaleString() : undefined,
                    },
                    {
                        key: intl.formatMessage({id: "message"}),
                        value: contactForm?.message,
                    },
                ]}
            />
        </Card>
    );
};

export default ContactFormFieldsCard;