import React, {useEffect, useState} from "react";
import {AlertModal, AlertText} from "peggirkit";
import {FormattedMessage, useIntl} from "react-intl";
import {deleteExamQuestions} from "../../../../data/ApiEndpoints";

type Props = {
    show: boolean,
    setShow: (show: boolean) => void,
    questionIds: number[],
    cb: () => void
};

const DeleteQuestionsModal = ({show, setShow, questionIds, cb}: Props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        deleteExamQuestions(questionIds).then(_ => {
            setLoading(false);
            cb();
        });
    }, [loading]);

    return (
        <AlertModal
            type={"danger"}
            loading={loading}
            show={show}
            onClose={() => setShow(false)}
            title={
                intl.formatMessage(
                    {id: questionIds.length === 1 ? "confirmDeleteExamQuestionTitle" : "confirmDeleteExamQuestions"},
                    {nQuestions: questionIds.length})
            }
            cancelText={intl.formatMessage({id: "cancel"})}
            continueText={intl.formatMessage({id: "continue"})}
            onContinue={() => setLoading(true)}
        >
            <AlertText>
                <FormattedMessage
                    id={questionIds.length === 1
                        ? "confirmDeleteExamQuestion"
                        : "confirmDeleteExamQuestionsDescription"
                    }
                    values={{nQuestions: questionIds.length}}
                />
            </AlertText>
        </AlertModal>
    );
};

export default DeleteQuestionsModal;