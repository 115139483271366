import React, {useRef} from "react";
import {EditQuestionSectionProps} from "../../EditQuestionForm";
import {Button, FormLayoutCard, Icon, InputField, isEmpty} from "peggirkit";
import {useIntl} from "react-intl";
import {ArrowUpTrayIcon, TrashIcon} from "@heroicons/react/24/outline";
// @ts-ignore
import placeholder from "../../../../../../assets/img/placeholder.jpg";
import ImageUploader from "../../../../assets/ImageUploader";
import {getAbsoluteImageUrl} from "../../../../assets/AssetsUtil";

const ImageSection = ({watch, setValue, loading, register, errors}: EditQuestionSectionProps) => {
    const intl = useIntl();
    const imageUploaderRef = useRef<HTMLInputElement | null>(null);

    return (
        <FormLayoutCard
            title={intl.formatMessage({id: "image"})}
            description={intl.formatMessage({id: "editExamQuestion.section.image.description"})}
        >
            <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 lg:col-span-4">
                    <InputField
                        type={"text"}
                        id={"question-image"}
                        displayName={intl.formatMessage({id: "image"})}
                        reactHookForm={register("image")}
                        error={errors.image && errors.image.message}
                        disabled={loading}
                        tip={intl.formatMessage({id: "questionImageAspectRatioTip"})}
                        trailingButton={{
                            type: "gray",
                            iconOnly: false,
                            icon: ArrowUpTrayIcon as Icon,
                            text: intl.formatMessage({id: "upload"}),
                            disabled: loading,
                            onClick: e => {
                                e.preventDefault();
                                imageUploaderRef.current && imageUploaderRef.current.click();
                            }
                        }}
                    />
                    <ImageUploader
                        afterUpload={(img) => setValue("image", img)}
                        uploaderRef={imageUploaderRef}
                    />
                </div>

                <div className="col-span-6 lg:col-span-4">
                    <img
                        className={"border rounded-md border-gray-300 w-full aspect-[938/639]"}
                        alt={intl.formatMessage({id: "image"})}
                        src={
                            !isEmpty(watch("image"))
                                ? getAbsoluteImageUrl(watch("image"))
                                : placeholder
                        }
                    />
                </div>

                <div className="col-span-6 lg:col-span-4">
                    <Button
                        type={"white"}
                        icon={TrashIcon as Icon}
                        text={intl.formatMessage({id: "removeImage"})}
                        disabled={loading}
                        onClick={e => {
                            e.preventDefault();
                            setValue("image", null);
                        }}
                    />
                </div>
            </div>
        </FormLayoutCard>
    );
};

export default ImageSection;