import dayjs from "dayjs";

export const formatToShortDate = (date?: string | Date): string => {
    if (!date) {
        return "..-..";
    }

    return dayjs(date).format("DD-MM");
};

export const formatDate = (date?: string | Date): string => {
    if (!date) {
        return "..-..-....";
    }

    return dayjs(date).format("DD-MM-YYYY");
};
