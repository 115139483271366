import React, {useMemo} from "react";
import {StatisticsSettings} from "../../StatisticsOverview";
import {ExamStats} from "../../../../../data/Entities";
import ExamSummaryCard, {ExamSummaryCardProps} from "./ExamSummaryCard";
import {useIntl} from "react-intl";

type Props = {
    statisticsSettings: StatisticsSettings
    examStats: ExamStats | undefined,
};

const ExamSummaries = ({statisticsSettings, examStats}: Props) => {
    const intl = useIntl();
    const cards: ExamSummaryCardProps[] = useMemo(() => ([
        {
            loading: statisticsSettings.loadingStudentStats,
            label: intl.formatMessage({id: "takenExams"}),
            value: examStats?.totalExams || 0,
        },
        {
            loading: statisticsSettings.loadingStudentStats,
            label: intl.formatMessage({id: "examsWithAudio"}),
            value: examStats?.soundEnabled || 0,
            badgeValue: !examStats ? undefined : Math.round((100 / examStats.totalExams) * examStats.soundEnabled) + "%"
        },
        {
            loading: statisticsSettings.loadingStudentStats,
            label: intl.formatMessage({id: "examsWithTime"}),
            value: examStats?.timeEnabled || 0,
            badgeValue: !examStats ? undefined : Math.round((100 / examStats.totalExams) * examStats.timeEnabled) + "%"
        },
        {
            loading: statisticsSettings.loadingStudentStats,
            label: intl.formatMessage({id: "examsWithExtraTime"}),
            value: examStats?.extraTime || 0,
            badgeValue: !examStats ? undefined : Math.round((100 / examStats.totalExams) * examStats.extraTime) + "%"
        },
    ]), [intl, examStats]);

    return (
        <div className={"w-full grid grid-cols-2 gap-3"}>
            {cards.map((props, i) => <ExamSummaryCard key={i} {...props}/>)}
        </div>
    );
};

export default ExamSummaries;