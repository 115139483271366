import React, {useState} from "react";
import {addDays, endOfDay, startOfDay} from "date-fns";
import {DateRange} from "react-day-picker";
import SalesStatistics from "./sales/SalesStatistics";
import StatisticsHeading from "./StatisticsHeading";
import DayRangeSelector from "./DayRangeSelector";
import StudentStatistics from "./students/StudentStatistics";

export type StatisticsSettings = {
    showPicker: boolean,
    loadingSaleStats: boolean,
    loadingStudentStats: boolean,
    dateRange: DateRange
};

const StatisticsOverview = () => {
    const [statisticsSettings, setStatisticsSettings] = useState<StatisticsSettings>({
        showPicker: false,
        loadingSaleStats: true,
        loadingStudentStats: true,
        dateRange: {
            from: startOfDay(addDays(new Date(), -7)),
            to: endOfDay(new Date())
        }
    });

    return (
        <>
            <StatisticsHeading
                statisticsSettings={statisticsSettings}
                setStatisticsSettings={setStatisticsSettings}
            />

            <SalesStatistics
                statisticsSettings={statisticsSettings}
                setStatisticsSettings={setStatisticsSettings}
            />

            <StudentStatistics
                statisticsSettings={statisticsSettings}
                setStatisticsSettings={setStatisticsSettings}
            />

            <DayRangeSelector
                statisticsSettings={statisticsSettings}
                setStatisticsSettings={setStatisticsSettings}
            />
        </>
    );
};

export default StatisticsOverview;