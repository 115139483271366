import React, {useMemo} from "react";
import {Spinner, Stats, TextSkeleton} from "peggirkit";
import {formatMoney} from "../../../../../utils/MoneyUtils";
import {formatDate} from "../../../../../utils/DateUtils";
import {useIntl} from "react-intl";
import {RevenueStats as RevenueStatsEntity} from "../../../../../data/Entities";
import {StatisticsSettings} from "../../StatisticsOverview";

type Props = {
    dateRange: StatisticsSettings,
    revenueStats: RevenueStatsEntity | undefined
};

export const LoaderSkeleton = () => <TextSkeleton height={"h-5"} width={"max-w-[150px]"}/>;

const RevenueStats = ({dateRange, revenueStats}: Props) => {
    const intl = useIntl();
    const formattedRange = useMemo(
        () => intl.formatMessage({id: "dateRange"}, {
            from: formatDate(dateRange.dateRange.from),
            to: formatDate(dateRange.dateRange.to)
        }),
        [intl, dateRange]);

    return (
        <Stats
            items={[
                {
                    name: intl.formatMessage({id: "period"}),
                    stat: (
                        <div className={"flex items-center"}>
                            {dateRange.loadingSaleStats && <Spinner color={"text-black"}/>}
                            {formattedRange}
                        </div>
                    ),
                },
                {
                    name: intl.formatMessage({id: "revenueInclTax"}),
                    stat: dateRange.loadingSaleStats ? <LoaderSkeleton/> : formatMoney(revenueStats?.priceInTaxSum || 0),
                },
                {
                    name: intl.formatMessage({id: "numberOfSales"}),
                    stat: dateRange.loadingSaleStats ? <LoaderSkeleton/> : revenueStats?.totalSalesCount || 0,
                },
            ]}
        />
    );
};

export default RevenueStats;