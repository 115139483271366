import React from "react";
import AuthFormSignIn from "../../widgets/auth/AuthFormSignIn";
import TheorieGoAuthFrame from "./layout/TheorieGoAuthFrame";
import {AuthFormPanel} from "peggirkit";
import AuthFormFooterSignIn from "../../widgets/auth/AuthFormFooterSignIn";
import {useIntl} from "react-intl";

const SignIn = () => {
    const intl = useIntl();

    return (
        <TheorieGoAuthFrame title={intl.formatMessage({id: "signIn"})}>
            <AuthFormPanel
                form={<AuthFormSignIn/>}
                footer={<AuthFormFooterSignIn/>}
            />
        </TheorieGoAuthFrame>
    );
};

export default SignIn;