import React, {useState} from "react";
import {BreadcrumbItem, BreadcrumbsContent, Icon, PageHeading} from "peggirkit";
import router, {breadcrumbItemContactForms, breadcrumbItemDashboard} from "../../../../routing/Router";
import {useIntl} from "react-intl";
import DeleteContactFormsModal from "../DeleteContactFormsModal";
import {ContactForm as ContactFormEntity} from "../../../../data/Entities";
import {useNavigate} from "react-router-dom";
import {TrashIcon} from "@heroicons/react/24/outline";

type Props = {
    currentBreadcrumbItem: BreadcrumbItem,
    contactForm: ContactFormEntity | undefined,
};

const ContactFormHeading = ({currentBreadcrumbItem, contactForm}: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [showDeleteContactFormModal, setShowDeleteContactFormModal] = useState(false);
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemContactForms, currentBreadcrumbItem]
    };

    return (
        <>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={currentBreadcrumbItem.name}
                actions={{
                    disabled: contactForm === undefined,
                    moreButtonText: intl.formatMessage({id: "more"}),
                    actions: [{
                        primary: true,
                        actionType: "button",
                        onClick: () => setShowDeleteContactFormModal(true),
                        text: intl.formatMessage({id: "delete"}),
                        type: "danger",
                        icon: TrashIcon as Icon,
                    }]
                }}
            />

            {
                contactForm &&
                <DeleteContactFormsModal
                    show={showDeleteContactFormModal}
                    setShow={setShowDeleteContactFormModal}
                    contactForms={[contactForm]}
                    cb={() => navigate(router.contactForms.absolutePath())}
                />
            }
        </>
    );
};

export default ContactFormHeading;