import React from "react";
import {Badge} from "peggirkit";
import {useIntl} from "react-intl";
import {StatusOpenFilter} from "../ContactFormsOverview";
import {statusOpenFilterToString} from "../ContactFormsOverviewPageHeading";

type Props = {
    openStatus: StatusOpenFilter
};

const OpenIndicationBadge = ({openStatus}: Props) => {
    const intl = useIntl();

    return <Badge
        type={openStatus === true ? "green" : "red"}
        content={statusOpenFilterToString(intl, openStatus)}
    />
};

export default OpenIndicationBadge;