import React from "react";
import router, {breadcrumbItemDashboard, breadcrumbItemStudents} from "../../../routing/Router";
import {CheckIcon, FunnelIcon, PlusIcon} from "@heroicons/react/24/outline";
import {BreadcrumbsContent, Icon, PageHeading} from "peggirkit";
import {StudentStatus} from "../../../data/Entities";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";

type Props = {
    studentStatusFilter: StudentStatus,
    setStudentStatusFilter: (newStatus: StudentStatus) => void
};

const StudentsOverviewPageHeading = ({studentStatusFilter, setStudentStatusFilter}: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const breadcrumbs: BreadcrumbsContent = {
        backText: intl.formatMessage({id: "back"}),
        items: [breadcrumbItemDashboard, breadcrumbItemStudents]
    };
    const studentStatusToString = (studentStatus: StudentStatus): string => {
        if (studentStatus === StudentStatus.NOT_YET_ACTIVATED) {
            return intl.formatMessage({id: "unactivated"});
        }

        if (studentStatus === StudentStatus.ACTIVE) {
            return intl.formatMessage({id: "active"});
        }

        if (studentStatus === StudentStatus.EXPIRED) {
            return intl.formatMessage({id: "expired"});
        }

        return intl.formatMessage({id: "allStudents"});
    };

    return (
        <PageHeading
            breadcrumbsContent={breadcrumbs}
            title={breadcrumbItemStudents.name}
            actions={{
                moreButtonText: intl.formatMessage({id: "more"}),
                disabled: false,
                actions: [
                    {
                        type: studentStatusFilter !== StudentStatus.ANY ? "whiteBlueAccent" : undefined,
                        actionType: "dropdown",
                        text: studentStatusToString(studentStatusFilter),
                        primary: false,
                        iconOnly: false,
                        icon: FunnelIcon as Icon,
                        options: [
                            [
                                {
                                    disabled: studentStatusFilter === StudentStatus.ANY,
                                    icon: studentStatusFilter === StudentStatus.ANY ? CheckIcon  as Icon: undefined,
                                    text: studentStatusToString(StudentStatus.ANY),
                                    onClick: () => setStudentStatusFilter(StudentStatus.ANY)
                                }
                            ],
                            [
                                {
                                    disabled: studentStatusFilter === StudentStatus.NOT_YET_ACTIVATED,
                                    icon: studentStatusFilter === StudentStatus.NOT_YET_ACTIVATED
                                        ? CheckIcon as Icon
                                        : undefined,
                                    text: studentStatusToString(StudentStatus.NOT_YET_ACTIVATED),
                                    onClick: () => setStudentStatusFilter(StudentStatus.NOT_YET_ACTIVATED)
                                },
                                {
                                    disabled: studentStatusFilter === StudentStatus.ACTIVE,
                                    icon: studentStatusFilter === StudentStatus.ACTIVE ? CheckIcon as Icon : undefined,
                                    text: studentStatusToString(StudentStatus.ACTIVE),
                                    onClick: () => setStudentStatusFilter(StudentStatus.ACTIVE)
                                },
                                {
                                    disabled: studentStatusFilter === StudentStatus.EXPIRED,
                                    icon: studentStatusFilter === StudentStatus.EXPIRED ? CheckIcon as Icon : undefined,
                                    text: studentStatusToString(StudentStatus.EXPIRED),
                                    onClick: () => setStudentStatusFilter(StudentStatus.EXPIRED)
                                }
                            ],
                        ]
                    },
                    {
                        actionType: "button",
                        text: intl.formatMessage({id: "newStudent"}),
                        primary: true,
                        icon: PlusIcon as Icon,
                        onClick: () => navigate(router.newStudent.absolutePath())
                    }
                ]
            }}
        />
    );
};

export default StudentsOverviewPageHeading;