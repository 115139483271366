import React from "react";
import {DropdownButton} from "peggirkit";
import {FunnelIcon} from "@heroicons/react/24/outline";
import {ExamCategory} from "../../../../../../data/Entities";
import {
    categoryFilterOptions,
    formatCategoryOption,
    QuestionFilterCategory
} from "../../../../categories/CategoryFilterOptions";

type Props = {
    categories: ExamCategory[]
    categoryFilter: QuestionFilterCategory
    setCategoryFilter: (filter: QuestionFilterCategory) => void
};

const Toolbar = ({categories, categoryFilter, setCategoryFilter}: Props) => {
    return (
        <div className={"w-full mb-2 flex flex-row justify-end"}>
            <DropdownButton
                icon={FunnelIcon}
                text={formatCategoryOption(categoryFilter)}
                alignRight={true}
                style={categoryFilter !== null ? "whiteBlueAccent" : undefined}
                actions={categoryFilterOptions(categoryFilter, setCategoryFilter, categories)}
            />
        </div>
    );
};

export default Toolbar;