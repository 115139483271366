import React from "react";
import {DescriptionList} from "peggirkit";
import {Order} from "../../../../../data/Entities";
import {useIntl} from "react-intl";
import {formatMoney} from "../../../../../utils/MoneyUtils";

type Props = {
    order: Order
};

const PaymentDetails = ({order}: Props) => {
    const intl = useIntl();

    return (
        <DescriptionList
            whitespaceNormal={true}
            items={[
                {
                    key: intl.formatMessage({id: "invoicedPerson"}),
                    value: order.name,
                },
                {
                    key: intl.formatMessage({id: "invoiceEmail"}),
                    value: order.emailAddress,
                },
                {
                    key: intl.formatMessage({id: "givenPreferredLanguage"}),
                    keyTooltip: intl.formatMessage({id: "couldBeChangeInMeanTime"}),
                    value: order.preferredLang,
                },
                {
                    key: intl.formatMessage({id: "descriptionOnInvoice"}),
                    keyTooltip: intl.formatMessage({id: "alsoNameOfPlan"}),
                    value: order.invoiceDescription,
                },
                {
                    key: intl.formatMessage({id: "subtotal"}),
                    value: formatMoney(order.priceExTax),
                },
                {
                    key: intl.formatMessage({id: "tax21Percent"}),
                    value: formatMoney(order.taxPrice),
                },
                {
                    key: intl.formatMessage({id: "total"}),
                    value: formatMoney(order.priceInTax),
                },
                {
                    key: intl.formatMessage({id: "paymentMethod"}),
                    value: order.paymentMethod ?? intl.formatMessage({id: "notYetPaid"})
                },
                {
                    key: intl.formatMessage({id: "mollieTransaction"}),
                    value: order.mollieTransactionId,
                    options: [{
                        text: intl.formatMessage({id: "view"}),
                        onClick: () => {
                            window.open(
                                `https://my.mollie.com/dashboard/payments/${order.mollieTransactionId}`,
                                "_blank");
                        }
                    }]
                },
                {
                    key: intl.formatMessage({id: "factuurSturenClientNumber"}),
                    value: order.factuurSturenClientNumber || "-",
                },
                {
                    key: intl.formatMessage({id: "factuurSturenInvoiceNumber"}),
                    value: order.factuurSturenInvoiceNumber || "-",
                },
                {
                    key: intl.formatMessage({id: "orderStartedOn"}),
                    value: new Date(order.creationDate).toLocaleString(),
                },
                {
                    key: intl.formatMessage({id: "paymentReceivedOn"}),
                    value: order.paidOn === null
                        ? intl.formatMessage({id: "notYetPaid"})
                        : new Date(order.paidOn).toLocaleString(),
                },
            ]}
        />
    );
};

export default PaymentDetails;