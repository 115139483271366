import React, {useMemo, useState} from "react";
import {
    AlertModal,
    AlertText,
    DataTable,
    DataTableColumn,
    NotificationService,
    TableButton,
    TableColumn,
    TableData
} from "peggirkit";
import {FormattedMessage, useIntl} from "react-intl";
import {theorieGoAxios} from "../../../../../data/ApiEndpoints";
import {TrashIcon} from "@heroicons/react/24/outline";
import {ExamFilterConcept, ExamFilterDemo} from "../ExamsOverview";
import {OverviewExam} from "../../../../../data/Entities";
import ConceptFormatter from "../../../formatter/ConceptFormatter";
import NameFormatter from "./formatter/NameFormatter";
import DeleteExamsModal from "../../delete/DeleteExamsModal";
import router from "../../../../../routing/Router";

type Props = {
    demoFilter: ExamFilterDemo,
    conceptFilter: ExamFilterConcept,
};

type DeleteExamsOptions = {
    showDialog: boolean,
    showCannotDeleteDialog: boolean,
    selectedExamsToDelete: OverviewExam[]
};

const dataTableBaseUrl = (
    demoFilter: ExamFilterDemo,
    conceptFilter: ExamFilterConcept,
    search: boolean
): string => {
    const dataUrl = new URL(`${process.env.BACKEND_API_BASE_URL}/admin/exams${search ? "/search" : ""}`);

    if (demoFilter !== null) {
        dataUrl.searchParams.set("demo", demoFilter ? "true" : "false");
    } else {
        dataUrl.searchParams.delete("demo");
    }

    if (conceptFilter !== null) {
        dataUrl.searchParams.set("concept", conceptFilter ? "true" : "false");
    } else {
        dataUrl.searchParams.delete("concept");
    }

    return dataUrl.toString();
}

const ExamsDataTable = ({demoFilter, conceptFilter}: Props) => {
    const intl = useIntl();
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const [deleteExamsOptions, setDeleteExamsOptions] = useState<DeleteExamsOptions>({
        showDialog: false,
        showCannotDeleteDialog: false,
        selectedExamsToDelete: [],
    });

    const columns: (TableColumn & DataTableColumn)[] = useMemo(() => {
        return [
            {
                dataName: "id",
                name: intl.formatMessage({id: "id"}),
                sortable: true,
                secondary: true
            },
            {
                dataName: "nameNl",
                name: intl.formatMessage({id: "name"}),
                sortable: true,
                secondary: false,
                formatter: (_, data: unknown) => <NameFormatter exam={data as OverviewExam}/>
            },
            {
                dataName: "concept",
                name: intl.formatMessage({id: "public"}),
                sortable: true,
                formatter: (concept: boolean) => <ConceptFormatter concept={concept}/>,
                secondary: true
            },
            {
                dataName: "creationDate",
                name: intl.formatMessage({id: "addedOn"}),
                sortable: true,
                formatter: (date: string) => new Date(date).toLocaleDateString(),
                secondary: true,
                sorted: "desc"
            },
            {
                hideLabel: true,
                name: intl.formatMessage({id: "options"}),
                sortable: false,
                alignRight: true,
                secondary: true,
                formatter: (_, exam): JSX.Element => (
                    <TableButton
                        text={intl.formatMessage({id: "view"})}
                        href={router.editExam.absolutePath(exam.id)}
                    />
                )
            }
        ]
    }, []);

    return (
        <>
            <DataTable
                initialLastUpdated={lastUpdated}
                withUrlPageParameter={true}
                scrollToTopOnPageChange={true}
                initialPageRequest={{
                    axios: theorieGoAxios,
                    baseUrl: dataTableBaseUrl(demoFilter, conceptFilter, false),
                    pageSize: 20,
                    pageNumber: 0,
                    search: {
                        baseUrl: dataTableBaseUrl(demoFilter, conceptFilter, true),
                        urlParameter: "query",
                        messages: {
                            search: intl.formatMessage({id: "search"}),
                            placeholder: intl.formatMessage({id: "exam"}),
                            clear: intl.formatMessage({id: "clear"})
                        }
                    }
                }}
                columns={columns}
                summaryFormatter={(from: number, to: number, total: number): JSX.Element | string => (
                    intl.formatMessage({id: "paginationSummary"}, {from: from, to: to, total: total})
                )}
                messages={{
                    next: intl.formatMessage({id: "next"}),
                    previous: intl.formatMessage({id: "previous"})
                }}
                batchActions={{
                    dropdownButtonText: (itemsSelected: number) => intl.formatMessage({id: "nItemsSelected"}, {n: itemsSelected}),
                    toggleSelectAllText: intl.formatMessage({id: "selectAll"}),
                    selectRowText: intl.formatMessage({id: "selectRow"}),
                    actions: [
                        [
                            {
                                type: "danger",
                                icon: TrashIcon,
                                text: intl.formatMessage({id: "delete"}),
                                disabled: false,
                                onClick: (rawData: TableData[]) => {
                                    const exams = rawData as OverviewExam[];
                                    const demoCount = exams.filter(e => e.demo).length;
                                    if (demoCount > 0) {
                                        setDeleteExamsOptions({
                                            ...deleteExamsOptions,
                                            showCannotDeleteDialog: true
                                        });
                                        return;
                                    }
                                    setDeleteExamsOptions({
                                        ...deleteExamsOptions,
                                        selectedExamsToDelete: rawData as OverviewExam[],
                                        showDialog: true
                                    });
                                }
                            }
                        ]
                    ]
                }}
                alwaysShowToolbar={true}
            />

            <DeleteExamsModal
                show={deleteExamsOptions.showDialog}
                setShow={(show: boolean) => setDeleteExamsOptions({...deleteExamsOptions, showDialog: show})}
                examIds={deleteExamsOptions.selectedExamsToDelete.map(q => q.id)}
                cb={() => {
                    setLastUpdated(new Date());
                    NotificationService.notify({
                        type: "success",
                        text: intl.formatMessage(
                            {id: "nExamsDeleted"},
                            {n: deleteExamsOptions.selectedExamsToDelete.length})
                    });
                    setDeleteExamsOptions({
                        ...deleteExamsOptions,
                        showDialog: false,
                        selectedExamsToDelete: []
                    });
                }}
            />

            <AlertModal
                type={"danger"}
                loading={false}
                show={deleteExamsOptions.showCannotDeleteDialog}
                onClose={() => setDeleteExamsOptions({
                    ...deleteExamsOptions,
                    showCannotDeleteDialog: false
                })}
                title={intl.formatMessage({id: "cannotDeleteExams"})}
                cancelText={intl.formatMessage({id: "close"})}
            >
                <AlertText>
                    <FormattedMessage id={"cannotDeleteExams.explanation"}/>
                </AlertText>
            </AlertModal>
        </>
    );
};

export default ExamsDataTable;