import React, {useState} from "react";
import PageHeading from "./PageHeading";
import ExamsDataTable from "./dataTable/ExamsDataTable";

export type ExamFilterConcept = boolean | null;
export type ExamFilterDemo = boolean | null;


const QuestionsOverview = () => {
    const [conceptFilter, setConceptFilter] = useState<ExamFilterConcept>(null);
    const [demoFilter, setDemoFilter] = useState<ExamFilterDemo>(null);

    return (
        <>
            <PageHeading
                conceptFilter={conceptFilter}
                setConceptFilter={setConceptFilter}
                demoFilter={demoFilter}
                setDemoFilter={setDemoFilter}
            />

            <div className={"mt-8 md:mt-16"}>
                <ExamsDataTable
                    conceptFilter={conceptFilter}
                    demoFilter={demoFilter}
                />
            </div>
        </>
    );
};

export default QuestionsOverview;