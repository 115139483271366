import React from "react";
import {OverviewExamQuestion} from "../../../../../../data/Entities";
import {Badge} from "peggirkit";
import {useIntl} from "react-intl";

type Props = {
    question: unknown
};

const SectionFormatter = ({question}: Props) => {
    const intl = useIntl();
    const q = question as OverviewExamQuestion;
    return (
        <div className={"flex flex-row gap-1 items-start"}>
            {
                q.hazardRecognitionSection
                    ? <Badge
                        type={"gray"}
                        content={intl.formatMessage({id: "hazardRecognition.shortened"})}
                        tooltip={intl.formatMessage({id: "hazardRecognition"})}
                    />
                    : <></>
            }
            {
                q.knowledgeSection
                    ? <Badge
                        type={"gray"}
                        content={intl.formatMessage({id: "knowledge.shortened"})}
                        tooltip={intl.formatMessage({id: "knowledge"})}
                    />
                    : <></>
            }
            {
                q.insightSection
                    ? <Badge
                        type={"gray"}
                        content={intl.formatMessage({id: "insight.shortened"})}
                        tooltip={intl.formatMessage({id: "insight"})}
                    />
                    : <></>
            }
        </div>
    );
};

export default SectionFormatter;