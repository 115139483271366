import React from "react";
import {EditQuestionSectionProps} from "../../../EditQuestionForm";
import {InputCreatableSelect} from "peggirkit";
import {useIntl} from "react-intl";
import {AnswerTypeProps} from "../AnswerSection";
import {SelectedAnswerType} from "../AnswerType";
import {yesNoOptions} from "./options";


const YesNoAnswer = ({
                         selectedAnswerType,
                         loading,
                         control,
                         errors,
                     }: EditQuestionSectionProps & AnswerTypeProps) => {
    const intl = useIntl();

    return (
        <InputCreatableSelect
            id={"question-answer-yes-no"}
            displayName={intl.formatMessage({id: "yesNoAnswer"})}
            options={yesNoOptions}
            disabled={loading}
            control={control}
            placeholder={intl.formatMessage({id: "pickAnAnswer"})}
            nameHookForm={"answerYes"}
            isValidNewOption={_ => false}
            formatCreateLabel={inputValue => intl.formatMessage({id: "addOption"}, {option: inputValue})}
            rules={{
                required: selectedAnswerType === SelectedAnswerType.YES_NO
                    ? intl.formatMessage({id: "mustPickOption"})
                    : false,
            }}
            error={errors.answerYes && errors.answerYes.message}
        />
    );
};

export default YesNoAnswer;